import styles from "./ExpandableContent.module.scss";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Button from "components/common/Button";
import Divider from "components/common/Divider";

interface ExpandableContentProps {
  title: string;
  children?: React.ReactNode;
  isOpen?: boolean;
  isPrimary?: boolean;
  divider?: boolean;
}

const ExpandableContent: React.FC<ExpandableContentProps> = ({
  title,
  children,
  isOpen = false,
  isPrimary = true,
  divider = true,
}) => {
  const [visible, setVisible] = useState(isOpen);
  const titleClass = isPrimary ? "primary" : "secondary";
  const handleVisibility = () => {
    setVisible(!visible);
  };
  return (
    <div className={styles.container}>
      <div
        className={`${styles["title-container"]} ${styles[titleClass]}`}
        onClick={() => handleVisibility()}
      >
        <p>{title}</p>
        <Button
          emphasis="low"
          action={() => handleVisibility()}
          color={"secondary"}
          size="xsmall"
        >
          {visible && <ArrowDropUpIcon />}
          {!visible && <ArrowDropDownIcon />}
        </Button>
      </div>
      {divider && <Divider color="black" />}
      {visible && children}
    </div>
  );
};

export default ExpandableContent;

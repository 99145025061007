import { getData, postRequest, patchRequest } from "./api";
import { CreateUser, USER_API, UpdateUser } from "./user.api.types";

const createUser = (user: CreateUser) => {
  return postRequest(USER_API.createUser, user);
};

const getUser = (userEmail: string) => {
  return getData(USER_API.getUser(userEmail));
};

const getUsers = () => {
  return getData(USER_API.getUsers);
};

const patchUser = (id: number, user: UpdateUser) => {
  return patchRequest(USER_API.patchUser(id), user, {});
};

export { createUser, getUser, getUsers, patchUser };

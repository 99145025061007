import { getLocation } from "config/api/api";
import { Form } from "../form.types";
import { DynamicDataContext } from "config/dynamicDataContext";
import TermsAndConditions from "views/TermsAndConditions";

export const fiscalSignupForm: Form = {
  formTitle: "Datos de facturación",
  formDescription: "Registra los datos fiscales para facturar tu pedido",
  fields: [
    {
      name: "fiscalRfc",
      label: "RFC",
      required: true,
      placeholder: "AAAA000000BBB",
      type: "rfc",
      errorMessage: "Por favor ingresa tu RFC completo con 13 caracteres.",
    },
    {
      name: "fiscalCurp",
      label: "CURP",
      required: true,
      placeholder: "AAAA000000BBBBBB00",
      type: "curp",
      errorMessage:
        "Por favor ingresa tu CURP completo con homoclave a 18 caracteres.",
    },
    {
      name: "fiscalIdentity",
      label: "Nombre legal",
      required: true,
      placeholder:
        "Escribe tu nombre o compañía como está registrada en el SAT",
      type: "text",
    },
    {
      name: "fiscalDocument",
      label: "Constancia fiscal",
      required: true,
      placeholder: "Sube aquí el documento de constancia fiscal",
      fieldCaption: "La constancia fiscal debe ser del mes en curso",
      type: "file",
    },
    {
      name: "fiscalEmail",
      label: "Correo electrónico personal",
      required: true,
      placeholder: "correo@ejemplo.com",
      type: "email",
      errorMessage:
        "Por favor ingresa una dirección de correo personal/adicional.",
      needsToBeDifferentfrom: "email",
    },
    {
      name: "fiscalZipCode",
      label: "Código postal",
      required: true,
      placeholder: "000000",
      type: "zipcode",
      fieldCaption: "De la constancia fiscal",
      errorMessage:
        "¡Lo sentimos! No hay opciones de envío a esta dirección. Por favor, introduzca un código postal diferente.",
      apiCall: getLocation,
    },
    {
      name: "fiscalStreet",
      label: "Avenida o calle",
      required: true,
      placeholder: "",
      type: "text",
      fieldCaption: "De la constancia fiscal",
      maxLength: 50,
    },
    {
      name: "fiscalExternalNumber",
      label: "Número exterior",
      required: true,
      placeholder: "25-A",
      type: "text",
      size: "half-width",
      value: "",
      fieldCaption: "De la constancia fiscal",
      maxLength: 15,
    },
    {
      name: "fiscalInternalNumber",
      label: "Número interior",
      required: false,
      placeholder: "25-A",
      type: "text",
      size: "half-width",
      fieldCaption: "De la constancia fiscal",
      maxLength: 15,
    },
    {
      name: "fiscalNeighborhoodId",
      label: "Colonia",
      required: true,
      value: "",
      placeholder: "Colonia",
      type: "dropdown",
      dynamicOptionsProp: "fiscalAddress",
      optionsContext: DynamicDataContext,
      fieldCaption: "De la constancia fiscal",
    },
    {
      name: "fiscalState",
      label: "Estado",
      required: true,
      placeholder: "Estado",
      type: "text",
      disabled: true,
      fieldCaption: "De la constancia fiscal",
    },
    {
      name: "fiscalCity",
      label: "Ciudad",
      required: true,
      placeholder: "Ciudad",
      type: "text",
      disabled: true,
      fieldCaption: "De la constancia fiscal",
    },
    {
      name: "checkbox",
      label: "",
      required: true,
      type: "checkbox",
      value: "",
      children: <TermsAndConditions type={"signup"} />,
    },
  ],
};

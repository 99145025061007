
import React from "react";
import styles from "./PaymentInfoStep.module.scss";
import CardLogoComponent, { CardLogoType } from "../CardLogoComponent";

const PaymentInfoStepCardsRow: React.FC = () => {

  return (
    <div className={styles["cards-row"]}>
      <div className={`${styles["card-column"]} ${styles["credit-card-logos-column"]}`}>
        <div className="credit"><h4>Tarjetas de crédito</h4></div>
        <CardLogoComponent type={CardLogoType.Credit} alt="visa" src={'/openpay/visa.png'}/>
        <CardLogoComponent type={CardLogoType.Credit} alt="masterCard" src={'/openpay/masterCard.png'}/>
        <CardLogoComponent type={CardLogoType.Credit} alt="americanExpress" src={'/openpay/americanExpress.png'}/>
        <CardLogoComponent type={CardLogoType.Credit} alt="carnet" src={'/openpay/carnet.png'}/>
      </div>
      <div className={`${styles["card-column"]} ${styles["debit-card-logos-column"]}`}>
        <div className="debit"><h4>Tarjetas de débito</h4></div>
        <CardLogoComponent type={CardLogoType.Debit} alt="banorte" src={'/openpay/banorte.png'}/>
        <CardLogoComponent type={CardLogoType.Debit} alt="santander" src={'/openpay/santander.png'}/>
        <CardLogoComponent type={CardLogoType.Debit} alt="citibanamex" src={'/openpay/citibanamex.png'}/>
        <CardLogoComponent type={CardLogoType.Debit} alt="hsbc" src={'/openpay/hsbc.png'}/>
        <CardLogoComponent type={`bbva-${CardLogoType.Debit}`} alt="BBVA" src={'/openpay/BBVA.png'}/>
        <CardLogoComponent type={CardLogoType.Debit} alt="afirme" src={'/openpay/afirme.png'}/>
        <CardLogoComponent type={CardLogoType.Debit} alt="banregio" src={'/openpay/banregio.png'}/>
        <CardLogoComponent type={CardLogoType.Debit} alt="bancoAzteca" src={'/openpay/bancoAzteca.png'}/>
      </div>
    </div>
  )
}

export default PaymentInfoStepCardsRow;
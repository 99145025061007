import env from "react-dotenv";

export const API = {
  location: (zipCode: string) =>
    `${env.REACT_APP_BACK_HOST}/locations/postalCode/${zipCode}`,
  mapsAPI: (postalCode: number, country: string) =>
    `https://maps.googleapis.com/maps/api/geocode/json?skip_auth&components=postal_code:${postalCode}|country:${country}&region=AU&key=${env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  openPayfillOrder: () => `${env.REACT_APP_BACK_HOST}/orders/createOrder`,
  _3dSecureTokenUrl: () =>
    `${env.REACT_APP_BACK_HOST}/orders/3dSecurePaymentToken`,
  sendEmail: () => `/notify`,
};

export type ZipCodeLocation = {
  countryName: string;
  location: Location;
};

export type Location = {
  cityId: string;
  city: string;
  stateId: string;
  state: string;
  neighborhood: Neighborhood[];
};

export type Neighborhood = {
  value: string;
  label: string;
};

export type LocationCoordinates = {
  lat: number;
  lng: number;
};

export type CodePostalInfo = {
  results: Array<any>;
  status: string;
};

interface LooseObject {
  [key: string]: any;
}

export type Email = {
  sendgrid: {
    from_email: string;
    to_email: string;
    subject: string;
    message: string;
    mailSettings?: LooseObject;
  };
};

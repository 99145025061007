import { OptionsField } from "forms/form.types";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./CVVField.module.scss";
import fieldStyles from "../Field/Field.module.scss";
import {
  numericInputHandleKeyDown,
  numericInputHandleKeyUp,
} from "../utils/ControlledNumericInputs.utils";

const CVVField: React.FC<OptionsField> = ({
  id,
  name,
  value,
  placeholder,
  label,
  required,
  type,
  disabled,
  fieldCaption = "",
  errorMessage = "Favor de ingresar un número de 3 dígitos o 4 para Amex",
}) => {
  const [error, setError] = useState("");
  const methods = useFormContext();
  const [currentValue, setCurrentValue] = useState(value || "");
  const [ctrlPressed, setCtrlPressed] = useState(false);

  const cvvMaxLength = 4;

  const validateCVV = (expiryDateValue: string) => {
    if (expiryDateValue.length >= 3 && expiryDateValue.length <= 4) {
      setError("");
      setCurrentValue(expiryDateValue);
      return true;
    }
    setCurrentValue(expiryDateValue);
    setError(errorMessage);
    return false;
  };

  useEffect(() => {
    methods.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const activeError = error !== "";
  const emptyFieldWhenRequired =
    methods.formState.errors[name] &&
    methods.formState.errors[name].type === "required";
  const valueNotChanged = currentValue === value;

  const errorStyle =
    activeError ||
    (emptyFieldWhenRequired && (currentValue === "" || valueNotChanged))
      ? "input-error"
      : "";

  const caption = fieldCaption && (
    <span className={fieldStyles["field-caption"]}>{fieldCaption}</span>
  );
  const requiredMessage = emptyFieldWhenRequired &&
    (currentValue === "" || valueNotChanged) && (
      <span className={styles["error-text"]}>Este campo es requerido</span>
    );

  activeError &&
    (methods.formState.errors[name] = { type: "validation", message: error });

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <input
        {...methods.register(name, {
          value: currentValue,
          required,
          validate: (value) => validateCVV(value),
        })}
        id={id}
        className={`${styles.input} ${styles[errorStyle]}`}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        max={`${cvvMaxLength}`}
        onKeyDown={(e) =>
          numericInputHandleKeyDown(
            e,
            currentValue,
            cvvMaxLength,
            ctrlPressed,
            setCtrlPressed
          )
        }
        onKeyUp={(e) => numericInputHandleKeyUp(e, setCtrlPressed)}
        onChange={(e) => {
          let cvvValue = e.target.value;
          setCurrentValue(cvvValue);
          validateCVV(cvvValue);
        }}
      />
      {caption}
      {requiredMessage}
      {error !== "" && <div className={styles["error-text"]}> {error}</div>}
    </div>
  );
};

export default CVVField;

import { useContext } from "react";
import SelectedOfferContext from "config/selectedOfferContext";
import Banner from "components/common/Banner";
import styles from "./OfferBanner.module.scss";
import Link from "components/common/Link";

const OfferBanner: React.FC = () => {
  const { selectedOffer } = useContext(SelectedOfferContext);

  if (selectedOffer?.total < 4000) {
    return (
      <div className={styles["banner-container"]}>
        <Banner
          size="large"
          type="error"
          message={
            <div>
              Importante: El monto de tu carrito ha disminuido debido al tipo de
              envío elegido, recuerda que el mínimo permitido de compra es de
              $4,000 MXN, escoge otro tipo de envío o &nbsp;
              <Link href={"/"} text="agrega más productos" />
            </div>
          }
        />
      </div>
    );
  }
  if (selectedOffer?.total > 200000) {
    return (
      <div className={styles["banner-container"]}>
        <Banner
          size="large"
          type="error"
          message={
            <div>
              Importante: El monto de tu carrito ha aumentado debido al tipo de
              envío elegido, recuerda que el máximo permitido de compra es de
              $200,000 MXN, escoge otro tipo de envío o elimina productos de tu
              carrito.
            </div>
          }
        />
      </div>
    );
  }
  return null;
};

export default OfferBanner;

import Button from "components/common/Button";
import Section from "components/common/Section";
import useLocalStorage from "components/hooks/useLocalStorage";
import ChangeAddressPopup from "./ChangeAddressPopup/ChangeAddressPopup";
import { useState } from "react";

interface AddressStepProps {
  onChangeAddressAction: () => void;
}

const AddressStep: React.FC<AddressStepProps> = ({ onChangeAddressAction }) => {
  const userData = useLocalStorage("userData", {}).storedValue;
  const [addressPopupOpen, setaddressPopupOpen] = useState(false);

  const changeAddressButton = (
    <div>
      <Button
        color="primary"
        emphasis="low"
        action={() => {
          setaddressPopupOpen(true);
        }}
      >
        ¿Quieres cambiar de dirección?
      </Button>
    </div>
  );

  const userLocation = userData.locationData.find((location: any) => {
    return location.type === "CONSIGNEE";
  });

  return (
    <div>
      <ChangeAddressPopup
        action={onChangeAddressAction}
        open={addressPopupOpen}
        setOpen={setaddressPopupOpen}
      />
      <Section
        title="1. Revisa tu dirección de envío"
        titleSize="small"
        variant="no-margin-title"
        action={changeAddressButton}
      >
        {userData.locationData && (
          <p>
            {`${userLocation.dir.toUpperCase()} ${userLocation.streetnumber}`}
            <br />
            {`${userLocation.neighborhood}, ${userLocation.postalCode}`}
            <br />
            {`${userLocation.city}, ${userLocation.state}`}
          </p>
        )}
      </Section>
    </div>
  );
};

export default AddressStep;

import Form from "components/Form/Form";
import { fiscalDataProfileForm } from "forms/Profile/Profile.fiscaldata";
import { fiscalAddressProfileForm } from "forms/Profile/Profile.fiscaladdress";
import styles from "./TaxData.module.scss";
import { useContext } from "react";
import { FieldValues } from "react-hook-form";
import UserContext from "config/userContext";
import Banner from "components/common/Banner";

const TaxData: React.FC = () => {
  const { userData } = useContext(UserContext);

  const autoFillFiscalForms = async () => {
    fiscalDataProfileForm.fields[0].value =
      userData?.name +
      " " +
      userData?.fatherLastName +
      " " +
      userData?.motherLastName;
    fiscalDataProfileForm.fields[1].value = userData?.rfc;
    fiscalDataProfileForm.fields[2].value = userData?.curp;
    const fiscalAddress = userData?.locationData?.find((obj: any) => {
      return obj.type === "FISCAL";
    });
    if (fiscalAddress) {
      fiscalAddressProfileForm.fields[0].value = fiscalAddress.postalCode;
      fiscalAddressProfileForm.fields[1].value = fiscalAddress.dir;
      fiscalAddressProfileForm.fields[2].value = fiscalAddress.streetnumber;
      fiscalAddressProfileForm.fields[3].value = fiscalAddress.internalnumber;
      fiscalAddressProfileForm.fields[4].value = fiscalAddress.neighborhood;
      fiscalAddressProfileForm.fields[5].value = fiscalAddress.state;
      fiscalAddressProfileForm.fields[6].value = fiscalAddress.city;
    }
  };

  const onSubmitFiscalDataForm = async (data: FieldValues) => {};

  const onSubmitFiscalAddressForm = async (data: FieldValues) => {};

  autoFillFiscalForms();

  return (
    <div className={styles.body}>
      <Form
        inputForm={fiscalDataProfileForm}
        onSubmit={onSubmitFiscalDataForm}
        submitTitle={"Guardar datos"}
        showOnly={true}
      />
      <Banner
        size="large"
        type="info"
        closeIcon={false}
        message="Para realizar algún cambio, contactar a ventaempleados@deacero.com"
      />
      <div className={styles.divider}></div>
      <Form
        inputForm={fiscalAddressProfileForm}
        onSubmit={onSubmitFiscalAddressForm}
        submitTitle={"Guardar datos"}
        showOnly={true}
      />
      <Banner
        size="large"
        type="info"
        closeIcon={false}
        message="Para realizar algún cambio, contactar a ventaempleados@deacero.com"
      />
    </div>
  );
};

export default TaxData;

export const openPayErrors: any = {
  "1000": {
    openPayErrorCode: 1000,
    httpErrorCode: 500,
    httpMessageEnglish: "Internal Server Error",
    messageEnglish: "An error happened in the internal Openpay server.",
    messageSpanish: "Ocurrió un error interno en el servidor de Openpay",
  },
  "1001": {
    openPayErrorCode: 1001,
    httpErrorCode: 400,
    httpMessageEnglish: "Bad Request",
    messageEnglish:
      "The request is not JSON valid format, the fields don’t have the correct format, or the request doesn’t have the required fields.",
    messageSpanish:
      "El formato de la petición no es JSON, los campos no tienen el formato correcto, o la petición no tiene campos que son requeridos.",
  },
  "1002": {
    openPayErrorCode: 1002,
    httpErrorCode: 401,
    httpMessageEnglish: "Unauthorized",
    messageEnglish: "The request is not authenticated or is incorrect.",
    messageSpanish:
      "La llamada no esta autenticada o la autenticación es incorrecta.",
  },
  "1003": {
    openPayErrorCode: 1003,
    httpErrorCode: 422,
    httpMessageEnglish: "Unprocessable Entity",
    messageEnglish:
      "The operation couldn’t be processed because one or more parameters are incorrect.",
    messageSpanish:
      "La operación no se pudo completar por que el valor de uno o más de los parámetros no es correcto.",
  },
  "1004": {
    openPayErrorCode: 1004,
    httpErrorCode: 503,
    httpMessageEnglish: "Service Unavailable",
    messageEnglish: "A required service is not available.",
    messageSpanish:
      "Un servicio necesario para el procesamiento de la transacción no se encuentra disponible.",
  },
  "1005": {
    openPayErrorCode: 1005,
    httpErrorCode: 404,
    httpMessageEnglish: "Not Found",
    messageEnglish: "A required resource doesn’t exist.",
    messageSpanish: "Uno de los recursos requeridos no existe.",
  },
  "1006": {
    openPayErrorCode: 1006,
    httpErrorCode: 409,
    httpMessageEnglish: "Conflict",
    messageEnglish: "There is already a transaction with the same ID order.",
    messageSpanish: "Ya existe una transacción con el mismo ID de orden.",
  },
  "1008": {
    openPayErrorCode: 1008,
    httpErrorCode: 423,
    httpMessageEnglish: "Locked",
    messageEnglish: "One of the required accounts is deactivated.",
    messageSpanish:
      "La transferencia de fondos entre una cuenta de banco o tarjeta y la cuenta de Openpay no fue aceptada.",
  },
  "1007": {
    openPayErrorCode: 1007,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish:
      "The funds transfer between the bank account or card and the Openpay account was rejected.",
    messageSpanish:
      "Una de las cuentas requeridas en la petición se encuentra desactivada.",
  },
  "1009": {
    openPayErrorCode: 1009,
    httpErrorCode: 413,
    httpMessageEnglish: "Request Entity too large",
    messageEnglish: "The request body is too large.",
    messageSpanish: "El cuerpo de la petición es demasiado grande.",
  },
  "1010": {
    openPayErrorCode: 1010,
    httpErrorCode: 403,
    httpMessageEnglish: "Forbidden",
    messageEnglish:
      "The public key is being used to make a request that requires the private key, or the private key is being using from Javascript.",
    messageSpanish:
      "Se esta utilizando la llave pública para hacer una llamada que requiere la llave privada, o bien, se esta usando la llave privada desde JavaScript.",
  },
  "1011": {
    openPayErrorCode: 1011,
    httpErrorCode: 404,
    httpMessageEnglish: "Not Found",
    messageEnglish: "The resource was previously deleted.",
    messageSpanish: "Se solicita un recurso que esta marcado como eliminado.",
  },
  "1012": {
    openPayErrorCode: 1012,
    httpErrorCode: 412,
    httpMessageEnglish: "Precondition failed",
    messageEnglish: "The transaction amount is out of the limits.",
    messageSpanish:
      "El monto transacción esta fuera de los limites permitidos.",
  },
  "1013": {
    openPayErrorCode: 1013,
    httpErrorCode: 412,
    httpMessageEnglish: "Precondition failed",
    messageEnglish: "The operation is not allowed on the resource.",
    messageSpanish: "La operación no esta permitida para el recurso.",
  },
  "1014": {
    openPayErrorCode: 1014,
    httpErrorCode: 401,
    httpMessageEnglish: "Unauthorized",
    messageEnglish: "The account is inactive.",
    messageSpanish: "La cuenta esta inactiva.",
  },
  "1015": {
    openPayErrorCode: 1015,
    httpErrorCode: 504,
    httpMessageEnglish: "Gateway timeout",
    messageEnglish: "Could not get any response from gateway.",
    messageSpanish:
      "No se ha obtenido respuesta de la solicitud realizada al servicio.",
  },
  "1016": {
    openPayErrorCode: 1016,
    httpErrorCode: 409,
    httpMessageEnglish: "Conflict",
    messageEnglish: "The merchant email has been already processed.",
    messageSpanish: "El mail del comercio ya ha sido procesada.",
  },
  "1017": {
    openPayErrorCode: 1017,
    httpErrorCode: 502,
    httpMessageEnglish: "Bad Gateway",
    messageEnglish:
      "The payment gateway is not available at the moment, please try again later.",
    messageSpanish: "El gateway no se encuentra disponible en ese momento.",
  },
  "1018": {
    openPayErrorCode: 1018,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish: "The number of retries of charge is greater than allowed.",
    messageSpanish: "El número de intentos de cargo es mayor al permitido.",
  },
  "1020": {
    openPayErrorCode: 1020,
    httpErrorCode: 400,
    httpMessageEnglish: "Bad Request",
    messageEnglish:
      "The number of decimal digits is not valid for this currency",
    messageSpanish:
      "El número de dígitos decimales es inválido para esta moneda.",
  },
  "2005": {
    openPayErrorCode: 2005,
    httpErrorCode: 400,
    httpMessageEnglish: "Bad Request",
    messageEnglish: "The expiration date has expired",
    messageSpanish: "La fecha de expiración ya sucedió.",
  },
  "2006": {
    openPayErrorCode: 2006,
    httpErrorCode: 400,
    httpMessageEnglish: "Bad Request",
    messageEnglish: "The CVV2 security code is required",
    messageSpanish: "El código de seguridad CVV2 es requerido.",
  },
  "3001": {
    openPayErrorCode: 3001,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish: "Card declined.",
    messageSpanish:
      "El pago fue declinado por el banco, favor de ponerse en contacto con su banco para autorizar la transacción.",
  },
  "3002": {
    openPayErrorCode: 3002,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish: "Card is expired.",
    messageSpanish: "La tarjeta ha expirado.",
  },
  "3003": {
    openPayErrorCode: 3003,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish: "Card has not enough funds.",
    messageSpanish: "La tarjeta no tiene fondos suficientes.",
  },
  "3004": {
    openPayErrorCode: 3004,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish: "Card has been flagged as stolen.",
    messageSpanish: "La tarjeta ha sido identificada como una tarjeta robada.",
  },
  "3005": {
    openPayErrorCode: 3005,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish: "Card has been rejected by the antifraud system.",
    messageSpanish: "La tarjeta ha sido rechazada por el sistema antifraude",
  },
  "3006": {
    openPayErrorCode: 3006,
    httpErrorCode: 412,
    httpMessageEnglish: "Precondition Failed",
    messageEnglish:
      "The operation is not allowed for this customer or transaction.",
    messageSpanish:
      "La operación no esta permitida para este cliente o esta transacción.",
  },
  "3007": {
    openPayErrorCode: 3007,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish: "The card was rejected.", // Deprecated
    messageSpanish: "The card was rejected.", // Deprecated
  },
  "3008": {
    openPayErrorCode: 3008,
    httpErrorCode: 412,
    httpMessageEnglish: "Precondition Failed",
    messageEnglish: "The card doesn’t support online transactions.",
    messageSpanish: "La tarjeta no está habilitada para compras en línea.",
  },
  "3009": {
    openPayErrorCode: 3009,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish: "Card has been flagged as lost.",
    messageSpanish: "La tarjeta fue reportada como perdida.",
  },
  "3010": {
    openPayErrorCode: 3010,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish: "The card has been restricted by the bank.",
    messageSpanish: "El banco ha restringido la tarjeta.",
  },
  "3011": {
    openPayErrorCode: 3011,
    httpErrorCode: 402,
    httpMessageEnglish: "Payment Required",
    messageEnglish:
      "The bank has requested to hold this card. Please contact the bank.",
    messageSpanish:
      "El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.",
  },
  "3012": {
    openPayErrorCode: 3012,
    httpErrorCode: 412,
    httpMessageEnglish: "Precondition Failed",
    messageEnglish: "Bank authorization is required to make this payment.",
    messageSpanish:
      "Se requiere solicitar al banco autorización para realizar este pago.",
  },
  "5001": {
    openPayErrorCode: 5001,
    httpErrorCode: 409,
    httpMessageEnglish: "Conflict",
    messageEnglish: "The external_order_id already exists",
  },
};

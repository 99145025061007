import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "components/common/Button";
import styles from "./Footer.module.scss";
import Tooltip from "components/common/Tooltip";
import MailTo from "components/common/MailTo";
import TermsAndConditions from "views/TermsAndConditions";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const username = user?.given_name || user?.name;

  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!username) {
    return null;
  }
  const tooltipContent = (
    <div>
      <div className={styles.title}>Soporte</div>
      <div className={styles["tooltip-text"]}>
        <p>Envianos tus dudas a: </p>
        <MailTo email="beneficios@deacero.com">
          {"beneficios@deacero.com"}
        </MailTo>
      </div>
    </div>
  );

  const contactSupport = (
    <div>
      <Button action={() => void 0} color="white" emphasis="low" size="xsmall">
        <p>Contactar soporte</p>
      </Button>
    </div>
  );

  return (
    <div className={styles.footer}>
      <div className={styles["info-text"]}>
        <div className={styles["info-text-container"]}>
          <div className={styles.title}>Acerca de</div>
          <div className={styles.buttons}>
            <Button
              emphasis="low"
              color="white"
              size="xsmall"
              action={() => navigate("/conocenos")}
            >
              <p>Conócenos</p>
            </Button>
          </div>
        </div>
        <div className={styles["info-text-container"]}>
          <div className={styles.title}>
            <p>Contacto</p>
          </div>
          <div className={styles.buttons}>
            <Tooltip
              offset={[0, 20]}
              position="right center"
              triggerTooltip={contactSupport}
              isOpen={isOpen}
              handleClose={handleClose}
              handleOpen={handleOpen}
            >
              {tooltipContent}
            </Tooltip>
            <Button
              color="white"
              emphasis="low"
              size="xsmall"
              action={() => navigate("/ayuda")}
            >
              <p>Preguntas frecuentes</p>
            </Button>
            <TermsAndConditions type={"footer"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

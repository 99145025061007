export const COLORS = {
  default: "#666666",
  disabled: "#e9e9e9",
  semantic: "#e6e6e6",
  white: "#ffffff",
  light: {
    light: "#616c7a",
    blue: "#ebf3ff",
  },
  background: {
    default: "#f2f2f2",
    text: "#fbfbfb",
  },
  text: {
    default: "#333333",
    disabled: "#808080",
  },
  link: {
    link: "#0058e1",
    hover: "#003b9c",
  },
  neutral: {
    neutral: "#333333",
    200: "#696969",
    400: "#bbbbbb",
    700: "#252525",
  },
  primary: {
    color: "#FF6B00",
    active: "#8C2C00",
    hover: "#C75004",
  },
  secondary: {
    color: "#1f1f1f",
    active: "#252525",
    hover: "#393939",
  },
  success: {
    color: "#4ba564",
    active: "#008863",
    hover: "#89CF56",
    disabled: "#32d2a7",
  },
  error: {
    error: "#d73a3d",
    active: "#a63631",
    disabled: "#ff9590",
  },
  warning: "#ffb93c",
  info: "#1175c9",
};

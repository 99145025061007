import { useEffect, useState } from "react";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Image } from "config/api/inventory.api.types";
import Tabs from "components/common/Tabs";
import styles from "./Carousel.module.scss";
import Button from "components/common/Button";

interface CarouselProps {
  array: Array<any>;
  type: "images" | "tabs" | "loading";
  maxElementsToShow: number;
  setActiveTab?: any;
  firstTabContentFetched?: boolean;
}
const Carousel: React.FC<CarouselProps> = ({
  array,
  type,
  setActiveTab,
  maxElementsToShow,
  firstTabContentFetched,
}) => {
  const [resultList, setResultList] = useState(Array<any>);
  const [largeImage, setLargeImage] = useState(array[0]);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(maxElementsToShow);

  const lastIndexHasNotReachedEnd = lastIndex < array?.length;
  const firstElementPresent = resultList.indexOf(array[0]) !== -1;

  const moveIndex = (direction: 1 | -1) => {
    setLastIndex(lastIndex + direction);
    setFirstIndex(firstIndex + direction);
  };

  const moveNext = () => {
    if (lastIndexHasNotReachedEnd) {
      moveIndex(1);
    }
  };
  const moveBack = () => {
    if (firstIndex > 0) {
      moveIndex(-1);
    }
  };

  const changeLargeImage = (e: any) => {
    const value = { url: e.target.getAttribute("src") };
    setLargeImage(value);
  };

  useEffect(() => {
    firstElementPresent && setLastIndex(maxElementsToShow);
  }, [maxElementsToShow, firstElementPresent]);

  useEffect(() => {
    const results = array.slice(firstIndex, lastIndex).map((item) => {
      return item;
    });
    setResultList(results);
  }, [firstIndex, lastIndex, array]);

  let content;

  if (type === "images") {
    content = (
      <section className={styles["carousel-images-container"]}>
        <ul className={styles["carousel-images"]}>
          {resultList.map((image: Image) => {
            return (
              <li className={styles.slide} key={image.image_id}>
                <Button
                  color="secondary"
                  emphasis="low"
                  action={(e) => changeLargeImage(e)}
                >
                  <img
                    src={image.url}
                    alt={image.image_id.toString()}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      setResultList(
                        resultList.filter(
                          (image: Image) => image.url !== currentTarget.src
                        )
                      );
                    }}
                  />
                </Button>
              </li>
            );
          })}
        </ul>
      </section>
    );
  }

  if (type === "tabs") {
    content = (
      <div className={styles.tabs}>
        <Tabs
          options={resultList}
          variant="secondary"
          onSelectTab={setActiveTab}
          firstTabContentFetched={firstTabContentFetched}
        />
      </div>
    );
  }

  if (type === "loading") {
    content = (
      <section className={styles["carousel-images-container"]}>
        <ul className={styles["carousel-images"]}>
          <span
            className={`${styles["loading"]} ${styles["slide-circle-loading"]}`}
            style={{ marginLeft: "-55px" }}
          ></span>
          {[...Array(4)].map((e, i) => {
            return (
              <li
                className={`${styles["loading"]} ${styles["slide-loading"]}`}
                key={i}
              ></li>
            );
          })}
          <span
            className={`${styles["loading"]} ${styles["slide-circle-loading"]}`}
            style={{ marginRight: "-55px" }}
          ></span>
        </ul>
      </section>
    );
  }

  const backArrow =
    array?.length > maxElementsToShow && firstIndex !== 0 ? (
      <div className={`${styles["arrow-button"]} ${styles.back}`}>
        <Button color="secondary" emphasis="high" action={() => moveBack()}>
          <ArrowBackIosNewIcon fontSize="small" className={styles["icon"]} />
        </Button>
      </div>
    ) : (
      array?.length > 3 && (
        <div className={`${styles["arrow-button"]} ${styles.back}`}>
          <Button
            action={() => void 0}
            color="secondary"
            emphasis="high"
            curvature="high"
            disabled={true}
          >
            <ArrowBackIosNewIcon fontSize="small" className={styles["icon"]} />
          </Button>
        </div>
      )
    );

  const nextArrow =
    array?.length > maxElementsToShow && lastIndexHasNotReachedEnd ? (
      <div className={`${styles["arrow-button"]} ${styles.next}`}>
        <Button color="secondary" emphasis="high" action={() => moveNext()}>
          <ArrowForwardIosIcon fontSize="small" className={styles["icon"]} />
        </Button>
      </div>
    ) : (
      array?.length > 3 && (
        <div className={`${styles["arrow-button"]} ${styles.next}`}>
          <Button color="secondary" action={() => void 0} disabled={true}>
            <ArrowForwardIosIcon fontSize="small" className={styles["icon"]} />
          </Button>
        </div>
      )
    );

  return (
    <div className={styles["carousel-container"]}>
      {type === "images" ? (
        <div className={styles["large-image"]}>
          {largeImage && largeImage.url && (
            <img
              className={styles["main-image"]}
              src={largeImage.url}
              alt="main"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                setLargeImage("");
              }}
            />
          )}
          {!largeImage ||
            (largeImage.url.length === 0 && (
              <div className={styles["fallback-image"]}>
                <ImageNotSupportedIcon />
              </div>
            ))}
        </div>
      ) : type === "loading" ? (
        <div
          className={`${styles["loading"]} ${styles["loading-image"]}`}
        ></div>
      ) : (
        ""
      )}
      <div className={styles.carousel}>
        {backArrow}
        {array.length > 1 && content}
        {nextArrow}
      </div>
    </div>
  );
};

export default Carousel;

import Button from "components/common/Button/Button";
import styles from "./Sidebar.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { getInitials } from "config/utils";

interface SidebarProps {
  views: { title: string; icon: React.ReactNode }[];
  selectedView: string;
  setSelectedView: any;
}

const Sidebar: React.FC<SidebarProps> = ({
  views,
  selectedView,
  setSelectedView,
}) => {
  const { user } = useAuth0();
  const userInitials = getInitials(
    user?.name || `${user?.given_name?.split(" ")[0]} ${user?.family_name}`
  );
  return (
    <div className={styles.sidebar}>
      <div className={styles.profileImg}>
        <div className={styles.profileImgText}>
          <span className={styles.avatarText}>{userInitials}</span>
        </div>
      </div>
      <div className={styles.navList}>
        {views.map((item) => {
          return (
            <div
              key={item.title}
              className={
                styles[`item${selectedView === item.title ? "Active" : ""}`]
              }
            >
              <Button
                emphasis="low"
                action={() => {
                  setSelectedView(item.title);
                }}
                key={item.title}
              >
                <div className={styles["wrapper"]}>
                  <span className={styles.icon}>{item.icon}</span>
                  <span className={styles.text}>{item.title}</span>
                </div>
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;

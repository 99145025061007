import validator from "validator";

/*
  Controls the user experience of handling number inputs to avoid mistaken letters
  still allowing controls like copying, selecting, pasting
 */

export const numericInputHandleKeyDown = (
  e: { key: string; preventDefault: () => void; },
  currentValue: string | undefined,
  valueMaxLength: number,
  ctrlPressed: boolean,
  setCtrlPressed: (value: (((prevState: boolean) => boolean) | boolean)) => void
) => {

  if(e.key === "Control") {
    setCtrlPressed(true);
  }
  const copy = ctrlPressed && (e.key === "c" || e.key === "C");
  const paste = ctrlPressed && (e.key === "v" || e.key === "V");
  const cut = ctrlPressed && (e.key === "x" || e.key === "X");
  const refresh = ctrlPressed && (e.key === "r" || e.key === "R");
  const selectingAll = ctrlPressed && (e.key === "a" || e.key === "A");
  const actionButton = ["Tab", "ArrowDown", "ArrowLeft",
    "ArrowRight", "ArrowUp", "Shit", "End", "Home"]
    .find(action => action === e.key);
  if ((
    ((currentValue?.length ?? 0) >= valueMaxLength && e.key !== "Backspace") ||
    (!validator.isNumeric(e.key) && e.key !== "Backspace")
  ) && !(paste || selectingAll || copy || cut || refresh || actionButton)
  ) {
    e.preventDefault();
  }
};

export const numericInputHandleKeyUp = (
  e: { key: string; preventDefault: () => void },
  setCtrlPressed: (value: (((prevState: boolean) => boolean) | boolean)) => void
) => {
  if(e.key === "Control") {
    setCtrlPressed(false);
  }
};
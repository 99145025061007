import Section from "../../../../components/common/Section";
import { ReasonFormInputs } from "../../../../components/common/common.types";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import React, { useEffect } from "react";
import TextArea from "components/Form/TextArea";
import styles from "./ReasonStep.module.scss";

interface ReasonInfoProps {
  setReasonDetails: (reasonData: UseFormReturn<ReasonFormInputs, any>) => void;
}

const ReasonStep: React.FC<ReasonInfoProps> = ({ setReasonDetails }) => {
  const methods = useForm<ReasonFormInputs>();
  useEffect(() => {
    sendUpdatedReasonData();
  });

  const sendUpdatedReasonData = () => {
    setTimeout(() => {
      setReasonDetails(methods);
    });
  };

  return (
    <Section
      title="3. Motivo de compra"
      titleSize="small"
      widthSize="full"
      variant="no-margin-title"
    >
      <FormProvider {...methods}>
        <form
          onChange={sendUpdatedReasonData}
          onSubmit={methods.handleSubmit(sendUpdatedReasonData)}
          id="reasonForm"
          name="reasonForm"
        >
          <div className={styles["textarea-wrapper"]}>
            <TextArea
              label={"¿Cuál es el motivo de tu compra?"}
              required={true}
              placeholder={
                "Compártenos que uso le darás a los materiales que compraste."
              }
              type={"text"}
              name={"reason"}
              maxLength={250}
            />
          </div>
        </form>
      </FormProvider>
    </Section>
  );
};

export default ReasonStep;

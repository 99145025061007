export const getBase64 = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => resolve("error");
  });
};

export const removeAccents = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const addDotAtTheEndOf = (text: string) => {
  const lastChar = text.slice(-1);
  return [".", ":"].includes(lastChar) ? text : text + ".";
};

export const getPriceFormat = (number: number) => {
  const stringNumber = number.toString();
  const truncatedNumber = stringNumber.match(/^-?\d+(?:\.\d{0,2})?/);

  return truncatedNumber?.length
    ? Number(truncatedNumber[0]).toLocaleString("es-MX")
    : "";
};

export const getInitials = (input: string) => {
  const words = input.split(/\s+/).filter((word) => word.length > 0);
  let initials = "";
  for (const word of words) {
    if (initials.length < 2) {
      initials += word.charAt(0).toUpperCase();
    } else {
      break;
    }
  }
  return initials;
};

export const orderCreatedStatuses = {
  ORDERED: "ORDERED",
  CHARGE_PENDING: "CHARGE_PENDING",
  FAILED: "FAILED",
  COMPLETED: "COMPLETED",
  ORDER_SUCCEDED: "CHARGE.SUCCEEDED",
};

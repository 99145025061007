export const newProductToMakeAvailable = (
  userEmail: string,
  sku: string,
  properties: any,
  url: string
) => {
  const propertiesKey = Object.keys(properties);
  const articleProperties = propertiesKey
    .map((key) => {
      return `<li key=${key}>${key} : ${properties[key]}</li>`;
    })
    .join("");
  const message = `<html>
  <head>
  <style>
  ul.list {
    list-style-type: square;
  }
  </style>
  </head>
  <body>
  <p>Hola,</p>
  <p>El usuario ${userEmail} solicita dar de alta el producto con SKU: ${sku}</p>
  <p>Te agradecemos contactar al usuario para dar seguimiento a la solicitud, para hacerle saber que el producto con la siguiente información ya fue activado:</p>
  <p>SKU: ${sku}</p>
  <p>Url: ${url}</p>
  <p>Características:</p>
  <ul class="list">${articleProperties}</ul>
  </body></html>`;
  return message;
};

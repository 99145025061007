import { TabItem } from "components/common/common.types";
import Button from "components/common/Button";
import styles from "./Tab.module.scss";

export interface TabProps {
  tab: TabItem;
  variant: "medium" | "high";
  onSelect: (tabId: TabItem) => void;
}

const Tab: React.FC<TabProps> = ({ tab, variant, onSelect }) => {
  const setActive = () => onSelect(tab);
  return (
    <div className={styles.tab}>
      <Button emphasis={variant} action={() => setActive()}>
        <p className={styles.content}>{tab.title}</p>
      </Button>
    </div>
  );
};

export default Tab;

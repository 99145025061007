import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import styles from "./StateIcon.module.scss";

interface StateIconProps {
  state: "success" | "error" | "info-error" | "refresh";
}

const StateIcon: React.FC<StateIconProps> = ({ state }) => {
  const icons = {
    error: <FontAwesomeIcon icon={faTriangleExclamation} size="3x" />,
    success: <FontAwesomeIcon icon={faCircleCheck} size="3x" />,
    "info-error": (
      <FontAwesomeIcon icon={faCircleExclamation} size="3x" color="#D73A3D" />
    ),
    refresh: (
      <div className={styles.refresh}>
        <FontAwesomeIcon icon={faArrowsRotate} size="2x" color="white" />
      </div>
    ),
  };

  return <div className={styles[state]}>{icons[state]}</div>;
};

export default StateIcon;

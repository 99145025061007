import { OptionsField } from "forms/form.types";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./ZipCodeField.module.scss";
import fieldStyles from "../Field/Field.module.scss";

const ZipCodeField: React.FC<OptionsField> = ({
  name,
  value,
  placeholder,
  label,
  required,
  type,
  disabled,
  apiCall,
  errorMessage = "",
  fieldCaption = "",
  autoComplete = "off",
  customProps = {},
}) => {
  const [error, setError] = useState("");
  const methods = useFormContext();
  const [currentValue, setCurrentValue] = useState(value);
  const [status, setStatus] = useState("");
  const [validZipCode, setValidZipCode] = useState(true);

  useEffect(() => {
    methods.clearErrors(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const validateText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    text !== "" ? setStatus("writing") : setStatus("not writing");
  };

  const activeError = error !== "";
  const emptyFieldWhenRequired =
    methods.formState.errors[name] &&
    methods.formState.errors[name].type === "required";

  const valueNotChanged = currentValue === value;

  const errorStyle =
    activeError || (emptyFieldWhenRequired && valueNotChanged)
      ? "input-error"
      : "";

  activeError &&
    (methods.formState.errors[name] = { type: "validation", message: error });

  const caption = fieldCaption && (
    <span className={fieldStyles["field-caption"]}>{fieldCaption}</span>
  );

  const validateZipCode = async (zipcode: string) => {
    setError("...");
    await apiCall!(zipcode)
      .then((data) => {
        if (data.location) {
          setValidZipCode(true);
          setError("");
          methods.clearErrors(name);
        } else {
          setValidZipCode(false);
          setError(errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const requiredMessage = emptyFieldWhenRequired && valueNotChanged && (
    <span className={styles["error-text"]}>Este campo es requerido</span>
  );

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <input
        {...methods.register(name, {
          value,
          required,
          validate: (value) => {
            validateZipCode(value);
            let isValid = value !== "" && validZipCode;
            return isValid;
          },
        })}
        className={`${styles.input} ${styles[errorStyle]}`}
        defaultValue={currentValue}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
        onChange={(e) => {
          const value = e.target.value;
          setCurrentValue(value);
          methods.formState.errors[name] && validateText(e);
          methods.setValue(name, value);
          errorMessage && value.length >= 5 && validateZipCode(value);
        }}
        {...customProps}
      />
      {caption}
      {requiredMessage}
      {activeError && <div className={styles["error-text"]}> {error}</div>}
    </div>
  );
};

export default ZipCodeField;

import { useMemo, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NotificationDataProvider } from "config/notificationDataContext";
import Home from "views/Home";
import Search from "views/Search";
import Signup from "views/Signup";
import PageNotFound from "views/PageNotFound";
import FrequentQuestions from "views/FrequentQuestions";
import AboutUs from "views/AboutUs";
import ShoppingCart from "views/ShoppingCart";
import MyProfileView from "views/MyProfile/MyProfileView";
import ProductDetailView from "views/ProductDetailView";
import ProtectedRoute from "components/ProtectedRoute";
import Header from "components/Header";
import Footer from "components/Footer";
import Notification from "components/common/Notification";
import UserHandler from "components/UserHandler";
import ShoppingCartHandler from "components/ShoppingCartHandler";

const App = () => {
  const [notificationData, setNotificationData] = useState(null);
  const NotificationDataStatus = useMemo(
    () => ({
      notificationData,
      setNotificationData,
    }),
    // eslint-disable-next-line
    [notificationData]
  );

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <UserHandler>
        <Router>
          <ShoppingCartHandler>
            <NotificationDataProvider value={NotificationDataStatus}>
              <Header />
              <Routes>
                <Route path="/signup" element={<Signup />}></Route>
                <Route path="/" element={<Home />}></Route>
                <Route element={<ProtectedRoute />}>
                  <Route path="/miperfil" element={<MyProfileView />}></Route>
                  <Route path="/buscar/:query" element={<Search />}></Route>
                  <Route
                    path="/producto/:productId"
                    element={<ProductDetailView />}
                  ></Route>
                  <Route
                    path="/carrito/:cartStep"
                    element={<ShoppingCart />}
                  ></Route>
                  <Route path="/ayuda" element={<FrequentQuestions />}></Route>
                  <Route path="/conocenos" element={<AboutUs />}></Route>
                  <Route path="/404" element={<PageNotFound />}></Route>
                  <Route path="*" element={<Navigate to="/404" />} />
                </Route>
              </Routes>
              <Footer />
              <Notification top={true} />
            </NotificationDataProvider>
          </ShoppingCartHandler>
        </Router>
      </UserHandler>
    </QueryClientProvider>
  );
};

export default App;

import { useState, useMemo } from "react";
import { ArticleDetail } from "config/api/inventory.api.types";
import { Offer } from "config/api/offers.types";
import { SelectedOfferProvider } from "config/selectedOfferContext";
interface ShoppingCartContextWrapperProps {
  children: React.ReactNode;
}
const ShoppingCartContextWrapper: React.FC<ShoppingCartContextWrapperProps> = ({
  children,
}) => {
  const [selectedOffer, setSelectedOffer] = useState({} as Offer);
  const [selectedOfferIndex, setSelectedOfferIndex] = useState(-1 as Number);
  const [offerArticles, setOfferArticles] = useState([] as ArticleDetail[]);
  const [offerId, setOfferId] = useState("" as String);
  const selectedOfferValue = useMemo(
    () => ({
      selectedOffer,
      setSelectedOffer,
      selectedOfferIndex,
      setSelectedOfferIndex,
      offerArticles,
      setOfferArticles,
      offerId,
      setOfferId,
    }),
    [selectedOffer, selectedOfferIndex, offerArticles, offerId]
  );
  return (
    <SelectedOfferProvider value={selectedOfferValue}>
      {children}
    </SelectedOfferProvider>
  );
};

export default ShoppingCartContextWrapper;

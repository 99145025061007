import React from "react";
import { useEffect } from "react";
import styles from "./FrequentQuestions.module.scss";
import data from "./data";
import Section from "components/common/Section";
import ExpandableContent from "components/common/ExpandableContent";
import View from "components/common/View/View";

const FrequentQuestions: React.FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <View>
      <Section
        title="Preguntas frecuentes"
        titleSize="large"
        widthSize="medium"
      >
        {data.map((data, key) => {
          return (
            <ExpandableContent
              title={data.title}
              isOpen={data.isOpen}
              key={key}
            >
              <div className={styles["question-content-container"]}>
                <div className={styles.content}>{data.content}</div>
              </div>
            </ExpandableContent>
          );
        })}
      </Section>
    </View>
  );
};

export default FrequentQuestions;

import { useContext, useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useLocalStorage from "components/hooks/useLocalStorage";
import ShoppingCartContext from "config/shoppingCartContext";
import { getProductPrice } from "config/api/inventory.api";
import Button from "components/common/Button";
import Divider from "components/common/Divider";
import sumProductsPrice from "components/common/sumProductsPrice";
import { CartProduct } from "components/common/common.types";
import styles from "./NewProductAddedNotification.module.scss";
import { getPriceFormat } from "config/utils";

interface NotificationProps {
  navigateToCart: () => void;
}

const NewProductAddedNotification: React.FC<NotificationProps> = ({
  navigateToCart,
}) => {
  const [totalPriceProducts, setTotalPriceProducts] = useState(0.0);
  const { shoppingCartItems } = useContext(ShoppingCartContext);
  const userData = useLocalStorage("userData", {}).storedValue;
  const userId = Number(userData["id"]);
  const locationId = Number(
    userData["locationData"] ? userData["locationData"][0]["id"] : 0
  );
  const { setShoppingCartItems } = useContext(ShoppingCartContext);
  const updateProductsPrice = (shoppingCartItems: Array<CartProduct>) => {
    const newItemsArray: any = shoppingCartItems.map(async (item) => {
      await getProductPrice(userId, locationId, `{"${item.articleId}":1}`)
        .then((priceData: any) => {
          const productPrice = priceData.total ?? 0;
          item.price = productPrice;
        })
        .catch((error) => {
          console.log(error);
        });

      return item;
    });

    Promise.all(newItemsArray).then((productsWithUpdatedPrice) => {
      setShoppingCartItems(productsWithUpdatedPrice);
    });
  };

  useEffect(() => {
    updateProductsPrice(shoppingCartItems);
    setTotalPriceProducts(sumProductsPrice(shoppingCartItems));

    // eslint-disable-next-line
  }, [sumProductsPrice(shoppingCartItems)]);

  const lastItemInCart = shoppingCartItems.slice(-1)[0];
  return (
    <div className={styles["notification-content"]}>
      <div>
        {lastItemInCart.srcImage.length > 0 && (
          <img
            src={lastItemInCart.srcImage}
            className={styles["notification-image"]}
            alt="shoppingCartImage"
          />
        )}
        <div className={styles["small-not-container"]}>
          <div className={styles["icon-info"]}>
            <CheckCircleIcon className={styles["check-circle"]} />
            Se agregó a tu carrito
          </div>
          <div className={styles.bold}>{lastItemInCart.title}</div>
        </div>
        <Divider color="grey" type="vertical-right" />
        <div className={styles["small-not-container"]}>
          <div>
            {shoppingCartItems.length}
            {shoppingCartItems.length === 1 ? " Producto" : " Productos"} en tu
            carrito
          </div>
          <div className={styles.bold}>
            $
            {totalPriceProducts === 0
              ? "-"
              : getPriceFormat(totalPriceProducts)}
          </div>
        </div>
      </div>
      <div>
        <div className={styles.button}>
          <Button
            color="primary"
            emphasis="high"
            curvature="high"
            action={() => navigateToCart()}
          >
            Ver carrito
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewProductAddedNotification;

import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { MultiFormProvider } from "config/multiFormContext";
import { DynamicDataProvider } from "config/dynamicDataContext";
import signupMessage from "config/signupMessages";
import { getBase64 } from "config/utils";
import { createUser } from "config/api/user.api";
import { generalSignupForm } from "forms/Signup/Signup.general";
import { deliveryLocationSignupForm } from "forms/Signup/Signup.deliveryLocation";
import { fiscalSignupForm } from "forms/Signup/Signup.fiscalLocation";
import { Form } from "forms/form.types";
import BgSection from "components/common/BgSection";
import View from "components/common/View";
import MultipleForm from "components/Form/MultipleForms";
import Stepper from "components/Form/Stepper";
import Popup from "components/common/Popup";
import Button from "components/common/Button";
import Link from "components/common/Link";
import LocationController from "./SignupController";
import styles from "./Signup.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import MailTo from "components/common/MailTo";

const Signup = () => {
  const forms = [
    generalSignupForm,
    deliveryLocationSignupForm,
    fiscalSignupForm,
  ];
  const navigate = useNavigate();
  const formState = useForm();
  const [activeForm, setActiveForm] = useState(forms[0] as Form);
  const [reStartForm, setReStartForm] = useState(false);
  const [openPopup, setopenPopup] = useState(false);
  const [PopupState, setPopupState] = useState<"success" | "error">("success");
  const [loading, setLoading] = useState(false);
  const [dynamicData, setDynamicData] = useState({} as any);
  const { user } = useAuth0();
  const username = user?.nickname;

  const onSubmit = async (data: FieldValues) => {
    const fiscalDoc = data["fiscalDocument"][0] as File;
    let fiscalDocAsB64 = "";
    await getBase64(fiscalDoc).then((data) => {
      fiscalDocAsB64 = (data as string).split(",")[1];
    });

    const userData = {
      name: data["name"]
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toUpperCase(),
      fatherLastName: data["fatherLastName"]
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toUpperCase(),
      motherLastName: data["motherLastName"]
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toUpperCase(),
      rfc: data["fiscalRfc"].toUpperCase(),
      curp: data["fiscalCurp"].toUpperCase(),
      phone: data["phone"],
      fiscalDoc: fiscalDocAsB64,
      email: data["email"].toUpperCase(),
      additionalEmail: data["fiscalEmail"].toUpperCase(),
      password: data["password"],
    };

    const parseLatandLon = JSON.parse(data["googleMapLatLng"]);
    const userDeliveryAddress = {
      countryId: 1,
      country: "MÉXICO",
      zipCode: parseInt(data["deliveryAddressZipCode"]),
      stateId: parseInt(dynamicData.deliveryAddress["stateId"]),
      state: data["deliveryAddressState"].toUpperCase(),
      cityId: parseInt(dynamicData.deliveryAddress["cityId"]),
      city: data["deliveryAddressCity"].toUpperCase(),
      neighborhoodId: parseInt(data["deliveryAddressNeighborhoodId"]),
      neighborhood: data["deliveryAddressNeighborhood"],
      street: data["deliveryAddressStreet"].toUpperCase(),
      externalNumber: data["deliveryAddressExternalNumber"],
      internalNumber: data["deliveryAddressInternalNumber"],
      lat: parseLatandLon.lat,
      lon: parseLatandLon.lng,
    };

    const userFiscalAddress = {
      countryId: 1,
      country: "MÉXICO",
      zipCode: parseInt(data["fiscalZipCode"]),
      stateId: parseInt(dynamicData.fiscalAddress["stateId"]),
      state: data["fiscalState"].toUpperCase(),
      cityId: parseInt(dynamicData.fiscalAddress["cityId"]),
      city: data["fiscalCity"].toUpperCase(),
      neighborhoodId: parseInt(data["fiscalNeighborhoodId"]),
      neighborhood: data["fiscalAddressNeighborhood"],
      street: data["fiscalStreet"].toUpperCase(),
      externalNumber: data["fiscalExternalNumber"],
      internalNumber: data["fiscalInternalNumber"],
    };

    const user = {
      user: userData,
      deliveryAddress: userDeliveryAddress,
      fiscalAddress: userFiscalAddress,
    };

    await createUser(user)
      .then(() => {
        setLoading(false);
        setopenPopup(true);
        setPopupState("success");
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        setReStartForm(false);
        setPopupState("error");
        setopenPopup(true);
      });
  };

  const signupForms = useMemo(
    () => ({
      forms,
      activeForm,
      setActiveForm,
    }),
    // eslint-disable-next-line
    [activeForm]
  );

  const formDynamicData = useMemo(
    () => ({
      dynamicData,
      setDynamicData,
    }),
    // eslint-disable-next-line
    [dynamicData]
  );

  if (username) {
    navigate("../");
  }

  const popupContent = (
    <div className={styles["popup-content"]}>
      {signupMessage[PopupState].content}
      {PopupState === "error" && (
        <MailTo email="beneficios@deacero.com">
          {signupMessage[PopupState].foreignProp}
        </MailTo>
      )}
    </div>
  );

  const popupMainButton = (
    <Button
      emphasis="high"
      color={PopupState === "error" ? "secondary" : "success"}
      action={() => {
        setopenPopup(false);
        if (PopupState === "error") {
          setActiveForm(forms[0]);
          setReStartForm(true);
          navigate("/signup");
        } else {
          navigate("/");
        }
      }}
    >
      {signupMessage[PopupState].buttonContent}
    </Button>
  );

  return (
    <DynamicDataProvider value={formDynamicData}>
      <MultiFormProvider value={signupForms}>
        <View>
          <div className={styles.container}>
            <div className={styles["form-container"]}>
              <FormProvider {...formState}>
                <LocationController />
                <MultipleForm
                  inputForms={forms}
                  onSubmit={() => {
                    setLoading(true);
                    onSubmit(formState.getValues());
                  }}
                  reStartForm={reStartForm}
                  action={
                    <div>
                      <Link href={"/"} text="Ir a inicio de sesión" />
                    </div>
                  }
                  isLoading={loading}
                />
              </FormProvider>
            </div>
            <BgSection color="primary" orientation="vertical" position="right">
              <Stepper
                title="Es muy fácil y rápido"
                selectedStep={activeForm.formTitle}
                steps={forms.map((form) => {
                  return { title: form.formTitle, element: form.formTitle };
                })}
              />
            </BgSection>
          </div>
        </View>
        <Popup
          title={signupMessage[PopupState].title}
          state={PopupState === "success" ? PopupState : "info-error"}
          content={popupContent}
          button={popupMainButton}
          open={openPopup}
          maxWidth={"xs"}
          padding={false}
        />
      </MultiFormProvider>
    </DynamicDataProvider>
  );
};

export default Signup;

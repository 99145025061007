import { useContext } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useParams } from "react-router-dom";
import ShoppingCartContext from "config/shoppingCartContext";
import Products from "views/Products";
import Payment from "views/Payment";
import Offers from "views/Offers";
import ShoppingCartItemsNotFound from "views/ShoppingCartItemsNotFound";
import Stepper from "components/Form/Stepper";
import Section from "components/common/Section";
import Banner from "components/common/Banner";
import View from "components/common/View/View";
import ShoppingCartData from "./ShoppingCartData";
import ShoppingCartContextWrapper from "./ShoppingCartContextWrapper";
import OfferBanner from "./OfferBanner/OfferBanner";

const pageLocation = {
  productos: Products,
  ofertas: Offers,
  pago: Payment,
};

const stepperIcons = [
  { title: "productos", element: <ShoppingCartIcon /> },
  { title: "ofertas", element: <LocalMallIcon /> },
  { title: "pago", element: <CreditCardIcon /> },
];

type ShoppingCartRoute = "productos" | "ofertas" | "pago";

const ShoppingCart: React.FC = () => {
  const { cartStep } = useParams();
  const cartStepIsValidRoute =
    cartStep && ["productos", "ofertas", "pago"].includes(cartStep);
  const section = cartStepIsValidRoute ? cartStep : "productos";
  const CartSection = pageLocation[section as ShoppingCartRoute];
  const { shoppingCartItems } = useContext(ShoppingCartContext);
  let totalPrice = 0;
  shoppingCartItems.forEach(
    (item) => (totalPrice += item.price * item.quantity)
  );

  const productsBanner =
    cartStep === "productos" && totalPrice < 4000 ? (
      <Banner
        size="large"
        type="warning"
        message="Antes de realizar tus compras te recordamos que por motivos de política de
la empresa, el mínimo permitido de compra es de $4,000 MXN."
      />
    ) : (
      totalPrice > 200000 && (
        <Banner
          size="large"
          type="warning"
          message="Antes de realizar tus compras te recordamos que por motivos de política de
la empresa, el máximo permitido de compra es de $200,000 MXN."
        />
      )
    );

  const offertBanner = cartStep === "ofertas" && <OfferBanner />;

  if (shoppingCartItems.length === 0) {
    return <ShoppingCartItemsNotFound />;
  }
  return (
    <View>
      <Section>
        <ShoppingCartContextWrapper>
          <Stepper
            variant="icon-stepper"
            orientation="horizontal"
            steps={stepperIcons}
            selectedStep={cartStep ?? ""}
          />
          {offertBanner}
          {productsBanner}
          <ShoppingCartData>
            <CartSection />
          </ShoppingCartData>
        </ShoppingCartContextWrapper>
      </Section>
    </View>
  );
};

export default ShoppingCart;

import styles from "./Spinner.module.scss";

type availableColors = "white" | "black" | "blue";
type availableSizes = "small" | "big";
interface SpinnerProps {
  color?: availableColors;
  size?: availableSizes;
}

const Spinner: React.FC<SpinnerProps> = ({
  color = "white",
  size = "small",
}) => {
  return (
    <div className={`${styles.spinner} ${styles[size]} ${styles[color]}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;

import { Option, OptionsField } from "forms/form.types";
import { useContext, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useFormContext } from "react-hook-form";
import fieldStyles from "../Field/Field.module.scss";
import styles from "./Dropdown.module.scss";

const Dropdown: React.FC<OptionsField> = ({
  name,
  label,
  value,
  required,
  disabled,
  options,
  dynamicOptionsProp = "",
  optionsContext,
  fieldCaption,
}) => {
  const methods = useFormContext();
  const dynamicOptionsContext = useContext(
    optionsContext || ({} as any)
  ) as any;

  const [currentValue, setCurrentValue] = useState(value);
  const isDeliveryAddress = name === "deliveryAddressNeighborhoodId";
  const emptyFieldWhenRequired =
    methods.formState.errors[name] &&
    methods.formState.errors[name].type === "required";

  const emptyValue = { value: "", label: "-" };
  const dynamicOptions = dynamicOptionsContext["dynamicData"][
    dynamicOptionsProp
  ]
    ? dynamicOptionsContext["dynamicData"][dynamicOptionsProp][name]
    : options
    ? options
    : [];

  const availableOptions = [emptyValue, ...dynamicOptions];

  const renderOptions =
    availableOptions?.length &&
    availableOptions.map((option: Option) => {
      return (
        <option
          key={option.value}
          value={option.value}
          id={`${option.value},${option.label}`}
        >
          {option.label}
        </option>
      );
    });

  const caption = fieldCaption && (
    <span className={fieldStyles["field-caption"]}>{fieldCaption}</span>
  );

  const requiredMessage = emptyFieldWhenRequired && currentValue === "" && (
    <span className={styles["error-text"]}>Este campo es requerido</span>
  );

  const errorStyle =
    emptyFieldWhenRequired && currentValue === "" ? "input-error" : "";

  const requiredRule = required && currentValue === "";

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <div className={`${styles["input-container"]} ${styles[errorStyle]}`}>
        <select
          {...methods.register(name, { value, required: requiredRule })}
          defaultValue={currentValue}
          disabled={disabled}
          onChange={(e) => {
            const valueAndLabel = e.target.selectedOptions[0].id;
            const labelName = valueAndLabel.split(",")[1];
            const locationType = isDeliveryAddress
              ? "deliveryAddressNeighborhood"
              : "fiscalAddressNeighborhood";
            methods.setValue(locationType, labelName);
            const value = e.target.value;
            setCurrentValue(value);
          }}
        >
          {renderOptions}
        </select>
        <div className={styles.arrow}>
          <ArrowDropDownIcon />
        </div>
      </div>
      {caption}
      {requiredMessage}
    </div>
  );
};

export default Dropdown;

import Divider from "components/common/Divider";
import { ArticleDetail } from "config/api/inventory.api.types";
import styles from "./PaymentItem.module.scss";
import { getPriceFormat } from "config/utils";

interface PaymentItemProps {
  article: ArticleDetail;
}

const PaymentItem: React.FC<PaymentItemProps> = ({ article }) => {
  const characteristicsTotal = Object.entries(article.characteristics).map(
    ([key, value]) => {
      return { prop: key, value: value };
    }
  );

  const productPrice =
    Math.ceil(Math.round(article.price * article.quantity * 100 * 1e9) / 1e9) /
    100;

  const productDetails = characteristicsTotal.map((characteristic: any) => (
    <p key={`${characteristic.prop}-${characteristic.value}`}>
      {characteristic.prop} : {characteristic.value}
    </p>
  ));

  return (
    <div>
      <div className={styles["flex-space-between"]}>
        <div className={styles["list-products-column"]}>
          <p>{article.name}</p>
          <div
            className={`${styles["detail-delete-container"]} ${styles["flex-space-between"]}`}
          >
            <div className={styles.detail}>{productDetails}</div>
          </div>
        </div>
        <div className={styles["quantity-price"]}>
          <div className={styles["quantity-column"]}>
            <p>{article.quantity}</p>
          </div>
          <div className={styles["price-column"]}>
            <p>${getPriceFormat(productPrice)}</p>
          </div>
        </div>
      </div>
      <div className={styles["check-divider"]}>
        <Divider color="grey" margin={false} />
      </div>
    </div>
  );
};

export default PaymentItem;

import styles from "./Section.module.scss";

interface SectionProps {
  title?: string | React.ReactNode;
  titleSize?: "small" | "medium" | "large";
  children?: React.ReactNode;
  variant?: "margin-title" | "no-margin-title";
  widthSize?: "medium" | "large" | "full";
  action?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({
  title,
  titleSize = "medium",
  children,
  variant = "margin-title",
  widthSize = "large",
  action,
}) => {
  const actionClass = action ? "action" : "";
  return (
    <div
      className={`${styles[`${widthSize}-container-width`]} ${
        styles.container
      }`}
    >
      <div
        className={`${styles[`${titleSize}-title`]} ${styles[variant]} ${
          styles[actionClass]
        }`}
      >
        {title}
        {action}
      </div>
      <div className={styles.children}> {children} </div>
    </div>
  );
};

export default Section;

import Section from "components/common/Section";
import Card from "components/common/Card";
import Carousel from "components/common/Carousel";
import Divider from "components/common/Divider";
import styles from "./LoadingProduct.module.scss";

interface LoadingProductProps {
  maxElementsToShow: number;
}

const LoadingProduct: React.FC<LoadingProductProps> = ({
  maxElementsToShow,
}) => {
  return (
    <Section>
      <div className={styles["details-container"]}>
        <div className={styles["images-container"]}>
          <div className={styles["carousel-container"]}>
            <Carousel
              array={[
                { image_id: 0, url: "" },
                { image_id: 0, url: "" },
              ]}
              type="loading"
              maxElementsToShow={maxElementsToShow}
            />
          </div>
        </div>

        <div className={styles["product-props-container"]}>
          <p className={`${styles["loading"]} ${styles["title-loading"]}`}></p>
          <Divider color="grey" />
          <p
            className={`${styles["loading"]} ${styles["subtitle-loading"]}`}
          ></p>
          {[...Array(4)].map((e, i) => (
            <div key={i}>
              <p
                className={`${styles["loading"]} ${styles["values-title-loading"]}`}
              ></p>
              <span
                className={`${styles["loading"]} ${styles["values-container-loading"]}`}
              ></span>
              <span
                className={`${styles["loading"]} ${styles["values-container-loading"]}`}
              ></span>
            </div>
          ))}
        </div>

        <div className={styles.summary}>
          <Card>
            <p
              className={`${styles["loading"]} ${styles["price-loading"]}`}
            ></p>
            <div className={styles["price-box"]}>
              <div className={styles["quantity-container"]}>
                <p
                  className={`${styles["loading"]} ${styles["quantity-title-loading"]}`}
                ></p>
                <p
                  className={`${styles["loading"]} ${styles["quantity-label-loading"]}`}
                ></p>
                <p
                  className={`${styles["loading"]} ${styles["quantity-input-loading"]}`}
                ></p>
                <p
                  className={`${styles["loading"]} ${styles["quantity-button-loading"]}`}
                ></p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Section>
  );
};

export default LoadingProduct;

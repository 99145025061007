import styles from "./GridContainer.module.scss";

interface GridContainerProps {
  children: React.ReactNode;
  perRow: number;
}

const GridContainer: React.FC<GridContainerProps> = (props) => {
  var row = props.perRow-1;
  var rowStyle = "auto";
  while (row > 0){
    rowStyle += " auto";
    row --;
  }
  return (
    <div>
      <div className={styles.container} style={{ gridTemplateColumns: rowStyle }}>{props.children}</div>
    </div>
  );
};

export default GridContainer;

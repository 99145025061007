import { CartProduct } from "components/common/common.types";
import useLocalStorage from "components/hooks/useLocalStorage";
import { getShoppingCartItems } from "config/api/shoppingCartItems.api";
import { ShoppingCartProvider } from "config/shoppingCartContext";
import { useEffect, useMemo, useState } from "react";

interface ShoppingCartHandlerProps {
  children?: React.ReactNode;
}
const ShoppingCartHandler: React.FC<ShoppingCartHandlerProps> = ({
  children,
}) => {
  const userData = useLocalStorage("userData", {}).storedValue;

  const [shoppingCartItems, setShoppingCartItems] = useState(
    [] as CartProduct[]
  );

  const shoppingCartValue = useMemo(
    () => ({ shoppingCartItems, setShoppingCartItems }),
    [shoppingCartItems]
  );

  const getItemsByUser = async (userId: number) => {
    await getShoppingCartItems(userId)
      .then((data) => {
        const items = data.map((item: any) => {
          if (item.price > 0) {
            return item;
          } else {
            return {
              ...item,
              price: 0,
            };
          }
        });
        setShoppingCartItems(items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const userId = Number(userData["id"]);
    if (!isNaN(userId)) {
      getItemsByUser(userId);
    }
  }, [userData]);

  return (
    <ShoppingCartProvider value={shoppingCartValue}>
      {children}
    </ShoppingCartProvider>
  );
};

export default ShoppingCartHandler;

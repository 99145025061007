import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { OptionsField } from "forms/form.types";
import Map from "components/common/Map";
import { getLatLngFromPostalCode } from "config/api/api";
import { requestStatus } from "config/statusTypes";
import fieldStyles from "../Field/Field.module.scss";
import styles from "./MapField.module.scss";

const MapField: React.FC<OptionsField> = ({
  label,
  name,
  required,
  errorMessage,
  fieldCaption,
}) => {
  const methods = useFormContext();
  const zipCode = methods.watch("deliveryAddressZipCode");
  const [latitude, setLatitude] = useState(25.65716);
  const [longitude, setLongitude] = useState(-100.40268);
  const [center, setCenter] = useState({ lat: 25.65716, lng: -100.40268 });
  const [currentZoom, setCurrentZoom] = useState(14);
  const [mapWasClicked, setMapWasClicked] = useState(false);

  const caption = fieldCaption && (
    <span className={fieldStyles["field-caption"]}>{fieldCaption}</span>
  );

  useEffect(() => {
    zipCode &&
      zipCode.length === 5 &&
      getLatLngFromPostalCode(parseInt(zipCode), "MX").then((data) => {
        if (data.status === requestStatus.OK)
          setCenter(data.results[0].geometry.location);
      });
  }, [zipCode]);

  useEffect(() => {
    setCenter({ lat: latitude, lng: longitude });
  }, [latitude, longitude]);

  useEffect(() => {
    methods.register(name, {
      value: JSON.stringify(center),
      required,
      validate: () => mapWasClicked,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  const mapClicked = (ev: any) => {
    setMapWasClicked(true);
    methods.setValue(name, JSON.stringify(center));
    setLatitude(ev.latLng!.lat());
    setLongitude(ev.latLng!.lng());
  };

  function handleZoomChanged(newZoom: number) {
    setCurrentZoom(newZoom);
  }

  const errorStyle =
    !mapWasClicked && methods.formState.errors[name] ? "error-border" : "";

  return (
    <div className={styles.container}>
      <label className={styles["map-info"]}>{label}</label>
      {!mapWasClicked && methods.formState.errors[name] && (
        <div className={styles["error-text"]}> {errorMessage}</div>
      )}
      {caption}
      <div className={`${styles["map-container"]} ${styles[errorStyle]}`}>
        <Map
          center={center}
          zoom={currentZoom}
          mapClicked={mapClicked}
          onZoomChanged={handleZoomChanged}
        />
      </div>
    </div>
  );
};

export default MapField;

import { deliveryProfileForm } from "forms/Profile/Profile.delivery";
import styles from "./DeliveryData.module.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import UserContext from "config/userContext";
import { patchUser } from "config/api/user.api";
import MultipleForms from "components/Form/MultipleForms";
import { DynamicDataProvider } from "config/dynamicDataContext";
import LocationController from "views/Signup/SignupController/LocationController";
import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from "../PersonalData/PersonalDataFormMessages";

const DeliveryData: React.FC = () => {
  const { userData } = useContext(UserContext);
  const formState = useForm();
  const forms = [deliveryProfileForm];
  const [dynamicData, setDynamicData] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [deliveryMessage, setDeliveryMessage] = useState("" as React.ReactNode);
  const deliveryAddress = userData?.locationData?.find((obj: any) => {
    return obj.type === "CONSIGNEE";
  });

  const autoFillDeliveryDataForm = async () => {
    if (deliveryAddress) {
      deliveryProfileForm.fields[0].value = deliveryAddress.postalCode;
      deliveryProfileForm.fields[1].value = deliveryAddress.state;
      deliveryProfileForm.fields[2].value = deliveryAddress.city;
      deliveryProfileForm.fields[4].value = deliveryAddress.dir;
      deliveryProfileForm.fields[5].value = deliveryAddress.streetnumber;
      deliveryProfileForm.fields[6].value = deliveryAddress.internalnumber;
    }
  };

  const onSubmitDeliveryProfile = async (data: FieldValues) => {
    const updatedUser = {
      name: userData["name"]
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toUpperCase(),
      fatherLastName: userData["fatherLastName"]
        ? userData["fatherLastName"]
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .toUpperCase()
        : "",
      motherLastName: userData["motherLastName"]
        ? userData["motherLastName"]
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .toUpperCase()
        : "",
      rfc: userData["rfc"].toUpperCase(),
      curp: userData["curp"]?.toUpperCase(),
      phone: userData["phone"],
      email: userData["email"].toUpperCase(),
      additionalEmail: userData["additionalEmail"].toUpperCase(),
    };
    const fiscalAddress = userData?.locationData?.find((obj: any) => {
      return obj.type === "FISCAL";
    });
    const updatedDeliveryAddress =
      userData?.locationData?.find((obj: any) => {
        return obj.type === "CONSIGNEE";
      }) ?? ({} as any);
    const parseLatandLon = JSON.parse(data["googleMapLatLng"]);

    updatedDeliveryAddress.postalCode = data.deliveryAddressZipCode;
    updatedDeliveryAddress.state = data.deliveryAddressState.toUpperCase();
    updatedDeliveryAddress.city = data.deliveryAddressCity.toUpperCase();
    updatedDeliveryAddress.neighborhood =
      data.deliveryAddressNeighborhood ?? deliveryAddress?.neighborhood;
    updatedDeliveryAddress.dir = data.deliveryAddressStreet.toUpperCase();
    updatedDeliveryAddress.streetnumber = data.deliveryAddressExternalNumber;
    updatedDeliveryAddress.internalnumber = data.deliveryAddressInternalNumber;
    updatedDeliveryAddress.lat = parseLatandLon.lat;
    updatedDeliveryAddress.lon = parseLatandLon.lng;

    const updatedData = {
      user: updatedUser,
      locations: [fiscalAddress, updatedDeliveryAddress],
    };

    await patchUser(userData["id"], updatedData)
      .then((success) => {
        setLoading(false);
        setDeliveryMessage(UPDATE_USER_SUCCESS);
      })
      .catch((error) => {
        setLoading(false);
        setDeliveryMessage(UPDATE_USER_ERROR);
      });
  };

  const formDynamicData = useMemo(
    () => ({
      dynamicData,
      setDynamicData,
    }),
    // eslint-disable-next-line
    [dynamicData]
  );

  useEffect(() => {
    if (deliveryAddress && dynamicData?.deliveryAddress?.neighborhood) {
      const currentNeighborhood = dynamicData.deliveryAddress.neighborhood.find(
        (obj: any) => {
          return obj.label === deliveryAddress.neighborhood;
        }
      );
      formState.setValue(
        "deliveryAddressNeighborhoodId",
        currentNeighborhood?.value
      );
    }
  }, [dynamicData, deliveryAddress, formState]);

  autoFillDeliveryDataForm();

  return (
    <DynamicDataProvider value={formDynamicData}>
      <div className={styles.body}>
        <FormProvider {...formState}>
          <LocationController />
          <MultipleForms
            inputForms={forms}
            onSubmit={() => {
              setLoading(true);
              onSubmitDeliveryProfile(formState.getValues());
            }}
            isLoading={loading}
          />
        </FormProvider>
        {deliveryMessage}
      </div>
    </DynamicDataProvider>
  );
};

export default DeliveryData;

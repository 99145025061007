import { useAuth0 } from "@auth0/auth0-react";
import Button from "components/common/Button";
import Popup from "components/common/Popup";

interface UnauthorizedPopupProps {
  isUserAuthorized: boolean;
}
const UnauthorizedPopup: React.FC<UnauthorizedPopupProps> = ({
  isUserAuthorized,
}) => {
  const { logout } = useAuth0();
  const content = (
    <div>
      Tu cuenta está en proceso de autorización. El área de Beneficios te
      notificará cuando tu cuenta sea autorizada, este proceso puede tomar de 2
      a 3 días hábiles.
    </div>
  );

  const logOutButton = (
    <Button color="primary" curvature="high" action={() => logUserOut()}>
      Cerrar
    </Button>
  );

  const logUserOut = () => logout({ returnTo: window.location.origin });
  return (
    <Popup
      title={"¡Enhorabuena! Has completado tu registro"}
      state={"success"}
      content={content}
      button={logOutButton}
      open={!isUserAuthorized}
      maxWidth={"sm"}
      padding={false}
    />
  );
};

export default UnauthorizedPopup;

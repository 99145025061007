import styles from "./Divider.module.scss";

interface DividerProps {
  type?: "vertical-right" | "vertical-left" | "horizontal";
  color?: "black" | "grey";
  margin?: boolean;
  children?: React.ReactNode;
}

const Divider: React.FC<DividerProps> = ({
  color = "grey",
  margin = true,
  type = "horizontal",
  children,
}) => {
  const marginClass = margin ? "" : "no-margin";
  const verticalColor =
    type !== "horizontal" && color === "grey"
      ? "vertical-grey"
      : "vertical-black";

  if (type === "horizontal") {
    return <hr className={`${styles[color]} ${styles[marginClass]}`} />;
  } else {
    return (
      <div className={styles[type]}>
        <div className={styles[verticalColor]}>{children}</div>
      </div>
    );
  }
};

export default Divider;

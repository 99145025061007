import { useNavigate } from "react-router-dom";
import styles from "./IconStep.module.scss";

interface IconStepProps {
  step: { title: string; element: React.ReactNode };
  isSelected: boolean;
}

const IconStep: React.FC<IconStepProps> = ({ step, isSelected }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.step}>
      <div className={styles["step-container"]}>
        <button
          onClick={() => navigate(`../carrito/${step.title}`)}
          className={styles.button}
          disabled
        >
          <div className={isSelected ? styles.selected : ""}>
            {step.element}
          </div>
        </button>
      </div>
    </div>
  );
};
export default IconStep;

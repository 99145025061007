import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "components/common/Button";
import styles from "./ProductQuantity.module.scss";
import { useState } from "react";
interface ProductQuantityProps {
  value: number;
  onChange: (e: any) => void;
  disable: boolean;
  ruleMin: number;
  ruleMultiply: number;
  unit: string;
}
const ProductQuantity: React.FC<ProductQuantityProps> = ({
  value,
  onChange,
  disable,
  ruleMin,
  ruleMultiply,
  unit = "Unidad",
}) => {
  const [, setInputValue] = useState(value);
  const disableClass = disable ? "disable" : "";
  const updateValue = (newValue: number) => {
    if (newValue >= ruleMin) {
      setInputValue(newValue);
      onChange(newValue);
    }
  };
  return (
    <div className={styles.layout}>
      <Button
        color="secondary"
        emphasis="high"
        curvature="high"
        size="xsmall"
        action={() => updateValue(value - ruleMultiply)}
        disabled={disable || value <= ruleMin}
      >
        <RemoveIcon fontSize="medium" className={styles["action-button"]} />
      </Button>
      <div className={[styles["input-box"], styles[disableClass]].join(" ")}>
        {disable ? 0 : value}
        <div className={[styles.unit, styles[disableClass]].join(" ")}>
          <p>{unit}</p>
        </div>
      </div>
      <Button
        color="secondary"
        emphasis="high"
        curvature="high"
        size="xsmall"
        action={() => updateValue(value + ruleMultiply)}
        disabled={disable}
      >
        <AddIcon fontSize="medium" />
      </Button>
    </div>
  );
};
export default ProductQuantity;

import { UserAddress } from "./templates_file";

export const changeAddressRequest = (
  userEmail: string,
  address: UserAddress
) => {
  const message = `<html>
  <head>
  <style>
  ul.list {
    list-style-type: square;
  }
  </style>
  </head>
  <body>
  <p>¡Hola!</p>
  <p>Te notificamos que el usuario ${userEmail} ha solicitado un cambio de dirección de entrega.</p>
  <p>A continuación se muestran los detalles de la nueva dirección de entrega:</p>
  <p>CÓDIGO POSTAL: ${address.zipCode}</p>
  <p>ESTADO: ${address.state}</p>
  <p>MUNICIPIO: ${address.city}</p>
  <p>COLONIA: ${address.neighborhood}</p>
  <p>CALLE: ${address.street}</p>
  <p>NÚMERO EXTERIOR: ${address.internalNumber}</p>
  <p>NÚMERO INTERIOR: ${address.externalNumber}</p>
  <p>LATITUD: ${address.lat}</p>
  <p>LONGITUD: ${address.lon}</p>
  <p>Una vez que quede el cambio de dirección en sistema comercial, te recordamos que compartas con el equipo de Dlabs (morivera@deacero.com)  el nuevo ID CONSIGNADO del usuario para poder actualizar los datos en la plataforma. </p>
  <p>¡Gracias!</p>
  <p>DEACERO Market</p>
  </body></html>`;
  return message;
};

import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import env from "react-dotenv";
import { deliveryLocationSignupForm } from "forms/Payment/payment.changeAddress";
import { DynamicDataProvider } from "config/dynamicDataContext";
import { sendEmail } from "config/api/api";
import LocationController from "views/Signup/SignupController";
import useLocalStorage from "components/hooks/useLocalStorage";
import Popup from "components/common/Popup";
import Button from "components/common/Button";
import Banner from "components/common/Banner";
import { TEMPLATES } from "components/common/Notifier/templates/templates_file";
import RenderForm from "components/RenderForm";
import styles from "./ChangeAddressPopup.module.scss";

interface ChangeAddressPopupProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  action: () => void;
}

const ChangeAddressPopup: React.FC<ChangeAddressPopupProps> = ({
  open,
  setOpen,
  action,
}) => {
  const form = useForm();
  const userData = useLocalStorage("userData", {}).storedValue;
  const [dynamicData, setDynamicData] = useState({} as any);

  const formDynamicData = useMemo(
    () => ({
      dynamicData,
      setDynamicData,
    }),
    // eslint-disable-next-line
    [dynamicData]
  );

  const onSubmit = async (data: FieldValues) => {
    form.trigger().then((valid: boolean) => {
      if (valid) {
        const parseLatandLon = JSON.parse(data["googleMapLatLng"]);
        const userDeliveryAddress = {
          zipCode: parseInt(data["deliveryAddressZipCode"]),
          state: data["deliveryAddressState"].toUpperCase(),
          city: data["deliveryAddressCity"].toUpperCase(),
          neighborhood: data["deliveryAddressNeighborhood"],
          street: data["deliveryAddressStreet"].toUpperCase(),
          externalNumber: data["deliveryAddressExternalNumber"],
          internalNumber: data["deliveryAddressInternalNumber"],
          lat: parseLatandLon.lat,
          lon: parseLatandLon.lng,
        };

        const { from_email, to_email, subject, message } = {
          from_email: "ventaempleados@deacero.com",
          to_email:
            env.REACT_APP_VENTA_EMPLEADOS_EMAIL ?? "ventaempleados@deacero.com",
          subject: "[DEACERO MARKET] Solicitud de cambio de dirección",
          message: TEMPLATES.changeAddressRequest(
            userData.email,
            userDeliveryAddress
          ),
        };

        sendEmail(from_email, to_email, subject, message).then(() => {
          action();
          setOpen(false);
        });
      }
    });
  };

  const infoBanner = (
    <Banner
      size="large"
      type="info"
      iconSize="medium"
      closeIcon={false}
      message={
        <div className={styles["banner-message"]}>
          <div className={styles["banner-title"]}>Recuerda</div>
          <div className={styles["banner-description"]}>
            Al realizar el cambio de dirección, deberás esperar al menos 3 días
            hábiles para que se actualice en el sistema y puedas continuar con
            tu compra.
          </div>
        </div>
      }
    />
  );

  const popupContent = (
    <div>
      <DynamicDataProvider value={formDynamicData}>
        <FormProvider {...form}>
          <LocationController />
          <div>
            <p> Cambia a la dirección donde quieres recibir tus productos.</p>
            {infoBanner}
          </div>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className={styles["form-container"]}
          >
            <RenderForm inputForm={deliveryLocationSignupForm} />
          </form>
        </FormProvider>
      </DynamicDataProvider>
    </div>
  );

  return (
    <Popup
      title={"Dirección de entrega"}
      content={popupContent}
      button={
        <div className={styles["buttons-wrapper"]}>
          <Button
            emphasis="low"
            action={() => {
              setOpen(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            emphasis="high"
            curvature="high"
            action={() => onSubmit(form.getValues())}
          >
            Guardar datos
          </Button>
        </div>
      }
      open={open}
      maxWidth={"sm"}
      padding={false}
    />
  );
};

export default ChangeAddressPopup;

import Link from "components/common/Link";
import { getUsers } from "config/api/user.api";
import { Form } from "../form.types";

export const generalSignupForm: Form = {
  formTitle: "Información general",
  formDescription: "Comienza tu registro añadiendo tu información personal",
  fields: [
    {
      name: "name",
      label: "Nombre(s)",
      required: true,
      placeholder: "Ingresa tu(s) nombre(s)",
      type: "text",
      value: "",
    },
    {
      name: "fatherLastName",
      label: "Apellido paterno",
      required: true,
      placeholder: "Ingresa tu apellido paterno",
      type: "text",
      size: "half-width",
      value: "",
    },
    {
      name: "motherLastName",
      label: "Apellido materno",
      required: true,
      placeholder: "Ingresa tu apellido materno",
      type: "text",
      size: "half-width",
      value: "",
    },
    {
      name: "phone",
      label: "Teléfono",
      required: true,
      placeholder: "00 0000 0000",
      type: "tel",
      value: "",
      errorMessage: "Por favor ingresa tu teléfono a 10 dígitos.",
    },
    {
      name: "email",
      label: "Correo electrónico",
      required: true,
      placeholder: "mail@deacero.com",
      type: "email",
      value: "",
      validateDomain: "deacero.com",
      errorMessage: "Por favor ingresa tu correo de DEACERO.",
      validateItIsNewDataMessage: (
        <div>
          Esta dirección de correo electrónico ya está registrada.
          <Link
            href={"/"}
            text="Inicia
          sesión."
          />
        </div>
      ),
      apiCall: getUsers,
    },
    {
      name: "password",
      label: "Contraseña",
      required: true,
      placeholder: "Ejemplo1!",
      type: "password",
      value: "",
      errorMessage:
        "Tu contraseña debe de contener al menos 8 caracteres, una minúscula, una mayúscula, un número y un caracter especial.",
    },
    {
      name: "password-confirm",
      label: "Repite tu contraseña",
      required: true,
      placeholder: "Ejemplo1!",
      type: "password",
      value: "",
      errorMessage:
        "La contraseña debe de coincidir con la que ingresaste previamente.",
      needsValidationFrom: "password",
    },
  ],
};

import Button from "../Button/Button";

interface PaymentButtonProps {
  sendPayment: () => void;
  inProcess: boolean;
}

const PaymentButton = ({
  sendPayment,
  inProcess = false,
}: PaymentButtonProps) => {
  return (
    <Button
      color="primary"
      emphasis="high"
      curvature="high"
      action={sendPayment}
      loading={inProcess}
      disabled={inProcess}
    >
      Realiza tu pedido y paga
    </Button>
  );
};

export default PaymentButton;

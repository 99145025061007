import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import env from "react-dotenv";
import { useQuery } from "@tanstack/react-query";
import {
  Article,
  Characteristic,
  ProductDetail,
} from "config/api/inventory.api.types";
import { getProductDetail, getProductPrice } from "config/api/inventory.api";
import {
  publishShoppingCartItem,
  patchShoppingCartItem,
  getShoppingCartItems,
} from "config/api/shoppingCartItems.api";
import { NotificationDataContext } from "config/notificationDataContext";
import ShoppingCartContext from "config/shoppingCartContext";
import NewProductAddedNotification from "views/ProductDetailView/NewProductAddedNotification";
import useLocalStorage from "components/hooks/useLocalStorage";
import { CartProduct } from "components/common/common.types";
import Divider from "components/common/Divider";
import Carousel from "components/common/Carousel";
import Section from "components/common/Section";
import StatusView from "components/StatusView";
import ProductPrice from "./ProductPrice";
import ProductProperties from "./ProductProperties";
import SelectableProperties from "./SelectableProperties";
import LoadingProduct from "./LoadingProduct";
import MakeProductAvailable from "./MakeProductAvailable";
import styles from "./ProductDetail.module.scss";
import ProductDescription from "./ProductDescription";
import View from "components/common/View/View";

export type Property = {
  prop: string;
  value: string;
  id: number;
};

export type ArticleSummary = {
  sku: string;
  articleId: string;
  properties: any;
};

export const NO_AVAILABLE_FOR_EMPLOYEES =
  "Este producto no está disponible para venta a empleados.";
export const USER_HAS_NOT_SELECTED_ARTICLE = " ";

const ProductDetailView: React.FC = () => {
  const navigate = useNavigate();
  const productId = useParams()["productId"] ?? "-1";
  const largeScreen = useMediaQuery("(min-width: 1400px)");
  const mediumScreen = useMediaQuery("(max-width: 1400px)");
  const smallScreen = useMediaQuery("(max-width: 900px)");
  const [maxElementsToShow, setmaxElementsToShow] = useState(4);
  const [activeArticle, setactiveArticle] = useState({} as ArticleSummary);
  const [product, setproduct] = useState({} as ProductDetail);
  const [articles, setArticles] = useState([] as Article[]);
  const [defaultArticles, setDefaultArticles] = useState([] as Article[]);
  const [lastPropSelected, setlastPropSelected] = useState({} as Property);
  const [quantity, setquantity] = useState(1);
  const { setNotificationData } = useContext(NotificationDataContext);
  const [price, setPrice] = useState(0.0);
  const userData = useLocalStorage("userData", {}).storedValue;
  const { shoppingCartItems, setShoppingCartItems } =
    useContext(ShoppingCartContext);
  const [selectedProperties, setselectedProperties] = useState(
    [] as Property[]
  );
  const [productProperties, setProductProperties] = useState(
    [] as Characteristic[]
  );
  const [error, setError] = useState(false);

  const userId = Number(userData["id"]);
  const locationId = Number(userData["locationData"][0]["id"]);
  const userExists = userData.id !== undefined;
  const isAvailable = ![
    undefined,
    NO_AVAILABLE_FOR_EMPLOYEES,
    USER_HAS_NOT_SELECTED_ARTICLE,
  ].includes(activeArticle.articleId);

  const getArticleQuery = useQuery({
    queryKey: ["article", activeArticle.articleId],
    queryFn: async () => {
      const articleData = await getProductPrice(
        userId,
        locationId,
        `{"${activeArticle.articleId}":1}`
      ).catch(() => {
        setPrice(0.0);
        setactiveArticle({
          sku: activeArticle.sku,
          articleId: NO_AVAILABLE_FOR_EMPLOYEES,
          properties: activeArticle.properties,
        });
      });

      const articlePrice = articleData.total ?? 0.0;
      setPrice(articlePrice);
      setquantity(
        articleData["Deacero"]["skus"][activeArticle.articleId]["ruleMin"]
      );

      return articleData;
    },
    enabled: isAvailable && userExists,
  });

  useQuery({
    queryKey: ["product"],
    queryFn: async () => {
      const productData = await getProductDetail(parseInt(productId));
      if (productData.groupedProduct) {
        const productImage = productData.groupedProduct.images[0]?.url || "";
        const groupedProduct = productData.groupedProduct;
        const productDetail = {
          ...productData,
          groupedProduct: {
            ...groupedProduct,
            images: [{ url: productImage, image_id: 0 }],
          },
        };
        setArticles(productData.articles);
        setDefaultArticles(productData.articles);
        setproduct(productDetail);
        setProductProperties(productData.groupedProduct.characteristics);
      } else {
        setError(true);
      }
      return productData;
    },
  });

  useEffect(() => {
    setmaxElementsToShow(4);
    largeScreen && setmaxElementsToShow(4);
    mediumScreen && setmaxElementsToShow(3);
    smallScreen && setmaxElementsToShow(1);
  }, [largeScreen, smallScreen, mediumScreen]);

  useEffect(() => {
    let charExist = false;
    let filteredArticles = articles.filter((article) => {
      charExist =
        article.characteristics[lastPropSelected.prop] ===
        lastPropSelected.value;
      if (!charExist) {
        return false;
      }
      return charExist;
    });

    selectedProperties.forEach((sProp) => {
      filteredArticles = filteredArticles.filter((fa) => {
        return fa.characteristics[sProp?.prop] === sProp?.value;
      });
    });

    setArticles(filteredArticles);
    if (filteredArticles.length === 1) {
      const article = filteredArticles[0];
      setactiveArticle({
        sku: article.sku,
        articleId: article.articleId,
        properties: article.characteristics,
      });
    } else {
      setPrice(0.0);
    }
    // eslint-disable-next-line
  }, [selectedProperties]);

  const isPropertyAlreadyIncluded = (property: Property) => {
    const propertyIncluded = selectedProperties?.find(
      (sProp) => sProp && sProp.prop === property.prop
    );
    return propertyIncluded;
  };

  const selectProperty = (property: Property, reset: boolean = false) => {
    setlastPropSelected(property);
    let properties = [] as Property[];
    if (!reset) {
      properties = [...selectedProperties];
    }
    if (isPropertyAlreadyIncluded(property)) {
      setArticles(product.articles);
      setactiveArticle({
        sku: "",
        articleId: " ",
        properties: null,
      } as ArticleSummary);
      const indexOfProperty = selectedProperties
        .map((prop) => prop.prop)
        .indexOf(property.prop);
      properties[indexOfProperty] = property;
    } else {
      properties = [...properties, property];
    }
    setselectedProperties(properties);
  };

  const resetProperties = () => {
    setArticles(defaultArticles);
    setselectedProperties([]);
  };

  const navigateToCart = () => {
    navigate("/carrito/productos");
    setNotificationData(null);
  };

  const patchProduct = async (productToUpdate: CartProduct) => {
    await patchShoppingCartItem(productToUpdate.id, {
      sku: productToUpdate.sku,
      quantity: productToUpdate.quantity,
      userId: userData.id,
      articleId: Number(productToUpdate.articleId),
      title: productToUpdate.title,
      srcImage: productToUpdate.srcImage,
      characteristics: productToUpdate.characteristics,
    })
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const addProductToCart = async () => {
    let currentCartItems: CartProduct[];
    const activeProperties = Object.keys(activeArticle.properties).map(
      (prop) => {
        return {
          prop: prop,
          value: activeArticle.properties[prop],
          id: `${prop}:${activeArticle.properties[prop]}`,
        };
      }
    );
    const productAlreadyOnCart = shoppingCartItems.find((item) => {
      if (item.sku === activeArticle.sku) {
        return true;
      }
      return false;
    });
    if (product.groupedProduct.productId) {
      if (productAlreadyOnCart) {
        let updatedItem = {} as CartProduct;
        shoppingCartItems.map((item) => {
          if (item.sku === activeArticle.sku) {
            const itemFromCart = shoppingCartItems.splice(
              shoppingCartItems.indexOf(item),
              1
            )[0];
            const newQuantity = itemFromCart.quantity + quantity;
            updatedItem = { ...itemFromCart, quantity: newQuantity };
          }
          return item;
        });
        patchProduct(updatedItem);
        setShoppingCartItems([...shoppingCartItems, updatedItem]);
      } else {
        await publishShoppingCartItem({
          sku: activeArticle.sku,
          quantity: quantity,
          userId: userData.id,
          articleId: Number(activeArticle.articleId),
          title: product.groupedProduct.productName,
          srcImage: product.groupedProduct.images[0].url,
          characteristics: activeProperties,
        })
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
        await getShoppingCartItems(userData.id)
          .then((data) => {
            const lastItemInDB = data.slice(-1)[0];
            const currentItems = [
              ...shoppingCartItems,
              {
                id: Number(lastItemInDB.id),
                title: product.groupedProduct.productName,
                sku: activeArticle.sku,
                quantity: quantity,
                userId: userData.id,
                articleId: Number(activeArticle.articleId),
                price: price,
                srcImage: product.groupedProduct.images[0].url,
                characteristics: activeProperties,
              } as CartProduct,
            ];
            currentCartItems = currentItems;
            setShoppingCartItems(currentCartItems);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    setNotificationData(
      <NewProductAddedNotification navigateToCart={() => navigateToCart()} />
    );
    navigate("/");
  };

  const emailTo =
    env.REACT_APP_EMAIL_TO_MAKE_PRODUCT_AVAILABLE_FOR_EMPLOYEES ??
    "ventaempleados@deacero.com";

  const currentUrl = window.location.href;

  const renderProductProperties = () => (
    <Section>
      <div className={styles["details-container"]}>
        <div className={styles["images-container"]}>
          {product.groupedProduct && (
            <div className={styles["carousel-container"]}>
              <Carousel
                array={product.groupedProduct.images}
                type="images"
                maxElementsToShow={maxElementsToShow}
              />
            </div>
          )}
        </div>
        <div className={styles["properties-container"]}>
          <SelectableProperties
            product={product}
            productProperties={productProperties}
            onIsPropertyAlreadyIncluded={isPropertyAlreadyIncluded}
            onSelectProperty={selectProperty}
            onResetProperties={resetProperties}
            selectedProperties={selectedProperties}
            productArticles={articles}
          />
        </div>
        <div className={styles["summary-container"]}>
          <div className={styles["price-container"]}>
            <ProductPrice
              ruleMin={
                getArticleQuery.isSuccess
                  ? getArticleQuery.data["Deacero"]["skus"][
                      activeArticle.articleId
                    ]["ruleMin"]
                  : 1
              }
              ruleMultiply={
                getArticleQuery.isSuccess
                  ? getArticleQuery.data["Deacero"]["skus"][
                      activeArticle.articleId
                    ]["ruleMultiply"]
                  : 1
              }
              price={price}
              quantity={quantity}
              product={product}
              loadingPrice={getArticleQuery.fetchStatus === "fetching"}
              onChangeQuantity={setquantity}
              onClickAdd={addProductToCart}
            />
          </div>
          <div className={styles["props-container"]}>
            <ProductProperties
              loadingPrice={getArticleQuery.fetchStatus === "fetching"}
              price={price}
              activeArticle={activeArticle}
            />
          </div>
          {activeArticle.articleId === NO_AVAILABLE_FOR_EMPLOYEES && (
            <MakeProductAvailable
              emailTo={emailTo}
              userEmail={userData.email}
              sku={activeArticle.sku}
              properties={activeArticle.properties}
              currentUrl={currentUrl}
            />
          )}
        </div>
      </div>
    </Section>
  );

  const renderProductDescription = () => (
    <Section>
      <ProductDescription description={product.groupedProduct.description} />
    </Section>
  );

  const renderProductDetail = () => {
    return (
      <div>
        {renderProductProperties()}
        <Divider color="grey" />
        {renderProductDescription()}
      </div>
    );
  };

  const renderLoadingDetails = () => (
    <LoadingProduct maxElementsToShow={maxElementsToShow} />
  );

  let view;
  if (product.groupedProduct && !error) {
    view = renderProductDetail();
  } else if (error) {
    view = (
      <StatusView
        iconRoute="/not-working.svg"
        title="¡Ups, este producto no existe! parece que hay un error en tu búsqueda"
        statusContext={<div></div>}
        iconAlt="service not working warning"
        actionTitle="Entendido"
        action={() => {
          navigate("/");
        }}
      />
    );
  } else {
    view = renderLoadingDetails();
  }

  return <View>{view}</View>;
};

export default ProductDetailView;

import env from "react-dotenv";

export const SHOPPING_CART_ITEMS_API = {
  publishShoppingCartItem: `${env.REACT_APP_BACK_HOST}/cart-items`,
  getShoppingCartItems: (userId: number) =>
    `${env.REACT_APP_BACK_HOST}/users/${userId}/cart-items`,
  patchShoppingCartItem: (itemId: number) =>
    `${env.REACT_APP_BACK_HOST}/cart-items/${itemId}`,
  deleteShoppingCartItem: (itemId: number) =>
    `${env.REACT_APP_BACK_HOST}/cart-items/${itemId}`,
};

export type CartItem = {
  quantity: number;
  sku: string;
  userId: number;
  articleId: number;
  title: string;
  srcImage: string;
  characteristics: { prop: string; value: string; id: string }[];
};

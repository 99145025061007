import React from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Product, SearchResult } from "config/api/inventory.api.types";
import { searchProducts } from "config/api/inventory.api";
import View from "components/common/View";
import ImageCard from "components/common/ImageCard";
import LoadingCard from "components/common/LoadingCard";
import Pagination from "./Pagination/Pagination";
import GridContainer from "components/common/GridContainer";
import Section from "components/common/Section";
import ProductsNotFound from "./ProductsNotFound";
import styles from "./Search.module.scss";

const Search: React.FunctionComponent = () => {
  const { query } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  let productsCards;
  const [totalResults, setTotalResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [cardsPerRow, setCardsPerRow] = useState(3);
  const largeScreen = useMediaQuery("(min-width: 1360px)");

  useEffect(() => {
    if (largeScreen) {
      setCardsPerRow(4);
    } else {
      setCardsPerRow(3);
    }
  }, [largeScreen]);

  useEffect(() => {
    setProducts([]);
    setLoading(true);
    query &&
      searchProducts(query, currentPage).then((response) => {
        const result: SearchResult = response.data;
        setProducts(result.data);
        setTotalResults(Number(result.totalResults));
        setLoading(false);
      });
  }, [query, currentPage]);

  if (loading) {
    productsCards = <LoadingCard cards={12} variant="medium" />;
    return (
      <View>
        <Section>
          <div className={styles.container}>
            <div className={styles.info}>
              <div className={styles.query}>Cargando productos...</div>
            </div>
            <GridContainer
              perRow={cardsPerRow}
              children={productsCards}
            ></GridContainer>
          </div>
        </Section>
      </View>
    );
  }

  if (products.length > 0) {
    productsCards = products.map((product) => {
      const productImage = (product.images && product.images[0]?.url) ?? "";
      return (
        <div
          className={styles.pointer}
          onClick={() => navigate(`../producto/${product.productId}`)}
          key={product.productId}
        >
          <ImageCard
            key={product.productId}
            title={product.productName}
            imgSrc={productImage}
            size="medium"
            loading={false}
          />
        </div>
      );
    });

    return (
      <View>
        <Section>
          <div className={styles.container}>
            <div className={styles.info}>
              <div className={styles.query}>“{query}”</div>
              <div className={styles["results-number"]}>
                {totalResults} resultados
              </div>
            </div>
            <GridContainer
              perRow={cardsPerRow}
              children={productsCards}
            ></GridContainer>
            <Pagination
              currentPage={currentPage}
              totalCount={totalResults}
              onPageChange={setCurrentPage}
            />
          </div>
        </Section>
      </View>
    );
  }

  return <ProductsNotFound />;
};

export default Search;

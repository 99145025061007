import { Dialog, DialogContent } from "@mui/material";
import StateIcon from "components/common/StateIcon";
import styles from "./Popup.module.scss";

interface PopupProps {
  title?: string;
  state?: "success" | "error" | "info-error" | "refresh";
  content: React.ReactNode;
  button?: React.ReactNode;
  open: boolean;
  onClose?: () => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  padding?: boolean;
}

const Popup: React.FC<PopupProps> = ({
  open,
  onClose = () => {},
  content,
  maxWidth = "sm",
  padding = true,
  state,
  button,
  title,
}) => {
  const containerStyle = padding ? "container" : "container-no-padding";
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth={true}
      maxWidth={maxWidth}
      PaperProps={{
        style: { borderRadius: 16 },
      }}
    >
      <DialogContent>
        <div className={styles[containerStyle]}>
          <div className={styles.popup}>
            <div className={styles["popup-centered"]}>
              {state && <StateIcon state={state} />}
              <div className={styles["popup-text"]}>
                <div className={styles["popup-title"]}>{title}</div>
                {content}
              </div>
            </div>
            <div className={styles["popup-button"]}>{button}</div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;

import env from "react-dotenv";
import { personalProfileForm } from "forms/Profile/Profile.personal";
import { changePasswordProfileForm } from "forms/Profile/Profile.changepassword";
import styles from "./PersonalData.module.scss";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import { patchUser } from "config/api/user.api";
import { getToken, changePassword } from "config/api/profile.api";
import { useContext, useState } from "react";
import UserContext from "config/userContext";
import MultipleForms from "components/Form/MultipleForms";
import {
  WRONG_PASSWORD_DESCRIPTION,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_WRONG,
  CHANGE_PASSWORD_ERROR,
  ACCOUNT_ERROR_MESSAGE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from "./PersonalDataFormMessages";

const PersonalData: React.FC = () => {
  const { user } = useAuth0();
  const { userData, setUserData } = useContext(UserContext);
  const formState = useForm();
  const [loadingPersonal, setLoadingPersonal] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("" as React.ReactNode);
  const [profileMessage, setProfileMessage] = useState("" as React.ReactNode);

  const autoFillPersonalProfileForm = () => {
    personalProfileForm.fields[0].value = userData?.name;
    personalProfileForm.fields[1].value = userData?.fatherLastName;
    personalProfileForm.fields[2].value = userData?.motherLastName;
    personalProfileForm.fields[3].value = userData?.email;
    personalProfileForm.fields[4].value = userData?.additionalEmail;
    personalProfileForm.fields[5].value = userData?.phone;
  };

  const onSubmitPasswordChange = async (data: FieldValues) => {
    if (user?.email && user?.sub) {
      const getTokenParams = {
        grant_type: "password",
        username: user.email,
        password: data.actualPassword,
        audience: `https://${env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
        scope: "openid",
        client_id: `${env.REACT_APP_AUTH0_CLIENT_ID}`,
        client_secret: `${env.REACT_APP_AUTH0_CLIENT_SECRET}`,
      };

      await getToken(getTokenParams)
        .then(async () => {
          if (user?.sub) {
            const changePasswordToken = user.sub;
            const changePasswordHeaders = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${env.REACT_APP_AUTH0_MANAGEMENT_API_TOKEN}`,
            };
            const changePasswordParams = {
              password: data.newPassword,
              connection: "Username-Password-Authentication",
            };
            await changePassword(
              changePasswordParams,
              changePasswordToken,
              changePasswordHeaders
            )
              .then(() => {
                setLoadingPassword(false);
                setPasswordMessage(CHANGE_PASSWORD_SUCCESS);
              })
              .catch(async () => {
                setLoadingPassword(false);
                setPasswordMessage(CHANGE_PASSWORD_ERROR);
              });
          } else {
            setLoadingPassword(false);
            setPasswordMessage(ACCOUNT_ERROR_MESSAGE);
          }
        })
        .catch(async (error) => {
          if (
            error?.response?.data?.error_description ===
            WRONG_PASSWORD_DESCRIPTION
          ) {
            setLoadingPassword(false);
            setPasswordMessage(CHANGE_PASSWORD_WRONG);
          } else {
            setLoadingPassword(false);
            setPasswordMessage(CHANGE_PASSWORD_ERROR);
          }
        });
    } else {
      setLoadingPassword(false);
      setPasswordMessage(ACCOUNT_ERROR_MESSAGE);
    }
  };

  const onSubmitPersonalProfile = async (data: FieldValues) => {
    const updatedUser = {
      name: userData["name"]
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toUpperCase(),
      fatherLastName: userData["fatherLastName"]
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toUpperCase(),
      motherLastName: userData["motherLastName"]
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toUpperCase(),
      rfc: userData["rfc"].toUpperCase(),
      curp: userData["curp"]?.toUpperCase(),
      phone: data.phone,
      email: userData["email"].toUpperCase(),
      additionalEmail: data.taxEmail.toUpperCase(),
    };
    const updatedData = {
      user: updatedUser,
      locations: userData["locationData"],
    };
    await patchUser(userData["id"], updatedData)
      .then((success) => {
        setLoadingPersonal(false);
        setProfileMessage(UPDATE_USER_SUCCESS);
        const updateContextUser = {
          ...userData,
          phone: data.phone,
        };
        setUserData(updateContextUser);
      })
      .catch((error) => {
        setLoadingPersonal(false);
        setProfileMessage(UPDATE_USER_ERROR);
      });
  };

  autoFillPersonalProfileForm();

  return (
    <div className={styles.body}>
      <FormProvider {...formState}>
        <MultipleForms
          inputForms={[personalProfileForm]}
          onSubmit={() => {
            setLoadingPersonal(true);
            onSubmitPersonalProfile(formState.getValues());
          }}
          isLoading={loadingPersonal}
        />
      </FormProvider>
      {profileMessage}
      <div className={styles.divider}></div>
      <FormProvider {...formState}>
        <MultipleForms
          inputForms={[changePasswordProfileForm]}
          onSubmit={() => {
            setLoadingPassword(true);
            onSubmitPasswordChange(formState.getValues());
          }}
          isLoading={loadingPassword}
        />
      </FormProvider>
      {passwordMessage}
    </div>
  );
};

export default PersonalData;

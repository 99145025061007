import Step from "./Step/Step";
import styles from "./Stepper.module.scss";
import IconStep from "./IconStep";

interface StepperProps {
  title?: string;
  steps: { title: string; element: React.ReactNode }[];
  selectedStep: string;
  orientation?: "vertical" | "horizontal";
  variant?: "check-stepper" | "icon-stepper";
}

const Stepper: React.FC<StepperProps> = ({
  steps,
  title,
  variant = "check-stepper",
  orientation = "vertical",
  selectedStep,
}) => {
  const totalSteps = steps.length;
  const stepPlace = steps.map((e) => e.title).indexOf(selectedStep);
  const percentFilled = (stepPlace / (totalSteps - 1)) * 100;
  const primaryColor = "rgba(249,117,19,1)";
  const greyColor = "rgba(242,242,242,1)";

  const progressLine =
    variant === "icon-stepper"
      ? `linear-gradient(90deg, ${primaryColor} ${percentFilled}%, ${greyColor} ${percentFilled}%)`
      : "";
  return (
    <div className={styles[`${orientation}-stepper-wrapper`]}>
      <div className={styles.stepper}>
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles["stepper-selector"]}>
          <div
            style={{
              background: progressLine,
            }}
            className={styles[`${orientation}-line`]}
          />
          {steps.map((step, index) => {
            return variant === "check-stepper" ? (
              <Step
                key={step.title}
                step={step.element}
                isSelected={step.title === selectedStep}
              />
            ) : (
              <IconStep
                key={step.title}
                step={step}
                isSelected={stepPlace >= index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Stepper;

const data = [
  {
    title: " ¿Cuáles son las formas de pago para venta de material a empleado?",
    // eslint-disable-next-line
    content: "Por el momento únicamente pago con tarjeta crédito/debito",
    isOpen: true,
  },
  {
    title: "¿Dónde se entrega el material?",
    // eslint-disable-next-line
    content:
      "Al hacer tu solicitud puedes elegir en sistema el envío a un domicilio particular, la dirección que tomará el sistema es la que diste de alta al momento de registrarte en venta a empleado. En caso de que quieras realizar un cambio en la dirección de entrega por favor enviar un correo a beneficios@deacero.com",
  },
  {
    title: "¿Cómo puedo cambiar la dirección de entrega?",
    // eslint-disable-next-line
    content:
      "Enviar un correo a beneficios@deacero.com con la información de la dirección (calle y número, colonia, municipio, estado, laitud y longitud y telefono y correo de la persona quien recibirá el material)",
  },
  {
    title: "¿Es posible solicitar material para un familiar o amigo?",
    // eslint-disable-next-line
    content:
      "Por Política solo es posible para colaboradores y familia directa (padres, cónyuge o hijos).",
  },
  {
    title: "¿Cuál es el descuento como empleado en venta de material?",
    // eslint-disable-next-line
    content:
      "Aproximadamente el 20% sobre el precio más bajo dado a distribuidores mayoristas en facturas de los últimos 30 días.",
  },
  {
    title: "¿Cómo doy seguimiento a mi pedido?",
    // eslint-disable-next-line
    content:
      "Envía un correo a Fidel Lara Rodríguez flara@deacero.com con copia a beneficos@deacero.com y comparte la información del PDF/foto de la confirmación de tu solicitud:\nAsunto: Seguimiento venta de material\nCuerpo de correo: Número de fabricación, tipo de entrega (envío a planta o domicilio) y nombre de la persona que ingresó la solicitud en el portal.\n\nEstamos trabajando para que puedas visualizar el seguimiento de tu pedido, ¡Esperalo pronto!",
  },
  {
    title: "¿Se puede facturar a nombre de un tercero?",
    // eslint-disable-next-line
    content:
      "Solo se puede facturar a nombre de la persona que ingreso el pedido en el portal, ya que la facturación esta ligado con el usuario.",
  },
  {
    title: "¿Puedo pagar con tarjeta a nombre de alguien más?",
    // eslint-disable-next-line
    content:
      "De preferencia la tarjeta debe de ser a nombre del colaborador que esta solicitando el material en el portal.",
  },
  {
    title: "¿Puedo pagar a meses sin intereses?",
    // eslint-disable-next-line
    content:
      "Por el momento no es posible, se maneja un único cobro a la tarjeta que utilices como método de pago (crédito/debito)",
  },
  {
    title: "¿Hay envío internacional?",
    // eslint-disable-next-line
    content: "Solo esta permitido envíos dentro de la Republica Méxicana",
  },
  {
    title: "¿Hay un limite de material que puedo pedir?",
    // eslint-disable-next-line
    content:
      "No, siempre y cuando el material sea para uso del colaboradora o familia directa (conyúge, padres e hijos). En caso de solicitar grandes cantidades o cierto número de pedidos es posible que Beneficios se acerque contigo para confirmar el uso.",
  },
  {
    title: "¿Necesito algun tiempo de antigüedad para hacer una compra?",
    // eslint-disable-next-line
    content:
      "No es necesario, puedes solicitar material siempre y cuando estes activo(a) en la nómina de DEACERO.",
  },
  {
    title: "¿Cómo puedo revisar el catálogo de productos disponibles?",
    // eslint-disable-next-line
    content:
      "Hay dos formas de buscar un producto:\n1. Puedes buscar directamente el producto deseado en el buscador.\n2. Puedes revisar el catálogo de productos dando click en el logo de DEACERO que se encuentra a un lago del buscador para que te lleve al Inicio/Home del portal.",
  },
  {
    title: "¿Hay envios a otro Estado aunque no viva en ese estado?",
    // eslint-disable-next-line
    content:
      "Si es posible solicitar envios a un estado diferente al que resides siempre y cuando la propiedad sea del colaborador que esta slicitando el material o familia directa (padres, conyugue e hijos)",
  },
  {
    title: "¿Cómo cancelar mi pedido?",
    // eslint-disable-next-line
    content:
      "Envía un correo a beneficios@deacero.com compartiendo tunombre completo y n° defabricacion o de pedido.",
  },
  {
    title: "¿Puedo cambiar productos de mi pedido?",
    // eslint-disable-next-line
    content:
      "De acuerdo al pedido/producto y el estatus el área de Beneficios evaluará si es posible, tendrás que enviar un correo a beneficios@deacero.com:\nAsunto: Modificación de productos venta a empleado\nCuerpo de correo: Número de fabricación/pedido, nombre completo del colaborador que ingresó el pedido y productos que quieres modificar.",
  },
  {
    title:
      "¿Con quien puedo consultar la fecha y hora para recoger mi pedido en planta/patio/cedis?",
    // eslint-disable-next-line
    content:
      "Para consultar o solicitar una cita para recoger mi pedido en planta por favor contactar a beneficios@deacero.com compartiendo tu nombre completo y n° de fabricación o pedido.",
  },
  {
    title: "¿Cuál es el minimo que puedo comprar?",
    // eslint-disable-next-line
    content:
      "Hay dos limitantes para la compra:\nPrecio: La cantidad minima para poder solicitar material es de $4,000 pesos\nCantidad minima de producto: El sistema esta configurado con la cantidad minima de cada producto.",
  },
  {
    title: "¿Puedo comprar material de segunda?",
    // eslint-disable-next-line
    content:
      "En venta de material a empleados solo estan permitidos los productos en listas de primera",
  },
  {
    title: "¿Puedo comprar material entre varios compañeros?",
    // eslint-disable-next-line
    content:
      "Si es posible, sin embargo solo se puede entregar en un solo domicilio y se facturará a la persona que ingresó el pedido desde su usuario.",
  },
  {
    title: "¿A nombre de quien se factura la compra?",
    // eslint-disable-next-line
    content:
      "Solo se puede factrar a la persona que ingresó la fabricación en el portal.",
  },
  {
    title: "¿Puede un ex-empleado o jubilado comprar material?",
    // eslint-disable-next-line
    content:
      "No es posible, ya que no tendrían manera de darse de alta como cliente en el portal.",
  },
  {
    title: "¿Puedo comprar material de DEACERO USA?",
    // eslint-disable-next-line
    content:
      "No es posible, por el momento este beneficio solo es de productos de DEACERO SAPI y algunas filiales dentro del territorio nacional.",
  },
  {
    title: "¿Cómo consulto mi historial de pedidos?",
    // eslint-disable-next-line
    content:
      "Por el momento no es posible directo de la plataforma, envía un correo a beneficios@deacero.com solicitando la información.\n\nEstamos trabajando para que puedas visualizar el historial de tus pedidos, ¡Esperalo pronto!",
  },
  {
    title: "¿Qué tan seguido puedo ingresar un pedido?",
    // eslint-disable-next-line
    content:
      "Puedes solicitar materiales las veces que necesites, siempre y cuando estes activo(a) en la nómina de DEACERO.",
  },
  {
    title: "¿Puede alguien más recibir mi pedido en el domicilio de entrega?",
    // eslint-disable-next-line
    content:
      "Si es posible, sin embargo, el chofer contactará al número que se dio de alta como contacto en la dirección.",
  },
  {
    title:
      "¿Hacen descuento adicionales arriba de cierta cantidad de material o precio?",
    // eslint-disable-next-line
    content:
      "No, debido a que el descuento de los productos mostrados en el portal ya son muy preferenciales a comparación del precio que se da a los clientes externos de DEACERO, ya no existe algun descuento adicional.",
  },
  {
    title:
      "¿Cómo puedo solicitar un reembolso de la diferencia de importe facturado contra el total pagado?",
    // eslint-disable-next-line
    content:
      "El área de Beneficios revisará si existe algún saldo a favor a devolver, tendrás que enviar un correo a beneficios@deacero.com:\nAsunto: Reembolso de saldo a favor\nCuerpo de correo: Número de fabricación/pedido, nombre completo del colaborador que ingresó el pedido y factura del material recibida.\n\nNOTA: El reembolso se tendrá que realizar a una cuenta a nombre de la persona a quien se facturó el pedido.",
  },
  {
    title:
      "¿El personal sindicalizado puede solicitar material con beneficio de venta a empleado?",
    // eslint-disable-next-line
    content:
      "Si es posible, sin emabrgo por el momento deberá ser por medio del RH de la ubicación en donde labore, para confirmar el RH envia un correo a beneficios@deacero.com.",
  },
  {
    title: "¿Puede alguien mas recoger mi pedido en planta?",
    // eslint-disable-next-line
    content:
      "Si es posible, sin embargo es necesaria una carta de recoleccion firmada por el titular del pedido, favor de contactar a beneficios@deacero.com para confirmar la información.",
  },
  {
    title: "¿Cómo puedo solicitar una devolución del material que recibí?",
    // eslint-disable-next-line
    content:
      "Por favor envía un correo a beneficios@deacero.com con tu nombre completo y n° de fabricación o pedido que deseas solicitar devolución para apoyarte con el proceso y/o requisitos.",
  },
  {
    title:
      "¿Cómo puedo consultar si es posible comprar material que no aparece en esta página?",
    // eslint-disable-next-line
    content:
      "Envía un correo a beneficios@deacero.com compartiendo tu nombre completo y la clave del producto que quieres consultar.",
  },
];

export default data;

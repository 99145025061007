import Spinner from "../Spinner";
import { COLORS } from "styles/colors";
import { Button as DButton } from "design-system-dlabs";

type spinnerColors = "white" | "black" | "blue";
type color =
  | "primary"
  | "secondary"
  | "success"
  | "disabled"
  | "white"
  | "grey";
type emphasis = "high" | "medium" | "low";
type size = "large" | "medium" | "small" | "xsmall";

const colors = {
  primary: COLORS.primary.color,
  secondary: COLORS.secondary.color,
  success: COLORS.success.color,
  disabled: COLORS.disabled,
  white: COLORS.white,
  grey: COLORS.default,
};

interface ButtonProps {
  children?: React.ReactNode;
  color?: color;
  contentColor?: color;
  emphasis?: emphasis;
  size?: size;
  curvature?: emphasis;
  disabled?: boolean;
  loading?: boolean;
  loadingColor?: spinnerColors;
  action: (data?: any) => void;
  border?: boolean;
  forceColor?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  size,
  color = "secondary",
  contentColor = "white",
  emphasis = "medium",
  disabled = false,
  curvature = "high",
  action,
  loading = false,
  loadingColor = "white",
}) => {
  const backgroundColor = colors[color];
  return (
    <DButton
      emphasis={emphasis}
      mainColor={backgroundColor}
      contentColor={contentColor}
      disabled={disabled}
      curvature={curvature}
      onClick={action}
      size={size}
    >
      {loading ? <Spinner color={loadingColor} size="small" /> : children}
    </DButton>
  );
};

export default Button;

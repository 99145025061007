import { getLocation } from "config/api/api";
import { DynamicDataContext } from "config/dynamicDataContext";
import { Form } from "../form.types";
export const deliveryLocationSignupForm: Form = {
  formTitle: "",
  formDescription: "",
  fields: [
    {
      name: "deliveryAddressZipCode",
      label: "Código postal",
      required: true,
      placeholder: "000000",
      type: "zipcode",
      errorMessage:
        "¡Lo sentimos! No hay opciones de envío a esta dirección. Por favor, introduzca un código postal diferente.",
      apiCall: getLocation,
    },
    {
      name: "deliveryAddressState",
      label: "Estado",
      required: true,
      placeholder: "Estado",
      type: "text",
      disabled: true,
    },
    {
      name: "deliveryAddressCity",
      label: "Municipio",
      required: true,
      placeholder: "Municipio",
      type: "text",
      disabled: true,
    },
    {
      name: "deliveryAddressNeighborhoodId",
      value: "",
      label: "Colonia",
      required: true,
      placeholder: "Colonia",
      type: "dropdown",
      dynamicOptionsProp: "deliveryAddress",
      optionsContext: DynamicDataContext,
    },
    {
      name: "deliveryAddressStreet",
      label: "Calle",
      required: true,
      placeholder: "Calle",
      type: "text",
    },
    {
      name: "deliveryAddressExternalNumber",
      label: "Número exterior",
      required: true,
      placeholder: "25-A",
      type: "text",
      size: "half-width",
    },
    {
      name: "deliveryAddressInternalNumber",
      label: "Número interior",
      required: false,
      placeholder: "25-A",
      type: "text",
      size: "half-width",
    },
    {
      name: "googleMapLatLng",
      label: "Ubica la dirección de entrega con un pin en el mapa",
      required: false,
      type: "map",
      errorMessage: "Aún no has ubicado la dirección de entrega en el mapa.",
      fieldCaption:
        "Arrastra el cursor en el mapa hasta llegar a tu ubicación y haz clic sobre ella para seleccionarla.",
    },
  ],
};

import { addDotAtTheEndOf, removeAccents } from "config/utils";
import styles from "./ProductDescription.module.scss";

interface DescriptionProps {
  description: string;
}

const ProductDescription: React.FC<DescriptionProps> = ({ description }) => {
  let descriptionJson = "";
  try {
    descriptionJson = JSON.parse(description);
  } catch (e) {
    return <></>;
  }
  const descriptionSectionsTitles = Object.keys(descriptionJson);

  const transformValueToHtml = (value: any) => {
    if (typeof value === "object") {
      return (
        <ul className={styles["description-list"]}>
          {value.map((item: string) => {
            return (
              <li key={item}>
                <p className={styles["list-item"]}>{addDotAtTheEndOf(item)}</p>
              </li>
            );
          })}
        </ul>
      );
    }
    return <p className={styles.text}>{addDotAtTheEndOf(value)}</p>;
  };

  const descriptionSections = descriptionSectionsTitles.map((title: any) => {
    const sectionKey =
      removeAccents(title).toLowerCase() === "descripcion" ? "" : title;
    return (
      <div className={styles["description-section"]} key={sectionKey}>
        <p className={styles["section-title"]}>{sectionKey}</p>
        <div className={styles["section-content"]}>
          {transformValueToHtml(descriptionJson[title])}
        </div>
      </div>
    );
  });

  return (
    <div className={styles["description-container"]}>
      <p className={styles["section-title"]}>Descripción del producto</p>
      <div className={styles.description}>{descriptionSections}</div>
    </div>
  );
};

export default ProductDescription;

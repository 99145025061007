import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import env from "react-dotenv";
import SelectedOfferContext from "config/selectedOfferContext";
import ShoppingCartContext from "config/shoppingCartContext";
import { API } from "config/api/api.types";
import { postRequest } from "config/api/api";
import { parseExpiryDate } from "views/OpenPayLab/utils/parse-expiry-date.utils";
import useLocalStorage from "components/hooks/useLocalStorage";
import useScript from "components/hooks/useScript";
import Card from "components/common/Card";
import PayButton from "components/common/PaymentButton";
import Divider from "components/common/Divider";
import styles from "./PaymentPay.module.scss";
import { getPriceFormat } from "config/utils";

interface PaymentPayProps {
  paymentData: any;
  reasonData: any;
  setPaymentResultOpen: Dispatch<SetStateAction<boolean>>;
  setRedirectPopupOpen: Dispatch<SetStateAction<boolean>>;
}

const OPEN_PAY_MERCHANT_ID = env.REACT_APP_OP_MERCHANT_ID;
const OPEN_PAY_API_KEY = env.REACT_APP_OP_API_KEY;
const OPEN_PAY_SANDBOX_MODE = env.REACT_APP_OP_SANDBOX_MODE !== "false";

const PaymentPay: React.FC<PaymentPayProps> = ({
  paymentData,
  reasonData,
  setPaymentResultOpen,
  setRedirectPopupOpen,
}) => {
  const { selectedOffer, selectedOfferIndex, offerId } =
    useContext(SelectedOfferContext);

  const { shoppingCartItems } = useContext(ShoppingCartContext);
  const userData = useLocalStorage("userData", {}).storedValue;
  const setOrderCreatedStatus = useLocalStorage("orderStatus", {}).setValue;
  const setOpenPayErrorCode = useLocalStorage("openPayErrorCode", {}).setValue;
  const [processingPayment, setProcessingPayment] = useState(false);
  const [productsQuantity, setProductsQuantity] = useState(0);
  const [openPaySessionId, setOpenPaySessionId] = useState();
  const openPayScriptStatus = useScript(
    `https://js.openpay.mx/openpay.v1.min.js`
  );

  const firstOpenPayResourcesStatus = useScript(
    `https://js.openpay.mx/openpay-data.v1.min.js`
  );
  const secondOpenPayResourcesStatus = useScript(
    `https://resources.openpay.mx/lib/openpay-data-js/1.2.38/openpay-data.v1.min.js`
  );
  const [scriptsReady, setScriptsReady] = useState(false);

  useEffect(() => {
    let sumShipments = 0;
    selectedOffer.shipments.forEach((shipment) => {
      shipment.articles.forEach((article) => {
        sumShipments += article.quantity;
      });
    });
    setProductsQuantity(sumShipments);
  }, [selectedOffer.shipments]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const openPayReady =
      openPayScriptStatus === "ready" &&
      firstOpenPayResourcesStatus === "ready" &&
      secondOpenPayResourcesStatus === "ready";
    if (!openPaySessionId && openPayReady) {
      // @ts-ignore
      OpenPay.setId(OPEN_PAY_MERCHANT_ID);
      // @ts-ignore
      OpenPay.setApiKey(OPEN_PAY_API_KEY);
      // @ts-ignore
      OpenPay.setSandboxMode(OPEN_PAY_SANDBOX_MODE);
      // @ts-ignore
      const openPay = OpenPay;
      setScriptsReady(true);
      if (openPay.deviceData) {
        const deviceSessionId = openPay.deviceData.setup(
          "payment-form",
          "deviceIdHiddenFieldName"
        );
        setOpenPaySessionId(deviceSessionId);
      } else {
        setScriptsReady(false);
      }
    }
  }, [
    openPayScriptStatus,
    firstOpenPayResourcesStatus,
    secondOpenPayResourcesStatus,
    openPaySessionId,
    scriptsReady,
  ]);

  const _3dSecurePaymentCallBack = (card: any) => {
    let _3dsCard = {
      ...card,
      use_3d_secure: "true",
    };
    paymentCallback(_3dsCard);
  };

  const paymentCallback = (card: any) => {
    const skus = shoppingCartItems.reduce((deaceroSKUObject: any, cartItem) => {
      deaceroSKUObject[cartItem.sku] = cartItem.quantity;
      return deaceroSKUObject;
    }, {});

    const location = userData.locationData[0];
    if (location.referenceId < 0) {
      setProcessingPayment(false);
      setPaymentResultOpen(true);
      console.error("referenceId is invalid");
      return;
    }
    const offerChosen = {
      idOfferGroup: offerId,
      selectedOfferIndex: selectedOfferIndex,
      selectedOfferType: selectedOffer.type,
    };

    const paymentData = {
      userId: userData.id,
      locationId: location.id,
      reason: reasonData.getValues().reason,
      offerChosen: offerChosen,
      roobynOffers: [selectedOffer],
      prices: {
        DEACERO: {
          skus,
          subtotal: 0,
          tax: 0,
          total: selectedOffer.total,
        },
      },
      openPayPayment: {
        redirect_url: window.location.href,
        source_id: card.data.id,
        device_session_id: openPaySessionId,
      },
    };

    const paymentUrl = API.openPayfillOrder();
    postRequest(paymentUrl, paymentData)
      .then((response) => {
        const paymentResponse = response.data;
        const cratedCharge =
          paymentResponse.pendingOrderDone.chargeResult?.createdCharge;

        if (cratedCharge?.error_message === null) {
          setOrderCreatedStatus(
            paymentResponse["orderFilled"]["order"]["status"]
          );
          setOpenPayErrorCode(paymentResponse.openPayError.error_code);
          setProcessingPayment(false);
          setRedirectPopupOpen(true);
          setTimeout(
            () => (window.location.href = cratedCharge?.payment_method.url),
            5000
          );
        } else {
          setOrderCreatedStatus("FAILED");
          setProcessingPayment(false);
          setPaymentResultOpen(true);
        }
      })
      .catch((e) => {
        console.error(e);
        setProcessingPayment(false);
        setPaymentResultOpen(true);
      });
  };

  const errorInPaymentCallback = (card: any) => {
    console.error("Error Open Pay", card);
    setProcessingPayment(false);
    setPaymentResultOpen(true);
  };

  const sendPayment = async () => {
    if (!paymentData) {
      return;
    }
    const isValidPaymentData = await paymentData.trigger();
    const isValidReasonData = await reasonData.trigger();

    if (isValidPaymentData && isValidReasonData) {
      setProcessingPayment(true);
      const { holder_name, card_number, expiration_date, cvv2 } =
        paymentData.getValues();
      const { expiration_month, expiration_year } =
        parseExpiryDate(expiration_date);

      const openPayData: any = {
        holder_name,
        card_number,
        expiration_month,
        expiration_year,
        cvv2,
        redirect_url: window.location.href,
        use_3d_secure: "true",
      };

      // @ts-ignore
      OpenPay.token.create(
        openPayData,
        _3dSecurePaymentCallBack,
        errorInPaymentCallback,
        1
      );
    }
  };

  return (
    <div className={styles.sticky}>
      <Card>
        <div className={styles["price-container"]}>
          <div>
            <PayButton
              sendPayment={sendPayment}
              inProcess={processingPayment}
            />
          </div>
          <div className={styles["medium-subtitle"]}>
            <p>Confirmación del pedido</p>
          </div>
          <div className={styles["flex-space-between"]}>
            <p>
              {productsQuantity}{" "}
              {productsQuantity === 1 ? "Producto" : "Productos"}
            </p>
            <p>${getPriceFormat(selectedOffer?.total)}</p>
          </div>
          <div className={styles["flex-space-between"]}>
            <p>Envío incluido</p>
            <p>$0</p>
          </div>
          <Divider color="grey" />
          <div
            className={`${styles["medium-subtitle"]} ${styles["flex-space-between"]}`}
          >
            <p className={styles["products-quantity"]}>Total</p>
            <p>${getPriceFormat(selectedOffer?.total)}</p>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default PaymentPay;

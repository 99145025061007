import { OptionsField } from "forms/form.types";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./TextArea.module.scss";
import fieldStyles from "../Field/Field.module.scss";

const TextArea: React.FC<OptionsField> = ({
  name,
  value,
  placeholder,
  label,
  required,
  type,
  disabled,
  fieldCaption = "",
  autoComplete = "off",
  customProps = {},
  maxLength,
}) => {
  const methods = useFormContext();
  const [currentValue, setCurrentValue] = useState(value);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    methods.clearErrors(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const validateText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    text !== "" ? setStatus("writing") : setStatus("not writing");
  };

  const emptyFieldWhenRequired =
    methods.formState.errors[name] &&
    methods.formState.errors[name].type === "required";

  const valueNotChanged = currentValue === value;

  const activeError = error !== "";
  const isEmptyFieldWithNoChange =
    emptyFieldWhenRequired && (currentValue === "" || valueNotChanged);
  const errorStyle =
    activeError || isEmptyFieldWithNoChange ? "input-error" : "";

  const caption = fieldCaption && (
    <span className={fieldStyles["field-caption"]}>{fieldCaption}</span>
  );

  const requiredMessage = emptyFieldWhenRequired &&
    (currentValue === "" || valueNotChanged) && (
      <span className={styles["error-text"]}>Este campo es requerido</span>
    );
  activeError &&
    (methods.formState.errors[name] = { type: "validation", message: error });

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <textarea
        {...methods.register(name, { value, required })}
        className={`${styles.input} ${styles[errorStyle]}`}
        defaultValue={currentValue}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
        maxLength={maxLength}
        onChange={(e) => {
          const value = e.target.value;
          if (maxLength) {
            const valueIsLargerThanExpected = value.length >= maxLength;
            if (valueIsLargerThanExpected) {
              setError("Se está excediendo el límite de caracteres");
            } else {
              setError("");
            }
          }
          setCurrentValue(value);
          methods.formState.errors[name] && validateText(e);
          methods.setValue(name, value);
        }}
        {...customProps}
      />
      {caption}
      {requiredMessage}
      {error !== "" && <div className={styles["error-text"]}> {error}</div>}
    </div>
  );
};

export default TextArea;

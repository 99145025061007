import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import validator from "validator";
import { OptionsField } from "forms/form.types";
import styles from "./EmailField.module.scss";

const EmailField: React.FC<OptionsField> = ({
  name,
  value,
  placeholder,
  label,
  required,
  type,
  disabled,
  validateDomain,
  validateItIsNewDataMessage = "",
  apiCall,
  needsToBeDifferentfrom = "",
  errorMessage = "",
}) => {
  const [error, setError] = useState<React.ReactNode>();
  const methods = useFormContext();
  const [currentValue, setCurrentValue] = useState(value);
  const [newEmail, setNewEmail] = useState(true);

  const validateEmail = (email: string) => {
    if (validator.isEmail(email)) {
      setError("");
      return true;
    }
    setError(errorMessage);
    return false;
  };

  const validateDomainEmail = (email: string) => {
    const isDomainEmail = email.split("@")[1] === validateDomain;
    if (isDomainEmail && validateEmail(email)) {
      setError("");
      return true;
    }
    setError(errorMessage);
    return false;
  };

  const validateEmailIsDifferent = (email: string) => {
    let currentEmailValue = methods.getValues()[name];
    const otherEmailValue = methods.getValues()[needsToBeDifferentfrom];
    currentEmailValue = email;

    if (currentEmailValue !== otherEmailValue && validateEmail(email)) {
      setError("");
      return true;
    }
    setError(errorMessage);
    return false;
  };

  const validateNewValueByEmail = async (email: string) => {
    setError("...");
    await apiCall!()
      .then((data) => {
        const currentEmail = email;
        const foundExistingUser = data.find((element: any) => {
          return (
            element.email === currentEmail.toUpperCase() ||
            element.email === currentEmail.toLowerCase()
          );
        });
        if (foundExistingUser === undefined) {
          setNewEmail(true);
          setError("");
        } else {
          setNewEmail(false);
          setError(validateItIsNewDataMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const activeError = error !== undefined && error !== "";
  const emptyFieldWhenRequired =
    methods.formState.errors[name] &&
    methods.formState.errors[name].type === "required";
  const valueNotChanged = currentValue === value;

  useEffect(() => {
    methods.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const errorStyle =
    activeError || (emptyFieldWhenRequired && valueNotChanged)
      ? "input-error"
      : "";

  const requiredMessage = emptyFieldWhenRequired && valueNotChanged && (
    <span className={styles["error-text"]}>Este campo es requerido</span>
  );
  activeError &&
    (methods.formState.errors[name] = { type: "validation", message: error });
  return (
    <div className={styles.container}>
      <label>{label}</label>
      <input
        {...methods.register(name, {
          value,
          required,
          validate: (value) => {
            let isValid = validateEmail(value);
            if (validateDomain) {
              isValid = validateDomainEmail(value);
            }
            if (validateItIsNewDataMessage) {
              isValid = newEmail;
              if (!isValid && validateDomainEmail(value)) {
                setError(validateItIsNewDataMessage);
              }
            }
            if (needsToBeDifferentfrom) {
              isValid = validateEmailIsDifferent(value);
            }
            return isValid;
          },
        })}
        className={`${styles.input} ${activeError && styles[errorStyle]}`}
        defaultValue={currentValue}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          const value = e.target.value.toLowerCase();
          setCurrentValue(value);
          validateEmail(value);
          validateDomain && validateDomainEmail(value);
          needsToBeDifferentfrom && validateEmailIsDifferent(value);
          validateItIsNewDataMessage &&
            validateDomainEmail(value) &&
            validateNewValueByEmail(value);
        }}
      />
      {requiredMessage}
      {activeError && <div className={styles["error-text"]}> {error}</div>}
    </div>
  );
};

export default EmailField;

import { Form } from "../form.types";

export const changePasswordProfileForm: Form = {
  formTitle: "Cambiar contraseña",
  fields: [
    {
      name: "actualPassword",
      label: "Escribe tu contraseña actual",
      required: true,
      placeholder: "Contraseña actual",
      type: "password",
      value: "",
    },
    {
      name: "newPassword",
      label: "Escribe tu nueva contraseña",
      required: true,
      placeholder: "Ejemplo1!",
      type: "password",
      value: "",
      errorMessage:
        "Tu contraseña debe de contener al menos 8 caracteres, una minúscula, una mayúscula, un número y un caracter especial.",
    },
    {
      name: "newPassword-confirm",
      label: "Confirma la nueva contraseña",
      required: true,
      placeholder: "Repite tu nueva contraseña",
      type: "password",
      value: "",
      errorMessage:
        "La contraseña debe de coincidir con la que ingresaste previamente.",
      needsValidationFrom: "newPassword",
    },
  ],
};

import React from "react";
import NotFound from "components/common/NotFound";
import styles from "./ShoppingCartItemsNotFound.module.scss";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";

const ShoppingCartItemsNotFound: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <NotFound
      title="¡No tienes artículos en tu carrito!"
      imgSrc="/empty-cart-no-face.jpg"
    >
      <div className={styles["info-container"]}>
        <div>
          No sabes que comprar, navega en el catálogo y agrega los productos que
          desees.
        </div>
        <Button color="primary" emphasis="high" action={() => navigate("/")}>
          Ir a ver el catálogo
        </Button>
      </div>
    </NotFound>
  );
};

export default ShoppingCartItemsNotFound;

import React from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Form as FormType } from "forms/form.types";
import Button from "components/common/Button";
import RenderForm from "components/RenderForm";
import Section from "components/common/Section";
import styles from "./Form.module.scss";

interface FormProps {
  inputForm: FormType;
  submitTitle: string;
  onSubmit: (data: FieldValues) => void;
  action?: React.ReactNode;
  showOnly?: boolean;
}

const Form: React.FC<FormProps> = ({
  inputForm,
  onSubmit,
  submitTitle,
  action,
  showOnly = false,
}) => {
  const form = useForm();

  return (
    <FormProvider {...form}>
      <div className={styles.container}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className={styles["form-container"]}
        >
          <Section
            title={inputForm.formTitle}
            action={action}
            titleSize="large"
          >
            {inputForm.formDescription && (
              <p className={styles["form-description"]}>
                {inputForm.formDescription}
              </p>
            )}
            <RenderForm inputForm={inputForm} />
          </Section>
          {!showOnly && (
            <div className={styles["submit-button-container"]}>
              <Button emphasis="high" curvature="high" action={onSubmit}>
                {submitTitle}
              </Button>
            </div>
          )}
        </form>
      </div>
    </FormProvider>
  );
};
export default Form;

import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

interface ProtectedRouteProps {
  redirectPath?: string;
  children?: React.ReactNode;
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirectPath = "/",
  children,
}) => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate(redirectPath);
    }
  }, [navigate, redirectPath, isAuthenticated, isLoading]);

  return children ? <div>{children}</div> : <Outlet />;
};

export default ProtectedRoute;

import styles from "./Card.module.scss";
interface CardProps {
  children: React.ReactNode;
  variant?:
    | "no-shadow"
    | "grey-card"
    | "red-card"
    | "blue-card"
    | "blue-right-card"
    | "input-card";
}
const Card: React.FC<CardProps> = ({ children, variant = "card" }) => {
  return <div className={styles[variant]}>{children}</div>;
};

export default Card;

import env from "react-dotenv";

export const INVENTORY_API = {
  categories: `${env.REACT_APP_BACK_HOST}/categories`,
  products: (categoryId: number) =>
    `${env.REACT_APP_BACK_HOST}/categories/${categoryId}/products`,
  productDetail: (productId: number) =>
    `${env.REACT_APP_BACK_HOST}/products/groupedProducts/${productId}`,
  articleDetail: (articleId: number) =>
    `${env.REACT_APP_BACK_HOST}/article/${articleId}`,
  search: `${env.REACT_APP_BACK_HOST}/search`,
  price: (userId: number, locationId: number, skus: string) =>
    `${env.REACT_APP_BACK_HOST}/orders-prices?userId=${userId}&locationId=${locationId}&skus=${skus}`,
};

export type Category = {
  categoryId: number;
  categoryName: string;
};

export type Article = {
  name: string;
  sku: string;
  articleId: string;
  characteristics: any;
};

export type ArticleDetail = {
  name: string;
  article_id: number;
  external_id: string;
  product_id: number;
  company_id: number;
  quantity: number;
  characteristics: any;
  price: number;
  substitutes?: number;
  images: Image[];
};

export type SearchQuery = {
  page: number;
  pageSize: number;
  orderBy: string;
  search: string;
};

export type SearchResult = {
  pages: number;
  totalPages: number;
  totalResults: string;
  pageSize: number;
  data: Array<Product>;
};

export type ProductDetail = {
  groupedProduct: Product;
  articles: Article[];
};

export type Image = {
  image_id: number;
  url: string;
};

export type Product = {
  categoryId: number;
  productName: string;
  productId: number;
  characteristics: Array<Characteristic>;
  unit: string;
  description: string;
  images: Array<Image>;
};

export type Characteristic = {
  characteristicId: number;
  characteristicName: string;
  values: Array<CharValue>;
};

export type CharValue = {
  valueId: number;
  value: string;
};

export type ProductPrice = {
  Deacero: { total: number; skus: Object; subtotal: number; tax: number };
  subtotal: number;
  tax: number;
  total: number;
};

import { useContext } from "react";
import { Offer, OffersOptions } from "config/api/offers.types";
import SelectedOfferContext from "config/selectedOfferContext";
import Card from "../../../components/common/Card/Card";
import { getEstimatedDate } from "../common";
import styles from "./OrderOptions.module.scss";

interface OptionProps {
  options: Offer[];
}

const OrderOptions: React.FC<OptionProps> = ({ options }) => {
  const { setSelectedOffer, selectedOffer, setSelectedOfferIndex } =
    useContext(SelectedOfferContext);

  const offersCards = options.map((option, index) => {
    let etaColor = "eta-green-color";
    const getMinDaysToArrival = () => {
      if (!option.shipments[0]?.MinDate) {
        return "10";
      }
      return getEstimatedDate(option.shipments[0].MinDate);
    };
    const getMaxDaysToArrival = () => {
      if (!option.shipments[0]?.MaxDate) {
        etaColor = "eta-red-color";
        return "40";
      }
      return getEstimatedDate(option.shipments[0].MaxDate);
    };

    const orderShipment = `Llega entre ${getMinDaysToArrival()} y ${getMaxDaysToArrival()} días hábiles`;
    return (
      <Card
        variant={
          option.title === selectedOffer.title ? "blue-card" : "input-card"
        }
        key={option.title}
      >
        <div
          className={styles["card-body"]}
          data-testid={option.title}
          onClick={() => {
            setSelectedOffer(option);
            setSelectedOfferIndex(index);
          }}
        >
          <div className={styles["offer-head"]}>
            {option.title}
            <span className={`${styles.eta} ${styles[etaColor]}`}>
              {orderShipment}
            </span>
          </div>
          <p className={styles["sub-label"]}>
            {OffersOptions[option.type as 1 | 2 | 3]}
          </p>
        </div>
      </Card>
    );
  });
  return <div>{offersCards}</div>;
};

export default OrderOptions;

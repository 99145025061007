import { useEffect, useState } from "react";
import { ProductDetail } from "config/api/inventory.api.types";
import Card from "components/common/Card";
import styles from "./ProductPrice.module.scss";
import { getPriceFormat } from "config/utils";
import Step from "../Step/Step";
import Divider from "components/common/Divider/Divider";
import ProductQuantity from "./ProductQuantity";
import Spinner from "components/common/Spinner";
import Button from "components/common/Button";

interface ProductPriceProps {
  price: number;
  quantity: number;
  product: ProductDetail;
  loadingPrice: boolean;
  onChangeQuantity: any;
  onClickAdd: any;
  ruleMin: number;
  ruleMultiply: number;
}

const ProductPrice: React.FC<ProductPriceProps> = ({
  price,
  quantity,
  product,
  loadingPrice,
  onChangeQuantity,
  onClickAdd,
  ruleMin,
  ruleMultiply,
}) => {
  const [priceFetching, setPriceFetching] = useState(loadingPrice);

  const handleQuantityChange = (e: any) => {
    onChangeQuantity(e);
  };
  const handleClick = () => {
    onClickAdd();
  };

  const priceUnknown = price === 0;
  const unit = product.groupedProduct.unit;

  useEffect(() => {
    loadingPrice ? setPriceFetching(true) : setPriceFetching(false);
  }, [loadingPrice]);

  const buttonContent = (content: string) => {
    return (
      <div className={styles["button-content"]}>
        {priceFetching ? (
          <Spinner color={"white"} size="small" />
        ) : (
          `${content}`
        )}
      </div>
    );
  };

  return (
    <Card>
      <div className={priceUnknown ? styles.grey : ""}>
        <Step step="2. Agrega la cantidad"></Step>
        <ProductQuantity
          ruleMin={ruleMin}
          ruleMultiply={ruleMultiply}
          value={quantity}
          disable={priceUnknown}
          onChange={handleQuantityChange}
          unit={unit && unit}
        />

        <div style={{ margin: "40px 0px" }}>
          <Divider />
        </div>
        {!priceUnknown && (
          <div
            className={[styles["price-line"], styles["unit-price"]].join(" ")}
          >
            <p>{"Precio unitario"}</p>
            <p className={styles.price}>${getPriceFormat(price)}</p>
          </div>
        )}
        <div className={styles["price-summary"]}>
          <div
            className={[styles["price-line"], styles["total-price"]].join(" ")}
          >
            <p>{"Total"}</p>
            <p className={styles.total}>${getPriceFormat(price * quantity)}</p>
          </div>
          {!priceUnknown && (
            <div className={styles.info}>
              <p className={styles.green}>{"Descuento incluido"}</p>
              <p>{"IVA incluido"}</p>
            </div>
          )}
        </div>
        <div className={styles.button}>
          <Button
            color="primary"
            curvature="high"
            emphasis="high"
            action={handleClick}
            disabled={(priceUnknown || quantity < 1) && !priceFetching}
          >
            {buttonContent("Agregar al carrito")}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ProductPrice;

import { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import env from "react-dotenv";
import { LocationCoordinates } from "config/api/api.types";

interface MapProps {
  center?: LocationCoordinates;
  zoom?: number;
  mapClicked?: (ev: any) => void;
  onZoomChanged?: any;
}
const sanPedroLocation = { lat: 25.65716, lng: -100.40268 };

const Map: React.FC<MapProps> = ({
  zoom = 12,
  center = sanPedroLocation,
  mapClicked,
  onZoomChanged,
}) => {
  const apiKey = env.REACT_APP_GOOGLE_MAPS_API_KEY!;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;

  function Map() {
    const centered = useMemo(() => center, []);
    const containerStyle = {
      width: "100%",
      height: "100%",
    };
    function handleZoomChanged(this: any) {
      onZoomChanged(this.getZoom());
    }

    return (
      <GoogleMap
        zoom={zoom}
        center={centered}
        mapContainerStyle={containerStyle}
        onClick={mapClicked && mapClicked}
        onZoomChanged={handleZoomChanged}
      >
        <MarkerF position={centered} />
      </GoogleMap>
    );
  }
};

export default Map;

import styles from "./MyProfile.module.scss";
import Sidebar from "./Sidebar/Sidebar";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useState } from "react";
import PersonalData from "views/MyProfile/PersonalData/PersonalData";
import TaxData from "views/MyProfile/TaxData/TaxData";
import DeliveryData from "views/MyProfile/DeliveryData/DeliveryData";
import { useNavigate } from "react-router-dom";

const MyProfileView: React.FC = () => {
  const navigate = useNavigate();
  const navigationItems = [
    {
      title: "Datos personales",
      icon: <PersonIcon />,
    },
    {
      title: "Datos fiscales",
      icon: <ApartmentIcon />,
    },
    {
      title: "Dirección de entrega",
      icon: <LocalShippingIcon />,
    },
  ];

  const pageLocation = {
    "Datos personales": PersonalData,
    "Datos fiscales": TaxData,
    "Dirección de entrega": DeliveryData,
  };

  const [selectedView, setSelectedView] = useState("Datos personales");

  type MyProfileRoute =
    | "Datos personales"
    | "Datos fiscales"
    | "Dirección de entrega";

  const profileViewIsValidRoute =
    selectedView &&
    ["Datos personales", "Datos fiscales", "Dirección de entrega"].includes(
      selectedView
    );
  const section = profileViewIsValidRoute ? selectedView : "Datos personales";
  const MyProfileSection = pageLocation[section as MyProfileRoute];

  return (
    <div className={styles.body}>
      <Sidebar
        views={navigationItems}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
      ></Sidebar>
      <div className={styles.content}>
        <div className={styles.backHome}>
          <KeyboardBackspaceIcon
            onClick={() => navigate("/")}
            sx={{ width: 32, height: 30, cursor: "pointer" }}
          />
          <p onClick={() => navigate("/")} className={styles.backHomeText}>
            Regresar a tienda
          </p>
        </div>
        <div className={styles.section}>
          <MyProfileSection />
        </div>
      </div>
    </div>
  );
};

export default MyProfileView;

import { CartProduct } from "components/common/common.types";
import React from "react";

const defaultShoppingCart = {
  shoppingCartItems: [] as CartProduct[],
  setShoppingCartItems: (items: CartProduct[]) => {},
};

const ShoppingCartContext = React.createContext(defaultShoppingCart);

export const ShoppingCartProvider = ShoppingCartContext.Provider;

export default ShoppingCartContext;

import env from "react-dotenv";

export const USER_API = {
  createUser: `${env.REACT_APP_BACK_HOST}/users`,
  getUser: (email: string) =>
    `${env.REACT_APP_BACK_HOST}/users/byEmail/${email}`,
  getUsers: `${env.REACT_APP_BACK_HOST}/users`,
  patchUser: (id: number) => `${env.REACT_APP_BACK_HOST}/users/${id}`,
};

type UserLocationData = {
  country: string;
  dir: string;
  id: number;
  lat: number;
  lon: number;
  neighborhood: string;
  postalCode: string;
  provider: null;
  referenceId: string;
  state: string;
  city: string;
  streetnumber: string;
  internalnumber: string;
  userId: string;
};

export type User = {
  additionalEmail: string;
  email: string;
  id: number;
  fatherLastName: string;
  motherLastName: string;
  locationData: UserLocationData[];
  name: string;
  phone: string;
  rfc: string;
  curp: string;
};

export type CreateUser = {
  user: {
    name: string;
    fatherLastName: string;
    motherLastName: string;
    rfc: string;
    curp: string;
    phone: string;
    fiscalDoc: string;
    email: string;
    additionalEmail: string;
    password: string;
  };
  deliveryAddress: {
    countryId: number;
    country: string;
    zipCode: number;
    stateId: number;
    state: string;
    cityId: number;
    city: string;
    neighborhoodId: number;
    neighborhood: string;
    street: string;
    externalNumber: number;
    internalNumber?: number;
    lat: number;
    lon: number;
  };
  fiscalAddress: {
    countryId: number;
    country: string;
    zipCode: number;
    stateId: number;
    state: string;
    cityId: number;
    city: string;
    neighborhoodId: number;
    neighborhood: string;
    street: string;
    externalNumber: number;
    internalNumber?: number;
  };
};

export type UpdateUser = {
  user: {
    name: string;
    fatherLastName: string;
    motherLastName: string;
    rfc: string;
    curp: string;
    phone: string;
    email: string;
    additionalEmail: string;
  };
  locations: UserLocationData[];
};

import Banner from "components/common/Banner";

const WRONG_PASSWORD_DESCRIPTION = (
  <Banner
    size="large"
    type="error"
    closeIcon={false}
    message="Wrong email or password."
  />
);
const CHANGE_PASSWORD_SUCCESS = (
  <Banner
    size="large"
    type="success"
    closeIcon={false}
    message="Contraseña cambiada."
  />
);
const CHANGE_PASSWORD_WRONG = (
  <Banner
    size="large"
    type="error"
    closeIcon={false}
    message="Contraseña equivocada"
  />
);
const CHANGE_PASSWORD_ERROR = (
  <Banner
    size="large"
    type="error"
    closeIcon={false}
    message="Error, intentar nuevamente"
  />
);
const ACCOUNT_ERROR_MESSAGE = (
  <Banner
    size="large"
    type="error"
    closeIcon={false}
    message="Hay un problema con tu cuenta, contacta a soporte"
  />
);
const UPDATE_USER_SUCCESS = (
  <Banner
    size="large"
    type="success"
    closeIcon={false}
    message="Cambios realizados con éxito."
  />
);
const UPDATE_USER_ERROR = (
  <Banner
    size="large"
    type="error"
    closeIcon={false}
    message="Error realizando cambios."
  />
);

export {
  WRONG_PASSWORD_DESCRIPTION,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_WRONG,
  CHANGE_PASSWORD_ERROR,
  ACCOUNT_ERROR_MESSAGE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
};

import Section from "../../../../components/common/Section";
import Card from "../../../../components/common/Card";
import { PaymentFormInputs } from "../../../../components/common/common.types";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import React, { useEffect } from "react";
import styles from "./PaymentInfoStep.module.scss";
import CardLogoComponent, { CardLogoType } from "../CardLogoComponent";
import TextField from "../../../../components/Form/TextField";
import CreditCardField from "../../../../components/Form/CreditCardField";
import CardExpiryField from "../../../../components/Form/CardExpiryField";
import CVVField from "../../../../components/Form/CVVField";
import PaymentInfoStepCardsRow from "./PaymentInfoStepCardsRow";

interface PaymentInfoProps {
  setPaymentDetails:  (paymentData: UseFormReturn<PaymentFormInputs, any>) => void;
}

const PaymentInfoStep: React.FC<PaymentInfoProps> = ({ setPaymentDetails }) => {

  const methods = useForm<PaymentFormInputs>();
  useEffect(() => {
    sendUpdatedPaymentData();
  })

  const sendUpdatedPaymentData = () => {
    setTimeout(() => {
      setPaymentDetails(
        methods
      )
    })
  }

  return (
    <Section
      title="4. Ingresa tus datos de pago"
      titleSize="small"
      widthSize="full"
      variant="no-margin-title"
      // action={checkProductsButton}
    >
      <Card variant="no-shadow">
        <FormProvider {...methods} >
          <form onChange={sendUpdatedPaymentData}
                onSubmit={methods.handleSubmit(sendUpdatedPaymentData)} id="processCard" name="processCard">
            <input type="hidden" name="token_id" id="token_id"></input>
            <input type="hidden" name="use_card_points" id="use_card_points" value="false"></input>
            <div className="pymnt-itm card active">
              <div className="pymnt-cntnt">
                <PaymentInfoStepCardsRow />
                <div className="sctn-row">
                  <div className="sctn-col">
                    <TextField
                      label={"Nombre del titular"}
                      required={true}
                      fieldCaption={"Como aparece en la tarjeta"}
                      placeholder={"Nombre del titular"}
                      type={"text"}
                      name={"holder_name"}
                      customProps={{
                        "data-openpay-card": "holder_name"
                      }}
                    />
                  </div>
                  <div className="sctn-col">
                    <CreditCardField
                      label={"Número de tarjeta"}
                      required={true}
                      fieldCaption={"Como aparece en la tarjeta"}
                      placeholder={"0000 0000 0000 0000"}
                      type={"text"}
                      name={"card_number"}
                      customProps={{
                        "data-openpay-card": "card_number"
                      }}
                    />
                  </div>
                </div>
                <div className="sctn-row">
                  <div className={styles["payment-half-width-col"]}>
                    <CardExpiryField
                      label={"Fecha de vencimiento"}
                      required={true}
                      fieldCaption={"Mes/Año"}
                      placeholder={"MM/AA"}
                      type={"text"}
                      name={"expiration_date"}
                      customProps={{
                        "data-openpay-card": "expiration_date"
                      }}
                      size={"half-width"}
                    />
                  </div>
                  <div className={styles["payment-half-width-col"]}>
                    <CVVField
                      label={"CVV"}
                      required={true}
                      fieldCaption={"Código de seguridad"}
                      placeholder={"000"}
                      type={"text"}
                      name={"cvv2"}
                      customProps={{
                        "data-openpay-card": "cvv2"
                      }}
                    />
                  </div>
                </div>
                <div className={styles["open-pay-info-row"]}>
                  <div className={styles["open-pay-logo-column"]}>
                    <img alt="Openpay" src={'/openpay/LogotipoOpenpay-01.jpg'}/>
                  </div>
                  <div className={styles["open-pay-encryption-column"]}>
                    <CardLogoComponent
                      type={CardLogoType.Debit}
                      alt="Transacción Seguro" src={'/openpay/Action.svg'}/>
                    <p>Tus pagos se realizan de forma segura con encriptación de 256 bits</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </Card>
    </Section>
  )
}

export default PaymentInfoStep;
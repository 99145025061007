import { useNavigate } from "react-router-dom";
import StatusView from "components/StatusView";

const NewAddressSent: React.FC = () => {
  const navigate = useNavigate();

  const newAddressContext = (
    <div>
      <p>
        Hemos enviado tu nueva dirección al equipo de beneficios, esto puede
        tomar alrededor de 3 días hábiles. Cuando el cambio se realice te
        enviaremos un correo para que puedas continuar con tu compra, mientras
        tu compra se mantendrá en el carrito.
      </p>
    </div>
  );

  return (
    <StatusView
      iconRoute="/new-location.svg"
      title="Tu cambio de dirección está en proceso"
      statusContext={newAddressContext}
      iconAlt="ícono de cambio de dirección en proceso"
      actionTitle="Ir a la página principal"
      action={() => {
        navigate("/");
      }}
    />
  );
};

export default NewAddressSent;

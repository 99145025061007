import { Dispatch, SetStateAction, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Offer } from "config/api/offers.types";
import ShoppingCartContext from "config/shoppingCartContext";
import SelectedOfferContext from "config/selectedOfferContext";
import Button from "components/common/Button";
import Popup from "components/common/Popup";
import useLocalStorage from "components/hooks/useLocalStorage";
import { orderCreatedStatuses } from "config/utils";
import { openPayErrors } from "views/OpenPayLab/utils/errors";
import styles from "./OrderPopupStatus.module.scss";

interface OrderPopupProps {
  setPaymentResultOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

const OrderPupupStatus: React.FC<OrderPopupProps> = ({
  setPaymentResultOpen,
  open,
}) => {
  const { setShoppingCartItems } = useContext(ShoppingCartContext);
  const { setSelectedOffer, setOfferArticles } =
    useContext(SelectedOfferContext);
  const orderCreatedStatus = useLocalStorage("orderStatus", []).storedValue;
  const setOrderStatus = useLocalStorage("orderStatus", []).setValue;
  const openPayErrorCode = useLocalStorage("openPayErrorCode", []).storedValue;
  const setOpenPayErrorCode = useLocalStorage("openPayErrorCode", {}).setValue;
  const navigate = useNavigate();
  const orderCreated = orderCreatedStatus.length ? true : false;

  if (orderCreated) {
    setPaymentResultOpen(true);
  }

  const contentSuccess = (
    <div>
      Pronto recibirás por correo electrónico la confirmación de tu compra y el
      id. de fabricación correspondiente para el seguimiento de tu pedido.
    </div>
  );

  const successPopup = (
    <Popup
      title={"¡Muchas gracias por comprar con nosotros!"}
      state={"success"}
      content={contentSuccess}
      button={
        <div className={styles["buttons-wrapper"]}>
          <Button
            color="success"
            curvature="high"
            emphasis="high"
            action={() => {
              setShoppingCartItems([]);
              setSelectedOffer({} as Offer);
              setOfferArticles([]);
              setOrderStatus("");
              setOpenPayErrorCode("");
              navigate("../");
            }}
          >
            Ir a la página principal
          </Button>
        </div>
      }
      open={open}
      maxWidth={"xs"}
      padding={false}
    />
  );

  const chargePendingContent = (
    <div>
      Si la compra fue exitosa recibirás pronto un correo electrónico con la
      confirmación de tu compra y el id. de fabricación correspondiente para el
      seguimiento de tu pedido.
    </div>
  );

  const chargePendingPopup = (
    <Popup
      title={"Se está procesando tu compra"}
      state={"refresh"}
      content={chargePendingContent}
      button={
        <div className={styles["buttons-wrapper"]}>
          <Button
            color="success"
            border={false}
            action={() => {
              setShoppingCartItems([]);
              setSelectedOffer({} as Offer);
              setOfferArticles([]);
              setOrderStatus("");
              navigate("../");
            }}
          >
            <p>Ir a la página principal</p>
          </Button>
        </div>
      }
      open={open}
      maxWidth={"xs"}
      padding={false}
    />
  );

  const orderWasSuccessfull =
    orderCreatedStatus === orderCreatedStatuses.ORDERED;

  const orderSuccessMessage = orderWasSuccessfull ? successPopup : null;

  const contentBankFailed = <div>{openPayErrors["3001"].messageSpanish}</div>;

  const contentGeneralFailed = (
    <div>
      Se ha producido un error. Por favor, vuelve a intentarlo más tarde.
    </div>
  );

  const cardDeclinedByBankError = openPayErrorCode === 3001;

  const contentFailed = cardDeclinedByBankError
    ? contentBankFailed
    : contentGeneralFailed;

  const FailedPopup = (
    <Popup
      title={"¡Ups!"}
      state={"error"}
      content={contentFailed}
      button={
        <div className={styles["buttons-wrapper"]}>
          <Button
            emphasis="high"
            action={() => {
              setPaymentResultOpen(false);
            }}
          >
            Volver a intentar
          </Button>
        </div>
      }
      open={open}
      maxWidth={"xs"}
      padding={false}
    />
  );

  const orderStatusPendingAfter3dSecure =
    orderCreatedStatus === orderCreatedStatuses.CHARGE_PENDING;

  const orderFailedMessage = !orderCreated ? FailedPopup : null;

  let popupMessage;

  if (orderWasSuccessfull) {
    popupMessage = orderSuccessMessage;
  } else if (cardDeclinedByBankError) {
    popupMessage = orderFailedMessage;
  } else if (orderStatusPendingAfter3dSecure) {
    popupMessage = chargePendingPopup;
  } else {
    popupMessage = orderFailedMessage;
  }

  return <div className={styles.container}>{popupMessage}</div>;
};

export default OrderPupupStatus;

import { OptionsField } from "forms/form.types";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./CheckboxField.module.scss";

const CheckboxField: React.FC<OptionsField> = ({
  name,
  value,
  label,
  children,
  required,
  type,
}) => {
  const methods = useFormContext();
  const [currentValue, setCurrentValue] = useState(value);
  const [isChecked, setIsChecked] = useState(false);

  const emptyFieldWhenRequired =
    methods.formState.errors[name] &&
    methods.formState.errors[name].type === "required";
  const valueNotChanged = isChecked === false;
  const errorStyle =
    emptyFieldWhenRequired && valueNotChanged ? "input-error" : "";

  const requiredMessage = emptyFieldWhenRequired && valueNotChanged && (
    <span className={styles["error-text"]}>Este campo es requerido</span>
  );

  return (
    <div>
      <div className={styles.container}>
        <input
          {...methods.register(name, {
            value,
            required,
            validate: (value) => value === "checked",
          })}
          defaultValue={currentValue}
          className={`${styles.input} ${styles[errorStyle]}`}
          type={type}
          onChange={(e) => {
            const value = e.target.checked;
            setIsChecked(value);
            value && setCurrentValue("checked");
            value && methods.setValue(name, "checked");
          }}
        />
        {label && <label>{label}</label>}
        {children}
      </div>
      {requiredMessage}
    </div>
  );
};

export default CheckboxField;

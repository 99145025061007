import env from "react-dotenv";

export const OFFERS_API = {
  getOffers: (userId: number, locationId: number) =>
    `${env.REACT_APP_BACK_HOST}/offers?userId=${userId}&locationId=${locationId}`,
  getOffersID: (userId: number, locationId: number) =>
    `${env.REACT_APP_BACK_HOST}/offersId?userId=${userId}&locationId=${locationId}`,
};

export type Offer = {
  cartSatisfactionRate: number;
  cartSubstituteRate: number;
  cartSubstitutesNumber: number;
  invalidShipment: boolean;
  missingArticles: ShipmentArticle[];
  price: number;
  shipments: Shipment[];
  title: string;
  discardedShipments: Shipment[];
  subtotal: number;
  tax: number;
  total: number;
  type: number;
};

type Shipment = {
  Distance: number;
  isInvalidShipment: boolean;
  Overpriced: number;
  articles: ShipmentArticle[];
  idBusinessLocation: number;
  ShipmentDate: string;
  MinDate?: string;
  MaxDate?: string;
};

export type ShipmentArticle = {
  articleSKU: number;
  quantity: number;
  prices?: { total: number; tax: number; subtotal: number };
  idCompany?: number;
  substitutes?: number;
  characteristics?: { prop: string; value: string; id: number }[];
};

export const OffersOptions = {
  1: "El envío de alguno de los productos puede demorarse hasta entre 10 y 40 días hábiles ya que se mandan a fabricar.",
  2: "Recibe de manera express los productos que cuentan con disponibilidad.",
  3: "Si no hay disponibilidad del producto que buscabas te ofrecemos uno similar.",
};

import React from "react";
import { useEffect } from "react";
import styles from "./AboutUs.module.scss";
import BgSection from "components/common/BgSection";
import Section from "components/common/Section";
import MailTo from "components/common/MailTo";
import View from "components/common/View/View";

const AboutUs: React.FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imageContainer = (
    <div className={styles["about-us-image"]}>
      <BgSection bgImage="/macrohub.jpg" position="middle">
        <div className={styles["image-content"]}>
          <div className={styles["image-text"]}>
            <div>Conócenos</div>
          </div>
        </div>
      </BgSection>
    </div>
  );

  return (
    <div>
      <div>{imageContainer}</div>
      <View>
        <br />
        <div className={styles["about-us-first-content"]}>
          <div>
            En DEACERO seguimos trabajando para mejorar la experiencia del
            colaborador, por ello, ponemos a tu alcance este nuevo portal, donde
            encontrarás a la venta todos nuestros materiales a precios
            preferenciales ¡sólo para ti!
          </div>
          <br />
          <div>
            Ayúdanos a vivir esta experiencia y danos tu opinión que será de
            gran utilidad para hacer crecer esta nueva plataforma.
          </div>
        </div>
        <Section
          title="¿No sabes por dónde empezar?"
          titleSize="small"
          widthSize="medium"
        >
          <div className={styles["about-us-second-content"]}>
            <div>
              Simplemente dirígete a la barra de búsqueda y escribe lo que
              desees encontrar, o bien, visita el catálogo para navegar y
              revisar los materiales disponibles por categoría de producto.
            </div>
            <br />
            <div>
              Si tienes alguna duda, dirígete al apartado de preguntas
              frecuentes o manda un correo a &nbsp;
              <MailTo email="beneficios@deacero.com" underlinded={true}>
                {"beneficios@deacero.com"}
              </MailTo>
              . ¡Estamos para apoyarte!
            </div>
          </div>
        </Section>
      </View>
    </div>
  );
};

export default AboutUs;

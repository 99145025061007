import env from "react-dotenv";

export const PROFILE_API = {
  getToken: `https://${env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
  changePassword: (token: string) =>
    `https://${env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${token}`,
};

export type tokenParams = {
  grant_type: string;
  username: string;
  password: string;
  audience: string;
  scope: string;
  client_id: string;
  client_secret: string;
};

export type changePasswordHeaders = {
  "Content-Type": string;
  Authorization: string;
};

export type changePasswordParams = {
  password: string;
  connection: string;
};

import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { useState } from "react";
import styles from "./ImageCard.module.scss";

interface ImageCardProps {
  title: string;
  size?: "small" | "medium" | "large";
  loading?: boolean;
  secondaryInfo?: string;
  imgSrc?: string;
}

const ImageCard: React.FC<ImageCardProps> = ({
  title,
  size = "small",
  loading = false,
  imgSrc,
  secondaryInfo,
}) => {
  const loadingClass = loading ? "loading" : "";
  const [imgState, setImgState] = useState(imgSrc);
  return (
    <div
      role="group"
      className={`${styles.card} ${styles[size]} ${styles[loadingClass]}`}
    >
      {loading ? (
        <div className={styles.cardImage} />
      ) : imgState ? (
        <img
          className={styles.cardImage}
          src={imgState}
          alt={title}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            setImgState("");
          }}
        />
      ) : (
        <div className={styles.cardImage}>
          <ImageNotSupportedIcon />
        </div>
      )}
      <div className={styles.cardInfo}>
        <div className={styles.cardName}> {title}</div>
        <div className={styles.secondaryInfo}> {secondaryInfo}</div>
      </div>
    </div>
  );
};

export default ImageCard;

import { useState } from "react";
import Banner from "components/common/Banner";
import Popup from "components/common/Popup";
import Button from "components/common/Button";
import { sendEmail } from "config/api/api";
import { TEMPLATES } from "components/common/Notifier/templates/templates_file";
import styles from "./MakeProductAvailable.module.scss";

interface MakeProductAvailableProps {
  emailTo: string;
  userEmail: string;
  sku: string;
  properties: object;
  currentUrl: string;
}

const MakeProductAvailable: React.FC<MakeProductAvailableProps> = ({
  emailTo,
  userEmail,
  sku,
  properties,
  currentUrl,
}) => {
  const [openPopup, setopenPopup] = useState(false);
  const popupContent = (
    <div>
      <div>
        Pronto te estaremos contactando por correo cuando el producto se
        encuentre disponible.
      </div>
      <div>
        Es importante estar atento ya que sólo se habilitará por un tiempo
        determinado en la página.
      </div>
    </div>
  );

  const bannerProductNotAvailable = (
    <div>
      <Banner
        size="large"
        type="error"
        iconSize="medium"
        closeIcon={false}
        message={
          <div className={styles["banner-message"]}>
            <div className={styles["banner-title"]}>
              Producto no disponible para venta empleados
            </div>
            <div className={styles["banner-description"]}>
              Si estás interesado en este producto haz clic en “dar de alta” y
              te contactaremos con los pasos a seguir.
            </div>
            <div
              className={styles.link}
              onClick={async () => {
                setopenPopup(true);
                sendEmail(
                  "ventaempleados@deacero.com",
                  emailTo,
                  "Dar de alta producto",
                  TEMPLATES.productToMakeAvailable(
                    userEmail,
                    sku,
                    properties,
                    currentUrl
                  )
                )
                  .then((data) => {
                    console.log(data);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              Dar de alta
            </div>
            <Popup
              title={"¡Hemos enviado tu solicitud!"}
              state={"success"}
              content={popupContent}
              button={
                <Button
                  color="secondary"
                  emphasis="high"
                  curvature="high"
                  action={() => {
                    setopenPopup(false);
                  }}
                >
                  Continuar
                </Button>
              }
              open={openPopup}
              maxWidth={"xs"}
              padding={false}
            />
          </div>
        }
      />
    </div>
  );
  return bannerProductNotAvailable;
};

export default MakeProductAvailable;

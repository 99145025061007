import styles from "./Step.module.scss";
interface StepProps {
  step: string;
}

const Step: React.FC<StepProps> = ({ step }) => {
  return <p className={styles.step}>{step}</p>;
};

export default Step;

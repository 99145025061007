import Button from "components/common/Button";
import styles from "./StatusView.module.scss";

interface StatusViewProps {
  iconRoute: string;
  title: string;
  statusContext: React.ReactNode;
  action: () => void;
  actionTitle?: string;
  iconAlt: string;
}

const StatusView: React.FC<StatusViewProps> = ({
  iconRoute,
  iconAlt,
  title,
  statusContext,
  action,
  actionTitle,
}) => {
  return (
    <div className={styles.view}>
      <div className={styles.card}>
        <div className={styles["status-window"]}>
          <div className={styles["icon-container"]}>
            <img src={iconRoute} alt={iconAlt} />
            <p className={styles.title}> {title}</p>
          </div>
        </div>
        <div className={styles["status-context"]}>{statusContext}</div>
        <div className={styles["action-container"]}>
          <Button emphasis="high" curvature="high" action={action}>
            {actionTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StatusView;

import { getData } from "./api";
import { Offer, OFFERS_API } from "./offers.types";

const getUserOffers = async (userId: number, locationId: number) => {
  let offers = [] as Offer[];
  await getData(OFFERS_API.getOffers(userId, locationId)).then(
    (data) => (offers = data.offers)
  );

  return offers;
};

const getUserOffersId = async (userId: number, locationId: number) => {
  let offersId = "" as String;
  await getData(OFFERS_API.getOffersID(userId, locationId)).then(
    (data) => (offersId = data.id)
  );
  return offersId;
};

export { getUserOffers, getUserOffersId };

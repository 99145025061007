import { useEffect, useState } from "react";
import Card from "components/common/Card";
import { ArticleSummary } from "../ProductDetailView";
import styles from "./ProductProperties.module.scss";

interface ProductPropertiesProps {
  price: number;
  activeArticle: ArticleSummary;
  loadingPrice?: boolean;
}

const ProductProperties: React.FC<ProductPropertiesProps> = ({
  price,
  activeArticle,
  loadingPrice = false,
}) => {
  const [properties, setproperties] = useState([] as string[]);

  useEffect(() => {
    if (activeArticle.sku) {
      setproperties(Object.keys(activeArticle.properties));
    }
  }, [activeArticle, price]);

  if (!activeArticle.properties || loadingPrice) {
    return null;
  }

  return (
    <Card {...{ variant: "grey-card" }}>
      <div className={styles["summary-container"]}>
        <p className={styles["summary-title"]}>Resumen del Producto</p>

        {activeArticle.sku && <p>SKU: {activeArticle.sku}</p>}
        {properties.map((prop: string) => {
          return (
            <p key={activeArticle.properties[prop]}>
              {prop}: {activeArticle.properties[prop]}
            </p>
          );
        })}
      </div>
    </Card>
  );
};

export default ProductProperties;

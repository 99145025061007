import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShoppingCartContext from "config/shoppingCartContext";
import sumProductsPrice from "components/common/sumProductsPrice";
import ShoppingCartItem from "views/ShoppingCart/ShoppingCartItem";
import { deleteShoppingCartItem } from "config/api/shoppingCartItems.api";
import { CartProduct } from "components/common/common.types";
import Button from "components/common/Button";
import Card from "components/common/Card";
import Section from "components/common/Section";
import styles from "./Products.module.scss";
import { getPriceFormat } from "config/utils";

const Products: React.FC = () => {
  const [totalPrice, settotalPrice] = useState(0);
  const [productsQuantity, setproductsQuantity] = useState(0);
  const navigate = useNavigate();
  const { shoppingCartItems, setShoppingCartItems } =
    useContext(ShoppingCartContext);

  useEffect(() => {
    const totalPriceProducts = sumProductsPrice(shoppingCartItems);
    settotalPrice(totalPriceProducts);
    let sumShoppingCartItems = 0;
    shoppingCartItems.forEach((shoppingCartItem) => {
      let shoppingCartItemQuantity = shoppingCartItem.quantity;
      sumShoppingCartItems = sumShoppingCartItems + shoppingCartItemQuantity;
    });
    setproductsQuantity(sumShoppingCartItems);
  }, [shoppingCartItems]);

  const deleteProductFromDB = async (product: CartProduct) => {
    await deleteShoppingCartItem(product.id)
      .then(() => {
        const newList = shoppingCartItems.filter(
          (item: CartProduct) => product !== item
        );
        setShoppingCartItems(newList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeItem = (product: CartProduct) => {
    deleteProductFromDB(product);
  };

  const PriceCardContent = (
    <div className={styles["price-container"]}>
      <div className={styles["total-header"]}>
        <p>Subtotal:</p>
        <p>${getPriceFormat(totalPrice)}</p>
      </div>
      <p className={styles["products-quantity"]}>
        {productsQuantity} {productsQuantity === 1 ? "Producto" : "Productos"}
      </p>
      <div>
        <Button
          disabled={totalPrice < 4000 || totalPrice > 200000}
          color="primary"
          contentColor="white"
          emphasis="high"
          curvature="high"
          action={() => {
            navigate("../carrito/ofertas");
          }}
        >
          Ir a pagar
        </Button>
        <Button
          color="primary"
          curvature="high"
          action={() => {
            navigate("../");
          }}
        >
          Agregar otro producto
        </Button>
      </div>
    </div>
  );

  return (
    <Section title="Verifica o edita tu listado de productos" widthSize="full">
      <div className={styles.body}>
        <div className={styles.products}>
          {shoppingCartItems.map((item: CartProduct) => {
            return (
              <div className={styles.product} key={item.id}>
                <Card>
                  <ShoppingCartItem item={item} removeItem={removeItem} />
                </Card>
              </div>
            );
          })}
        </div>
        <div>
          <div className={styles.sticky}>
            <Card>{PriceCardContent}</Card>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Products;

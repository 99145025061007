import Section from "components/common/Section";
import Card from "components/common/Card";
import styles from "./LoadingOffers.module.scss";

const LoadingOffers: React.FC = () => {
  return (
    <div>
      <Section
        title="Elige el tipo de envío de tu preferencia"
        titleSize="small"
        widthSize="full"
      >
        <div className={styles.body}>
          <div className={styles.options}>
            <p>1.- Seleccionar opción de envío.</p>
            <p className={styles["options-subtitle"]}>
              Ve las opciones para elegir la mejor para ti
            </p>
            {[...Array(3)].map((e, i) => (
              <Card variant="input-card" key={`${e} ${i}`}>
                <div className={styles["offer-head"]}>
                  <span
                    className={`${styles["loading"]} ${styles["input-tag"]}`}
                  ></span>
                  <span
                    className={`${styles["loading"]} ${styles["eta"]}`}
                  ></span>
                </div>
                <p
                  className={`${styles["loading"]} ${styles["sub-label"]}`}
                ></p>
              </Card>
            ))}
          </div>
          <div className={`${styles.options} ${styles["option-detail"]}`}>
            <p>2.- Revisar la información de la opción seleccionada.</p>
            <p className={styles["options-subtitle"]}>
              Ve las opciones para elegir la mejor para ti
            </p>
            <div className={styles["first-card"]}>
              <Card variant="no-shadow">
                <p
                  className={`${styles["loading"]} ${styles["total-price"]}`}
                ></p>
                <p
                  className={`${styles["loading"]} ${styles["green-text"]}`}
                ></p>
                <p
                  className={`${styles["loading"]} ${styles["sub-description"]}`}
                ></p>
                <p
                  className={`${styles["loading"]} ${styles["description"]}`}
                ></p>
              </Card>
            </div>
            {[...Array(3)].map((e, i) => (
              <div
                className={styles["product-wrapper"]}
                key={`${e} ${i} loading cards key`}
              >
                <Card>
                  <div className={styles.product}>
                    <div className={styles.header}>
                      <p
                        className={`${styles["loading"]} ${styles["item-title"]}`}
                      ></p>
                      <p
                        className={`${styles["loading"]} ${styles["item-price"]}`}
                      ></p>
                    </div>
                    <div className={styles.body}>
                      <div className={styles.properties}>
                        <div
                          className={`${styles["loading"]} ${styles["image-product"]}`}
                        />
                        <div>
                          <p
                            className={`${styles["loading"]} ${styles["properties-title"]}`}
                          ></p>
                          {[...Array(3)].map((e, i) => (
                            <p
                              className={`${styles["loading"]} ${styles["properties-text"]}`}
                              key={`${e} ${i} properties key`}
                            ></p>
                          ))}
                        </div>
                      </div>
                      <div className={styles.options}>
                        <div className={styles["quantity-wrapper"]}>
                          <p
                            className={`${styles["loading"]} ${styles["quantity-text"]}`}
                          ></p>
                          <div
                            className={`${styles["loading"]} ${styles["unit-container"]}`}
                          ></div>
                        </div>
                        <div
                          className={`${styles["loading"]} ${styles["buttons-wrapper"]}`}
                        ></div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
            <div className={styles["loading-card"]}>
              <div className={styles["right-wrapper"]}>
                {[...Array(3)].map((e, i) => (
                  <div
                    className={styles["data-wrapper"]}
                    key={`${e} ${i} loading key`}
                  >
                    <p
                      className={`${styles["loading"]} ${styles["left-text"]}`}
                    ></p>
                    <p
                      className={`${styles["loading"]} ${styles["right-text"]}`}
                    ></p>
                  </div>
                ))}
                <div
                  className={`${styles["loading"]} ${styles["total-wrapper"]}`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default LoadingOffers;

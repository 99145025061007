import { getLocation } from "config/api/api";
import { DynamicDataContext } from "config/dynamicDataContext";
import { Form } from "../form.types";

export const deliveryProfileForm: Form = {
  formTitle: "Dirección de entrega",
  formDescription: "Modifica la dirección donde recibirás tus productos.",
  fields: [
    {
      name: "deliveryAddressZipCode",
      label: "Código postal",
      required: true,
      placeholder: "N/A",
      type: "zipcode",
      errorMessage:
        "¡Lo sentimos! No hay opciones de envío a esta dirección. Por favor, introduzca un código postal diferente.",
      apiCall: getLocation,
    },
    {
      name: "deliveryAddressState",
      label: "Estado",
      required: true,
      placeholder: "N/A",
      type: "text",
      disabled: true,
    },
    {
      name: "deliveryAddressCity",
      label: "Municipio",
      required: true,
      placeholder: "N/A",
      type: "text",
      disabled: true,
    },
    {
      name: "deliveryAddressNeighborhoodId",
      label: "Colonia",
      required: true,
      placeholder: "N/A",
      type: "dropdown",
      dynamicOptionsProp: "deliveryAddress",
      optionsContext: DynamicDataContext,
    },
    {
      name: "deliveryAddressStreet",
      label: "Calle",
      required: true,
      placeholder: "N/A",
      type: "text",
      maxLength: 60,
    },
    {
      name: "deliveryAddressExternalNumber",
      label: "Número exterior",
      required: true,
      placeholder: "N/A",
      type: "text",
      size: "half-width",
      maxLength: 15,
    },
    {
      name: "deliveryAddressInternalNumber",
      label: "Número interior",
      required: false,
      placeholder: "N/A",
      type: "text",
      size: "half-width",
      maxLength: 15,
    },
    {
      name: "googleMapLatLng",
      label: "Ubica la dirección de entrega con un pin en el mapa",
      required: false,
      type: "map",
      errorMessage: "Aún no has ubicado la dirección de entrega en el mapa.",
      fieldCaption:
        "Arrastra el cursor en el mapa hasta llegar a tu ubicación y haz clic sobre ella para seleccionarla.",
    },
  ],
};

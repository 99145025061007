export const getEstimatedDate = (date: string) => {
  const todayDate = new Date();
  const arrivalDate = new Date(date);
  const difference = arrivalDate.getTime() - todayDate.getTime();
  return milisecondsToDays(difference);
};

const milisecondsToDays = (miliseconds: number) => {
  return Math.ceil(miliseconds / (1000 * 3600 * 24));
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

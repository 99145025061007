import styles from "./MailTo.module.scss";

interface MailToProps {
  children?: React.ReactNode;
  email: string;
  underlinded?: boolean;
}

const MailTo: React.FC<MailToProps> = ({
  children,
  email,
  underlinded = false,
}) => {
  const underlindedClass = underlinded ? "" : "not-underlined";
  return (
    <a
      className={`${styles.mail} ${styles[underlindedClass]}`}
      href={`mailto:${email}`}
    >
      {children}
    </a>
  );
};

export default MailTo;

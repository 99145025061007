import React from "react";
import { ArticleDetail } from "./api/inventory.api.types";
import { Offer } from "./api/offers.types";

const defaultOffer = {
  selectedOffer: {} as Offer,
  setSelectedOffer: (offer: Offer) => {},
  selectedOfferIndex: -1 as Number,
  setSelectedOfferIndex: (index: Number) => {},
  offerArticles: [] as ArticleDetail[],
  setOfferArticles: (articles: ArticleDetail[]) => {},
  offerId: "" as String,
  setOfferId: (offerid: String) => {},
};

const SelectedOfferContext = React.createContext(defaultOffer);

export const SelectedOfferProvider = SelectedOfferContext.Provider;

export default SelectedOfferContext;

import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Button from "components/common/Button";
import styles from "./Banner.module.scss";

interface BannerProps {
  type: "neutral" | "error" | "warning" | "info" | "success";
  message: React.ReactNode;
  size?: "large" | "medium" | "small";
  closeIcon?: boolean;
  iconSize?: "large" | "medium" | "small";
}
const Banner: React.FC<BannerProps> = ({
  type = "success",
  message = "",
  size = "large",
  closeIcon = true,
  iconSize = "small",
}) => {
  const [open, setOpen] = useState(true);
  type SizeProp = "lg" | "7x" | "5x" | "3x" | "2x" | "1x";
  let iconCurrentSize = "lg" as SizeProp;
  if (iconSize === "large") {
    iconCurrentSize = "5x";
  } else if (iconSize === "medium") {
    iconCurrentSize = "2x";
  } else {
    iconCurrentSize = "lg";
  }

  const iconTypes = {
    neutral: (
      <FontAwesomeIcon icon={faCircleExclamation} size={iconCurrentSize} />
    ),
    warning: (
      <FontAwesomeIcon icon={faCircleExclamation} size={iconCurrentSize} />
    ),
    error: (
      <FontAwesomeIcon icon={faTriangleExclamation} size={iconCurrentSize} />
    ),
    info: <FontAwesomeIcon icon={faCircleInfo} size={iconCurrentSize} />,
  } as any;

  if (open) {
    return (
      <div className={styles["banner-container"]}>
        <div className={`${styles.banner} ${styles[type]} ${styles[size]}`}>
          <div className={styles["icon-message-container"]}>
            <div className={styles[`${type}-icon`]}>{iconTypes[type]}</div>
            {message}
          </div>

          {closeIcon && (
            <Button
              action={() => setOpen(false)}
              emphasis="low"
              color={"secondary"}
              size="xsmall"
            >
              <div className={styles.close}>
                <CloseIcon />
              </div>
            </Button>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Banner;

import { postRequest, patchRequest } from "./api";
import {
  PROFILE_API,
  changePasswordHeaders,
  changePasswordParams,
  tokenParams,
} from "./profile.api.types";

const getToken = (getTokenParams: tokenParams) => {
  return postRequest(PROFILE_API.getToken, getTokenParams);
};

const changePassword = (
  changePasswordParams: changePasswordParams,
  token: string,
  headers: changePasswordHeaders
) => {
  return patchRequest(
    PROFILE_API.changePassword(token),
    changePasswordParams,
    headers
  );
};

export { getToken, changePassword };

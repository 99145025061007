import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserOffers, getUserOffersId } from "config/api/offers.api";
import SelectedOfferContext from "config/selectedOfferContext";
import OrderOptions from "views/Offers/OrderOptions";
import useLocalStorage from "components/hooks/useLocalStorage";
import Section from "components/common/Section";
import Button from "components/common/Button";
import LoadingOffers from "./LoadingOffers";
import styles from "./Offers.module.scss";
import OfferDetail from "./OfferDetail";
import { useQuery } from "@tanstack/react-query";
import UnavailableService from "components/UnavailibleService/UnavailableService";

const Offers: React.FC = () => {
  const { selectedOffer, setSelectedOffer, setSelectedOfferIndex, setOfferId } =
    useContext(SelectedOfferContext);
  const [offers, setOffers] = useState([] as any[]);
  const [requestError, setrequestError] = useState("");
  const userData = useLocalStorage("userData", {}).storedValue;
  const userId = Number(userData["id"]);
  const locationId = Number(userData["locationData"][0]["id"]);

  const navigate = useNavigate();

  const { data, isError, isLoading, isSuccess } = useQuery({
    queryKey: ["offers", userId],
    queryFn: async () => {
      const offersData = await getUserOffers(userId, locationId).catch(
        (error) => {
          setrequestError("/offers");
          console.debug(error);
          return [];
        }
      );
      setOffers(offersData);
      return offersData;
    },
  });

  useEffect(() => {
    let offersData = data ?? [];
    let offersLength = offersData.length;
    const firstOfferArticlesLength =
      offersData[0]?.shipments[0]?.articles?.length ?? -1;
    const secondOfferArticlesLength =
      offersData[1]?.shipments[0]?.articles?.length ?? -2;

    const lower4kOfferIndex = offersData.findIndex((offer) => {
      return offer.total < 4000;
    });

    const thereIsALower4kOffer = lower4kOfferIndex !== -1;

    if (offersLength > 1 && thereIsALower4kOffer) {
      offersData.splice(lower4kOfferIndex, 1);
      offersLength = offersData.length;
    }

    const higher200kOfferIndex = offersData.findIndex((offer) => {
      return offer.total > 200000;
    });

    const thereIsAHigher200kOffer = higher200kOfferIndex !== -1;

    if (offersLength > 1 && thereIsAHigher200kOffer) {
      offersData.splice(higher200kOfferIndex, 1);
      offersLength = offersData.length;
    }

    if (
      offersLength > 1 &&
      firstOfferArticlesLength === secondOfferArticlesLength
    ) {
      offersData = offersData.filter((element) => {
        return element.title !== "Estándar";
      });
    }
    setOffers(offersData);
    if (isSuccess && offersLength > 0) {
      setSelectedOffer(offersData[0]);
      setSelectedOfferIndex(0);
    }
  }, [data, isError, isSuccess, setSelectedOffer, setSelectedOfferIndex]);

  const getOffersIdQuery = useQuery({
    queryKey: ["offersId"],
    queryFn: async () => {
      const offersIdData = await getUserOffersId(userId, locationId).catch(
        () => {
          setrequestError("/offersId");
          return "";
        }
      );
      setOfferId(offersIdData);
      return offersIdData;
    },
  });

  if (isLoading || getOffersIdQuery.status === "loading") {
    return <LoadingOffers />;
  }

  if (offers.length === 0 && isSuccess && data.length > 0) {
    setOffers(data);
  }

  if (requestError.length > 0 || offers.length === 0) {
    return <UnavailableService service={requestError} />;
  }

  const noOfferSelected = (
    <div className={styles["no-option-wrapper"]}>
      <p className={styles["no-option-message"]}>
        ¡Parece que aún no has seleccionado una opción!
      </p>
      <img
        className={styles.image}
        src="/empty-cart-no-face.jpg"
        alt="no offer selected"
      />
    </div>
  );

  const actions = (
    <div className={styles.actions}>
      <Button
        color="primary"
        action={() => navigate(`../carrito/productos`)}
        curvature="high"
      >
        Regresar
      </Button>
      <Button
        color="primary"
        emphasis="high"
        curvature="high"
        disabled={selectedOffer.total < 4000 || selectedOffer.total > 200000}
        action={() => navigate(`../carrito/pago`)}
      >
        Continuar
      </Button>
    </div>
  );

  return (
    <div>
      <Section
        title="Elige el tipo de envío de tu preferencia"
        titleSize="small"
        widthSize="full"
        test-id="offer-type"
      >
        <div className={styles.body}>
          <div className={styles.options}>
            <p>1.- Seleccionar opción de envío.</p>
            <p className={styles["options-subtitle"]}>
              Ve las opciones para elegir la mejor para ti
            </p>
            <OrderOptions options={offers} />
          </div>
          <div className={`${styles.options} ${styles["option-detail"]}`}>
            <p>2.- Revisar la información de la opción seleccionada.</p>
            {!selectedOffer.type ? noOfferSelected : <OfferDetail />}
          </div>
        </div>
      </Section>
      {actions}
    </div>
  );
};

export default Offers;

import { TabItem } from "components/common/common.types";
import Button from "components/common/Button";
import styles from "./SideMenuItem.module.scss";

export interface MenuItemProps {
  item: TabItem;
  variant: "medium" | "high";
  onSelect: (itemId: TabItem) => void;
}

const SideMenuItem: React.FC<MenuItemProps> = ({ item, variant, onSelect }) => {
  const setActive = () => onSelect(item);
  return (
    <div className={styles.item}>
      <Button
        color={"secondary"}
        action={() => setActive()}
        emphasis={variant}
        curvature="low"
      >
        <p className={styles.content}>{item.title}</p>
      </Button>
    </div>
  );
};

export default SideMenuItem;

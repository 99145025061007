import { changeAddressRequest } from "./changeAddressRequest copy";
import { newProductToMakeAvailable } from "./makeProductAvailable";

export type UserAddress = {
  zipCode: number;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  externalNumber: string;
  internalNumber: string;
  lat: string;
  lon: string;
};

export const TEMPLATES = {
  productToMakeAvailable: (
    userEmail: string,
    sku: string,
    properties: object,
    url: string
  ) => newProductToMakeAvailable(userEmail, sku, properties, url),
  changeAddressRequest: (userEmail: string, userAddress: UserAddress) =>
    changeAddressRequest(userEmail, userAddress),
};

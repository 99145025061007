import { Form } from "../form.types";

export const fiscalAddressProfileForm: Form = {
  formTitle: "Dirección fiscal",
  formDescription: "Consulta tu dirección fiscal.",
  fields: [
    {
      name: "taxAddressZipCode",
      label: "Código postal",
      required: true,
      placeholder: "N/A",
      type: "zipcode",
      fieldCaption: "De la constancia fiscal",
      disabled: true,
    },
    {
      name: "taxStreet",
      label: "Avenida o calle",
      required: true,
      placeholder: "N/A",
      type: "text",
      fieldCaption: "De la constancia fiscal",
      disabled: true,
    },
    {
      name: "taxExternalNumber",
      label: "Número exterior",
      required: true,
      placeholder: "N/A",
      type: "text",
      size: "half-width",
      value: "",
      fieldCaption: "De la constancia fiscal",
      disabled: true,
    },
    {
      name: "taxInternalNumber",
      label: "Número interior",
      required: false,
      placeholder: "N/A",
      type: "text",
      size: "half-width",
      value: "",
      fieldCaption: "De la constancia fiscal",
      disabled: true,
    },
    {
      name: "taxNeighborhood",
      label: "Colonia",
      required: true,
      placeholder: "N/A",
      type: "text",
      fieldCaption: "De la constancia fiscal",
      disabled: true,
    },
    {
      name: "taxState",
      label: "Estado",
      required: true,
      placeholder: "N/A",
      type: "text",
      disabled: true,
      fieldCaption: "De la constancia fiscal",
    },
    {
      name: "taxCity",
      label: "Ciudad",
      required: true,
      placeholder: "N/A",
      type: "text",
      disabled: true,
      fieldCaption: "De la constancia fiscal",
    },
  ],
};

const signupMessages = {
  success: {
    title: "¡Felicidades! Registro completado",
    content: (
      <div>
        <div>
          Hemos enviado tu información y tu cuenta se encuentra en proceso de
          verificación.
        </div>
        <div> Una vez aprobada te lo haremos saber por correo.</div>
      </div>
    ),
    buttonContent: "Entendido",
  },
  error: {
    title: "Ha ocurrido un error en el registro",
    content: (
      <div>
        <div>
          Ocurrió un error por nuestro lado mientras enviábamos la información
          de tu registro.
        </div>
        <div> Por favor, inténtalo de nuevo o contacta a:</div>
      </div>
    ),
    foreignProp: "beneficios@deacero.com",
    buttonContent: "Intentar nuevamente",
  },
};

export default signupMessages;

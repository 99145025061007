import Divider from "components/common/Divider";
import styles from "./Table.module.scss";

interface TableProps {
  children?: React.ReactNode;
  primaryColumn?: string;
  columnsNames: Array<string>;
}

const Table: React.FC<TableProps> = ({
  children,
  primaryColumn,
  columnsNames,
}) => {
  const columnsClass = primaryColumn ? "primary-column-present" : "";

  const tableHeader = (
    <div>
      <div className={styles[columnsClass]}>
        {primaryColumn && (
          <p className={styles["primary-column"]}>{primaryColumn}</p>
        )}
        <div className={styles.columns}>
          {columnsNames.map((columnName) => {
            return <p key={columnName}>{columnName}</p>;
          })}
        </div>
      </div>
      <div className={styles["check-divider"]}>
        <Divider color="grey" margin={false} />
      </div>
    </div>
  );

  const rows = children;

  return (
    <div>
      {tableHeader}
      {rows}
    </div>
  );
};

export default Table;

import styles from "./LoadingPage.module.scss";

const LoadingPage: React.FC = () => {
  return (
    <div className={styles["loader-container"]}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default LoadingPage;

import { Form } from "../form.types";

export const personalProfileForm: Form = {
  formTitle: "Datos personales",
  formDescription: "Consulta o actualiza tus datos personales y de contacto.",
  fields: [
    {
      name: "name",
      label: "Nombre(s)",
      required: false,
      placeholder: "N/A",
      type: "text",
      disabled: true,
      value: "",
    },
    {
      name: "fatherLastName",
      label: "Apellido paterno",
      required: false,
      placeholder: "N/A",
      type: "text",
      size: "half-width",
      disabled: true,
      value: "",
    },
    {
      name: "motherLastName",
      label: "Apellido materno",
      required: false,
      placeholder: "N/A",
      type: "text",
      size: "half-width",
      disabled: true,
      value: "",
    },
    {
      name: "email",
      label: "Correo electrónico",
      required: false,
      placeholder: "N/A",
      type: "email",
      disabled: true,
      value: "",
    },
    {
      name: "taxEmail",
      label: "Correo electrónico personal",
      required: true,
      placeholder: "N/A",
      type: "email",
      errorMessage:
        "Por favor ingresa una dirección de correo personal/adicional.",
    },
    {
      name: "phone",
      label: "Teléfono",
      required: true,
      placeholder: "N/A",
      type: "tel",
      errorMessage: "Por favor ingresa tu teléfono a 10 dígitos.",
    },
  ],
};

import React from "react";
import styles from "./CardLogoComponent.module.scss";

interface CardLogoProps {
  alt: string;
  src: string;
  type: string;
}

export enum CardLogoType {
  Credit = 'credit',
  Debit = 'debit',
}

const CardLogoComponent: React.FC<CardLogoProps> = ({ alt,
                                                      src, type }) => {
  return (
    <div className={`${styles["card-logo-container"]} ${styles[`${type}-card-logo-container`]}`}>
      <img alt={alt} src={src}/>
    </div>
  );
};

export default CardLogoComponent;
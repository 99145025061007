import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { getLocation } from "config/api/api";
import { DynamicDataContext } from "config/dynamicDataContext";

const LocationController: React.FC = () => {
  const formState = useFormContext();
  const zipCode = formState.watch("deliveryAddressZipCode");
  const fiscalZipCode = formState.watch("fiscalZipCode");
  const { dynamicData, setDynamicData } = useContext(DynamicDataContext);

  useEffect(() => {
    if (zipCode && zipCode.length === 5) {
      const location = getLocation(zipCode);
      location.then((data) => {
        if (data.location) {
          formState.setValue("deliveryAddressState", data.location.state);
          formState.setValue("deliveryAddressCity", data.location.city);
          setDynamicData({
            deliveryAddress: {
              ...data.location,
              deliveryAddressNeighborhoodId: data.location.neighborhood,
            },
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [zipCode]);

  useEffect(() => {
    if (fiscalZipCode && fiscalZipCode.length === 5) {
      const location = getLocation(fiscalZipCode);
      location.then((data) => {
        if (data.location) {
          formState.setValue("fiscalState", data.location.state);
          formState.setValue("fiscalCity", data.location.city);
          setDynamicData({
            ...dynamicData,
            fiscalAddress: {
              ...data.location,
              fiscalNeighborhoodId: data.location.neighborhood,
            },
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [fiscalZipCode]);

  return null;
};

export default LocationController;

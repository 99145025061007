import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchProducts, fetchCategories } from "config/api/inventory.api";
import { Product } from "config/api/inventory.api.types";
import BgSection from "components/common/BgSection";
import ImageCard from "components/common/ImageCard";
import LoadingCard from "components/common/LoadingCard";
import Section from "components/common/Section";
import View from "components/common/View";
import { TabItem } from "components/common/common.types";
import SearchBar from "components/common/SearchBar";
import Banner from "components/common/Banner";
import LoadingPage from "./LoadingPage";
import styles from "./Home.module.scss";
import UnauthorizedPopup from "components/App/UnauthorizedPopup";
import SideMenu from "components/common/SideMenu/SideMenu";
import { useQuery } from "@tanstack/react-query";
import UnavailableService from "components/UnavailibleService";

const Home: React.FunctionComponent = () => {
  const [isUserAuthorized, setisUserAuthorized] = useState(false);
  const [categories, setCategories] = useState<TabItem[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [activeCategory, setActiveCategory] = useState({} as TabItem);
  const [requestError, setrequestError] = useState("");
  const navigate = useNavigate();

  let productsCards;

  const {
    isLoading,
    isAuthenticated,
    user,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user) {
        await loginWithRedirect();
      }
    })();
  }, [isLoading, user, loginWithRedirect]);

  useEffect(() => {
    let claims = getIdTokenClaims();
    claims.then((userMetadata) => {
      if (userMetadata && userMetadata["deacero_authorized"]) {
        setisUserAuthorized(true);
      }
    });
  }, [getIdTokenClaims, user]);

  const getCategoriesQuery = useQuery({
    queryKey: ["categories"],
    enabled: !!isAuthenticated,
    queryFn: async () => {
      const categoriesData = await fetchCategories().catch(() => {
        setrequestError("/categories");
        return [];
      });
      let categories = categoriesData.map(
        (category) =>
          ({
            id: category.categoryId,
            title: category.categoryName,
          } as TabItem)
      );
      categories = categories.sort((a, b) => {
        const aTitle = a.title;
        const bTitle = b.title;
        return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0;
      });
      setCategories(categories);
      return categoriesData;
    },
  });

  useQuery({
    queryKey: ["products", activeCategory.id],
    enabled: !!activeCategory.id,
    queryFn: async () => {
      const productsData = await fetchProducts(activeCategory.id).catch(() => {
        setrequestError(`categories/${activeCategory.id}`);
        return [];
      });
      const products = productsData;
      setProducts(products);
      return productsData;
    },
  });

  if (requestError.length) {
    return <UnavailableService service={requestError} />;
  }

  const onSelectCategory = (category: TabItem) => {
    setActiveCategory(category);
  };

  if (getCategoriesQuery.status === "loading") {
    productsCards = <LoadingCard cards={8} variant="medium" />;
  } else {
    productsCards = products.map((product) => {
      const productImage = product.images[0]?.url ?? "";
      return (
        <div
          key={product.productId}
          className={styles.pointer}
          onClick={() => navigate(`../producto/${product.productId}`)}
        >
          <ImageCard
            title={product.productName}
            imgSrc={productImage}
            size="small"
            loading={false}
          />
        </div>
      );
    });
  }

  const homeHeader = (
    <div className={styles["home-image"]}>
      <BgSection bgImage="/macrohub.jpg" position="top">
        <div className={styles["header-content"]}>
          <div className={styles["header-title"]}>
            Conoce el portal que te ayudará a comprar productos DEACERO más
            fácil y rápido.
          </div>
          <SearchBar />
        </div>
      </BgSection>
    </div>
  );

  if (isAuthenticated && !isLoading) {
    return (
      <View header={homeHeader}>
        {!isUserAuthorized && (
          <UnauthorizedPopup isUserAuthorized={isUserAuthorized} />
        )}
        <Banner
          size="large"
          type="info"
          message="Por políticas de la empresa, este beneficio aplica para un mínimo de compra de $4,000 MXN y un máximo de $200,000 MXN por transacción."
        />
        {categories.length > 0 && (
          <Section title="Catálogo de productos">
            <div className={styles.catalog}>
              <div className={styles["side-menu"]}>
                <SideMenu options={categories} onSelectTab={onSelectCategory} />
              </div>
              <div className={styles["products-container"]}>
                {productsCards}
              </div>
            </div>
          </Section>
        )}
      </View>
    );
  } else {
    return <LoadingPage />;
  }
};

export default Home;

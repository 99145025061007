import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { ArticleDetail } from "config/api/inventory.api.types";
import { OffersOptions } from "config/api/offers.types";
import SelectedOfferContext from "config/selectedOfferContext";
import { getEstimatedDate } from "views/Offers/common";
import PaymentItem from "views/Payment/PaymentItem";
import Button from "components/common/Button";
import Card from "components/common/Card";
import Divider from "components/common/Divider";
import Section from "components/common/Section";
import Table from "components/common/Table";
import styles from "./OrderSummary.module.scss";

const OrderSumary: React.FC = () => {
  const navigate = useNavigate();
  const { selectedOffer, offerArticles } = useContext(SelectedOfferContext);

  const checkProductsButton = (
    <Button
      color="primary"
      emphasis="low"
      action={() => {
        navigate("../");
      }}
    >
      Agregar otro producto
      <AddToPhotosIcon className={styles.icon} />
    </Button>
  );

  return (
    <Section
      title="2. Verifica tus artículos"
      titleSize="small"
      widthSize="full"
      variant="no-margin-title"
      action={checkProductsButton}
    >
      <div className={styles["small-subtitle"]}>
        Opción {selectedOffer.title.toLowerCase()}
      </div>
      <p> {OffersOptions[selectedOffer.type as 1 | 2 | 3]}</p>
      {selectedOffer.shipments &&
        selectedOffer.shipments.map((shipment, i) => {
          let etaColor = "eta-green-color";
          const getMinDaysToArrival = () => {
            if (!shipment.MinDate) {
              etaColor = "eta-green-color";
              return "10";
            }
            return getEstimatedDate(shipment.MinDate);
          };
          const getMaxDaysToArrival = () => {
            if (!shipment.MaxDate) {
              etaColor = "eta-red-color";
              return "40";
            }
            return getEstimatedDate(shipment.MaxDate);
          };

          const orderShipment = `Llega entre ${getMinDaysToArrival()} y ${getMaxDaysToArrival()} días hábiles`;
          return (
            <Card variant="no-shadow" key={i}>
              <Card variant="blue-card">
                <div>
                  Días de entrega:
                  <span className={`${styles.eta} ${styles[etaColor]}`}>
                    {orderShipment}
                  </span>
                </div>
              </Card>
              <div className={styles["check-divider"]}>
                <Divider color="grey" />
              </div>
              <Table
                primaryColumn={"Lista de Productos"}
                columnsNames={["Cantidad", "Precio"]}
              >
                {offerArticles.map((item: ArticleDetail) => {
                  return <PaymentItem key={item.article_id} article={item} />;
                })}
              </Table>
            </Card>
          );
        })}
    </Section>
  );
};

export default OrderSumary;

import { useAuth0 } from "@auth0/auth0-react";
import useLocalStorage from "components/hooks/useLocalStorage";
import { getUser } from "config/api/user.api";
import { UserProvider } from "config/userContext";
import { useEffect, useMemo, useState } from "react";

interface UserProps {
  children?: React.ReactNode;
}

const UserHandler: React.FC<UserProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth0();
  const { setValue } = useLocalStorage("userData", {});

  const [userData, setUserData] = useState(
    useLocalStorage("userData", {}).storedValue
  );
  const userValue = useMemo(() => ({ userData, setUserData }), [userData]);

  useEffect(() => {
    if (isAuthenticated && user?.email) {
      getUser(user.email)
        .then((data) => {
          setUserData(data);
          setValue(data);
        })
        .catch((error) => console.log("error obteniendo a usuario"));
    }
    // eslint-disable-next-line
  }, [user, isAuthenticated]);

  return <UserProvider value={userValue}>{children}</UserProvider>;
};

export default UserHandler;

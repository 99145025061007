import { useRef, useContext, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationDataContext } from "config/notificationDataContext";
import styles from "./Notification.module.scss";
import Button from "components/common/Button";

interface NotificationProps {
  size?: "large";
  top: boolean;
}
const Notification: React.FC<NotificationProps> = ({ size = "large", top }) => {
  const notificationRef = useRef<any>(null);
  const { notificationData, setNotificationData } = useContext(
    NotificationDataContext
  );
  const Notification = (e: any) => {
    if (
      notificationRef.current &&
      notificationData &&
      !notificationRef.current.contains(e.target)
    ) {
      setNotificationData(null);
    }
  };
  document.addEventListener("mousedown", Notification);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fixStyle = top && scrollPosition < 270 ? "with-top" : "without-top";

  if (notificationData) {
    return (
      <div
        className={`${styles["notification-container"]} ${styles[fixStyle]}`}
      >
        <div
          ref={notificationRef}
          className={`${styles.notification} ${styles[size]}`}
        >
          <div className={styles.close}>
            <Button action={() => setNotificationData(null)} emphasis="low">
              <CloseIcon />
            </Button>
          </div>
          {notificationData && <div>{notificationData}</div>}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Notification;

import { useEffect, useState } from "react";
import Tab from "./Tab/Tab";
import { TabItem } from "../common.types";
import styles from "./Tabs.module.scss";

interface TabsProps {
  variant?: "secondary" | "secondary-filled";
  options: TabItem[];
  onSelectTab: (tab: TabItem) => void;
  firstTabContentFetched?: boolean;
}

const Tabs: React.FC<TabsProps> = ({
  variant = "secondary-filled",
  options,
  onSelectTab,
  firstTabContentFetched,
}) => {
  const [activeTab, setactiveTab] = useState(options[0]);
  const [firstTabContentAlreadyFetched, setFirstTabContentAlreadyFetched] =
    useState(firstTabContentFetched);

  const setActive = (tab: TabItem) => {
    setactiveTab(tab);
    onSelectTab(tab);
  };

  useEffect(() => {
    if (firstTabContentAlreadyFetched && options.length > 0) {
      setActive(options[0]);
      setFirstTabContentAlreadyFetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTabContentFetched, options]);

  const tabs = options.map((tab) => {
    return (
      <Tab
        key={tab.id}
        variant={activeTab === tab ? "high" : "medium"}
        tab={tab}
        onSelect={setActive}
      />
    );
  });
  return <div className={styles.tabs}>{tabs}</div>;
};

export default Tabs;

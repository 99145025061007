import { CartProduct } from "components/common/common.types";
import sumProductsPrice from "components/common/sumProductsPrice";
import useLocalStorage from "components/hooks/useLocalStorage";
import { getProductPrice } from "config/api/inventory.api";
import ShoppingCartContext from "config/shoppingCartContext";
import { useContext, useEffect, useState } from "react";

interface ShoppingCartDataProps {
  children: React.ReactNode;
}

const ShoppingCartData: React.FC<ShoppingCartDataProps> = ({ children }) => {
  const userData = useLocalStorage("userData", {}).storedValue;
  const userId = Number(userData["id"]);
  const locationId = Number(
    userData["locationData"] ? userData["locationData"][0]["id"] : 0
  );
  const [, settotalPrice] = useState(0);
  const { shoppingCartItems, setShoppingCartItems } =
    useContext(ShoppingCartContext);

  useEffect(() => {
    const updateProductsPrice = (shoppingCartItems: Array<CartProduct>) => {
      const newItemsArray: any = shoppingCartItems.map(async (item) => {
        await getProductPrice(userId, locationId, `{"${item.articleId}":1}`)
          .then((priceData: any) => {
            const productPrice = priceData.total ?? 0;
            item.price = productPrice;
          })
          .catch((error) => {
            console.debug(error);
          });

        return item;
      });

      Promise.all(newItemsArray).then((productsWithUpdatedPrice) => {
        setShoppingCartItems(productsWithUpdatedPrice);
      });
    };
    updateProductsPrice(shoppingCartItems);
    const totalPriceProducts = sumProductsPrice(shoppingCartItems);
    settotalPrice(totalPriceProducts);
    // eslint-disable-next-line
  }, [userId, locationId, setShoppingCartItems]);

  return <div>{children}</div>;
};

export default ShoppingCartData;

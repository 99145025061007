import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Popup from "components/common/Popup";
import Button from "components/common/Button";
import ShowPDF from "components/common/ShowPDF";
import styles from "./TermsAndConditions.module.scss";

interface TermsAndConditionsProps {
  type: "signup" | "footer";
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ type }) => {
  const [openPopup, setopenPopup] = useState(false);

  const popupContent = (
    <div className={styles.popup}>
      <div className={styles.close}>
        <Button
          size="xsmall"
          color="secondary"
          emphasis="low"
          action={() => setopenPopup(false)}
        >
          <CloseIcon />
        </Button>
      </div>
      <ShowPDF
        file={"/TyC-PORTAL-VENTA-EMPLEADOS-VF.pdf"}
        pageNumber={13}
      ></ShowPDF>
    </div>
  );

  const signupContent = (
    <div className={styles.container}>
      <div>
        <p>He leído y aceptado los &nbsp;</p>
      </div>
      <div
        className={styles["popup-trigger"]}
        onClick={() => setopenPopup(true)}
      >
        <p>términos y condiciones</p>
      </div>
    </div>
  );

  const footerContent = (
    <Button
      color="white"
      emphasis="low"
      action={() => setopenPopup(true)}
      size="xsmall"
    >
      <p>Políticas y condiciones</p>
    </Button>
  );

  const TermsAndConditionsContent =
    type === "signup" ? signupContent : footerContent;
  return (
    <div>
      {TermsAndConditionsContent}
      <Popup
        content={popupContent}
        open={openPopup}
        maxWidth={"md"}
        padding={false}
      />
    </div>
  );
};

export default TermsAndConditions;

import { useContext, useState } from "react";
import ReasonStep from "./PaymentSteps/ReasonStep/ReasonStep";
import PaymentInfoStep from "views/Payment/PaymentSteps";
import Section from "components/common/Section";
import Divider from "components/common/Divider";
import {
  ReasonFormInputs,
  PaymentFormInputs,
} from "components/common/common.types";
import { UseFormReturn } from "react-hook-form";
import SelectedOfferContext from "config/selectedOfferContext";
import useScript from "components/hooks/useScript";
import PaymentPay from "./PaymentPay";
import OrderPopupStatus from "./OrderPopupStatus";
import RedirectPopupStatus from "./RedirectPopupStatus";
import styles from "./Payment.module.scss";
import OrderSummary from "./PaymentSteps/OrderSummary";
import AddressStep from "./PaymentSteps/AddressStep/AddressStep";
import NewAddressSent from "components/NewAddressSent/NewAddressSent";

const Payment: React.FC = () => {
  const [reasonData, setReasonData] = useState(
    null as unknown as UseFormReturn<ReasonFormInputs, any>
  );
  const [paymentData, setPaymentData] = useState(
    null as unknown as UseFormReturn<PaymentFormInputs, any>
  );
  const [redirectPopup, setRedirectPopup] = useState(false);
  const [paymentResultOpen, setPaymentResultOpen] = useState(false);
  const [isChangingAddress, setisChangingAddress] = useState(false);
  const { selectedOffer } = useContext(SelectedOfferContext);

  useScript(
    `https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js`
  );

  const setReasonDetails: (
    reasonData: UseFormReturn<ReasonFormInputs, any>
  ) => void = (reasonData: UseFormReturn<ReasonFormInputs, any>) => {
    setReasonData(reasonData);
  };

  const setPaymentDetails: (
    paymentData: UseFormReturn<PaymentFormInputs, any>
  ) => void = (paymentData: UseFormReturn<PaymentFormInputs, any>) => {
    setPaymentData(paymentData);
  };

  if (isChangingAddress) {
    return <NewAddressSent />;
  }

  if (selectedOffer.title === undefined) {
    const location = window.location.href;
    if (location.split("?")[1]) {
      return (
        <OrderPopupStatus
          setPaymentResultOpen={setPaymentResultOpen}
          open={true}
        />
      );
    }
  }

  return (
    <Section title="Realiza tu pago">
      <OrderPopupStatus
        setPaymentResultOpen={setPaymentResultOpen}
        open={paymentResultOpen}
      />
      <RedirectPopupStatus
        setPaymentResultOpen={setPaymentResultOpen}
        redirectMessage={redirectPopup}
        open={redirectPopup}
      />
      <div className={styles.body}>
        {selectedOffer.title && (
          <div className={styles["payment-container"]}>
            <div className={styles["payment-steps"]}>
              <AddressStep
                onChangeAddressAction={() => setisChangingAddress(true)}
              />
              <Divider color="grey" />
              <OrderSummary />
              <Divider color="grey" />
              <ReasonStep setReasonDetails={setReasonDetails}></ReasonStep>
              <Divider color="grey" />
              <PaymentInfoStep
                setPaymentDetails={setPaymentDetails}
              ></PaymentInfoStep>
            </div>
            <div className={styles["payment-box"]}>
              <PaymentPay
                paymentData={paymentData}
                reasonData={reasonData}
                setPaymentResultOpen={setPaymentResultOpen}
                setRedirectPopupOpen={setRedirectPopup}
              />
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};

export default Payment;

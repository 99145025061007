import { Dispatch, SetStateAction } from "react";
import Button from "components/common/Button";
import Popup from "components/common/Popup";
import styles from "./RedirectPopupStatus.module.scss";

interface OrderPopupProps {
  setPaymentResultOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  redirectMessage: boolean;
}

const RedirectPopupStatus: React.FC<OrderPopupProps> = ({
  setPaymentResultOpen,
  redirectMessage,
  open,
}) => {
  const contentFailed = (
    <div>Se ha producido un error, verifíca tus datos.</div>
  );

  const FailedPopup = (
    <Popup
      title={"¡Ups!"}
      state={"error"}
      content={contentFailed}
      button={
        <div className={styles["buttons-wrapper"]}>
          <Button
            emphasis="high"
            action={() => {
              setPaymentResultOpen(false);
            }}
          >
            Volver a intentar
          </Button>
        </div>
      }
      open={open}
      maxWidth={"xs"}
      padding={false}
    />
  );

  const contentRedirect = (
    <div>
      Es posible que tu banco solicite una confirmación adicional para
      garantizar la seguridad de la operación.
    </div>
  );

  const RedirectPopup = (
    <Popup
      title={"Estamos procesando tu pago"}
      state={"refresh"}
      content={contentRedirect}
      button={
        <div className={styles["loading-button"]}>
          <Button
            emphasis="low"
            loading
            loadingColor="black"
            action={() => {}}
          ></Button>
        </div>
      }
      open={open}
      maxWidth={"xs"}
      padding={false}
    />
  );
  const orderRedirectMessage = redirectMessage ? RedirectPopup : null;

  return (
    <div className={styles.container}>
      {redirectMessage ? orderRedirectMessage : FailedPopup}
    </div>
  );
};

export default RedirectPopupStatus;

import { CartProduct } from "components/common/common.types";

const sumProductsPrice = (shoppingCartItems: Array<CartProduct>) => {
  let totalPriceRoundedTwoDecimals = 0;
  shoppingCartItems.forEach((item) => {
    totalPriceRoundedTwoDecimals += item.price * item.quantity;
    totalPriceRoundedTwoDecimals =
      Math.ceil(Math.round(totalPriceRoundedTwoDecimals * 100 * 1e9) / 1e9) /
      100;
  });
  return totalPriceRoundedTwoDecimals;
};

export default sumProductsPrice;

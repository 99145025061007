import { Form } from "../form.types";

export const fiscalDataProfileForm: Form = {
  formTitle: "Datos fiscales",
  formDescription: "Consulta tus datos fiscales.",
  fields: [
    {
      name: "taxIdentity",
      label: "Nombre legal",
      required: true,
      placeholder: "N/A",
      type: "text",
      disabled: true,
    },
    {
      name: "taxRfc",
      label: "RFC",
      required: true,
      placeholder: "N/A",
      type: "rfc",
      errorMessage: "Por favor ingresa tu RFC completo con 13 caracteres.",
      disabled: true,
    },
    {
      name: "taxCurp",
      label: "CURP",
      required: true,
      placeholder: "N/A",
      type: "curp",
      errorMessage:
        "Por favor ingresa tu CURP completo con homoclave a 18 caracteres.",
      disabled: true,
    },
  ],
};

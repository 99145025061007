import { getData, postRequest } from "./api";
import {
  ArticleDetail,
  Category,
  INVENTORY_API,
  Product,
  ProductDetail,
  SearchQuery,
} from "./inventory.api.types";

const fetchProducts = (categoryId: number): Promise<Product[]> => {
  return getData(INVENTORY_API.products(categoryId));
};

const getProductPrice = (userId: number, locationId: number, skus: string) => {
  return getData(INVENTORY_API.price(userId, locationId, skus));
};

const searchProducts = (query: string, currentPage: number): Promise<any> => {
  const body: SearchQuery = {
    search: query,
    page: currentPage,
    pageSize: 50,
    orderBy: "name:ASC",
  };
  return postRequest(INVENTORY_API.search, body);
};

const getProductDetail = (productId: number): Promise<ProductDetail> => {
  return getData(INVENTORY_API.productDetail(productId));
};

const getArticleDetail = (articleId: number): Promise<ArticleDetail> => {
  return getData(INVENTORY_API.articleDetail(articleId));
};

const fetchCategories = (): Promise<Category[]> => {
  return getData(INVENTORY_API.categories);
};

export {
  fetchProducts,
  getProductPrice,
  searchProducts,
  getProductDetail,
  getArticleDetail,
  fetchCategories,
};

import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartContext from "config/shoppingCartContext";
import SearchBar from "components/common/SearchBar";
import Button from "components/common/Button";
import Tooltip from "components/common/Tooltip";
import Divider from "components/common/Divider";
import styles from "./Header.module.scss";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth0();
  const username = user?.given_name || user?.name;
  const { shoppingCartItems } = useContext(ShoppingCartContext);
  const activePath = location.pathname;
  const pathIsHome = activePath === "/";
  const backgroundColor = pathIsHome ? "clean" : "color";
  const [cartIsOpen, setCartIsOpen] = useState(false);
  const [logIsOpen, setlogIsOpen] = useState(false);

  const handleCartOpen = () => {
    setCartIsOpen(true);
  };

  const handleCartClose = () => {
    setCartIsOpen(false);
  };

  const handleLogOpen = () => {
    setlogIsOpen(true);
  };

  const handleLogClose = () => {
    setlogIsOpen(false);
  };

  const shoppingCartTooltip = (
    <div>
      <div className={styles.title}>Mi Carrito</div>
      <div className={styles["cart-tooltip-children"]}>
        <div className={styles["cart-tooltip-text"]}>
          <p>¡No tienes artículos en tu carrito! </p>
        </div>
        <img
          className={styles["cart-tooltip-img"]}
          src="/empty-cart-sad-face.jpg"
          alt="empty-cart-sad-face"
        />
        <div className={styles["cart-tooltip-navigate-button"]}>
          <Button
            color="primary"
            emphasis="high"
            curvature="high"
            action={() => {
              navigate("/");
              handleCartClose();
            }}
          >
            Agrega productos
          </Button>
        </div>
      </div>
    </div>
  );

  const logTooltip = (
    <div className={styles["tooltip-log"]}>
      <Button
        emphasis="low"
        action={() => {
          navigate("/miperfil");
          handleLogClose();
        }}
      >
        Mi Perfil
      </Button>
      <Divider color="grey" />
      <Button
        emphasis="low"
        action={() => {
          navigate("/ayuda");
          handleLogClose();
        }}
      >
        Ayuda
      </Button>
      <Divider color="grey" />
      <Button
        emphasis="low"
        action={() => {
          navigate("conocenos");
          handleLogClose();
        }}
      >
        Conócenos
      </Button>
      <Divider color="grey" />
      <Button
        emphasis="low"
        action={() => logout({ returnTo: window.location.origin })}
      >
        Salir
      </Button>
    </div>
  );

  const shoppingCartButton = (
    <div
      className={
        shoppingCartItems.length > 0
          ? styles["shopping-cart-icon"]
          : styles["shopping-cart-icon-empty"]
      }
    >
      <Button
        emphasis="low"
        size="xsmall"
        action={() => shoppingCartItems.length && navigate("carrito/productos")}
      >
        <div className={styles["shopping-cart-count-wrapper"]}>
          {shoppingCartItems.length > 0 && (
            <div className={styles["shopping-cart-count-text-wrapper"]}></div>
          )}
          <ShoppingCartIcon sx={{ color: "white", fontSize: 30 }} />
        </div>
      </Button>
    </div>
  );

  const logButton = (
    <div className={styles["login-container"]}>
      <Button action={() => void 0} emphasis="low" size="xsmall">
        <div id="user-icon" className={styles["person-icon"]}>
          <PersonIcon sx={{ color: "white", fontSize: 38 }} />
        </div>
        <p className={styles.username}>
          Hola,&nbsp;
          <b>{username}</b>
        </p>
      </Button>
    </div>
  );

  if (!username) {
    return null;
  }

  return (
    <div className={`${styles.header} ${styles[backgroundColor]}`}>
      <div className={styles["logo-container"]}>
        <Button size="xsmall" emphasis="low" action={() => navigate("/")}>
          <img
            src="/logo-header.svg"
            alt="deacero-logo"
            className={styles.logo}
          />
        </Button>
      </div>
      {!pathIsHome && (
        <div className={styles["search-bar"]}>
          <SearchBar navbarMode={true} />
        </div>
      )}
      <div className={styles["login-shopping-cart-container"]}>
        <Tooltip
          offset={[-20, 1]}
          position="bottom left"
          triggerTooltip={logButton}
          isOpen={logIsOpen}
          handleClose={handleLogClose}
          handleOpen={handleLogOpen}
          closeWithX={false}
        >
          {logTooltip}
        </Tooltip>
        {!shoppingCartItems.length && (
          <Tooltip
            offset={[20, 1]}
            position="bottom left"
            triggerTooltip={shoppingCartButton}
            isOpen={cartIsOpen}
            handleClose={handleCartClose}
            handleOpen={handleCartOpen}
          >
            {shoppingCartTooltip}
          </Tooltip>
        )}
        {shoppingCartItems.length > 0 && shoppingCartButton}
      </div>
    </div>
  );
};

export default Header;

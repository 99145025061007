import { useEffect, useState } from "react";
import { TabItem } from "../common.types";
import SideMenuItem from "./SideMenuItem/SideMenuItem";
import styles from "./SideMenu.module.scss";

interface SideMenuProps {
  options: TabItem[];
  onSelectTab: (tab: TabItem) => void;
  firstTabContentFetched?: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({ options, onSelectTab }) => {
  const [activeTab, setactiveTab] = useState(options[0]);

  const setActive = (tab: TabItem) => {
    setactiveTab(tab);
    onSelectTab(tab);
  };

  useEffect(() => {
    if (options.length > 0) {
      setActive(options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const menu = options.map((tab) => {
    return (
      <SideMenuItem
        key={tab.id}
        variant={activeTab === tab ? "high" : "medium"}
        item={tab}
        onSelect={setActive}
      />
    );
  });
  return <div className={styles.menu}>{menu}</div>;
};

export default SideMenu;

import { useContext, useEffect } from "react";
import { ArticleDetail } from "config/api/inventory.api.types";
import { OffersOptions, ShipmentArticle } from "config/api/offers.types";
import SelectedOfferContext from "config/selectedOfferContext";
import { getArticleDetail, getProductPrice } from "config/api/inventory.api";
import ShoppingCartItem from "views/ShoppingCart/ShoppingCartItem";
import useLocalStorage from "components/hooks/useLocalStorage";
import Card from "components/common/Card";
import { CartProduct } from "components/common/common.types";
import styles from "./OfferDetail.module.scss";
import Divider from "components/common/Divider";
import SubstituteItem from "./SubstituteItem";
import { getPriceFormat } from "config/utils";

const OfferDetail: React.FC = () => {
  const { selectedOffer, offerArticles, setOfferArticles } =
    useContext(SelectedOfferContext);
  const userData = useLocalStorage("userData", {}).storedValue;

  useEffect(() => {
    if (selectedOffer) {
      const getOfferDetails = () => {
        const offerArticles = selectedOffer.shipments[0]?.articles;
        const userId = Number(userData["id"]);
        const locationId = Number(userData["locationData"][0]["id"]);
        let arts = [] as ArticleDetail[];
        offerArticles.forEach((shipmentArticle: ShipmentArticle) => {
          getArticleDetail(shipmentArticle.articleSKU).then((article) => {
            getProductPrice(
              userId,
              locationId,
              `{"${shipmentArticle.articleSKU}":1}`
            ).then((productPrice) => {
              const articleToPush = {
                ...article,
                substitutes: shipmentArticle.substitutes,
                quantity: shipmentArticle.quantity,
                price: productPrice.total,
              };
              arts = arts.concat(articleToPush);
              setOfferArticles(arts);
            });
          });
        });
      };
      if (selectedOffer.type) {
        getOfferDetails();
      }
    }
  }, [selectedOffer, setOfferArticles, userData]);
  if (!offerArticles) {
    return null;
  }
  const articlesCards = offerArticles.map((item: ArticleDetail) => {
    if (!item) {
      return null;
    }
    const toCartProduct: CartProduct = {
      id: Number(item.external_id),
      sku: item.external_id,
      quantity: item.quantity!,
      userId: -1,
      articleId: Number(item.article_id),
      title: item.name,
      price: item.price!,
      srcImage: item.images[0]?.url,
      characteristics: Object.entries(item.characteristics).map(
        ([k, v], n) => ({ prop: k, value: v as string, id: n.toString() })
      ),
    };

    if (item.substitutes) {
      return (
        <div className={styles.product} key={toCartProduct.id}>
          <div className={styles.product}>
            <Card variant="blue-card">
              <p className={styles["blue-text"]}>
                El producto que estabas buscando no está disponible, creemos que
                esta opción podría gustarte.
              </p>
            </Card>
          </div>
          <Card>
            <ShoppingCartItem item={toCartProduct} />
            <Divider />
            <SubstituteItem article={item.substitutes} />
          </Card>
        </div>
      );
    }

    return (
      <div className={styles.product} key={toCartProduct.id}>
        <Card>
          <ShoppingCartItem item={toCartProduct} />
        </Card>
      </div>
    );
  });

  return (
    <div>
      <div className={styles["summary-card"]}>
        <Card variant="blue-card">
          <p className={styles["total-price"]}>
            ${getPriceFormat(selectedOffer.total)}
          </p>
          <p className={styles["discount"]}>Descuento Incluido</p>
          <p className={styles["description"]}>Costo de envío: Incluido</p>
          <p className={styles["description"]}>
            {OffersOptions[selectedOffer.type as 1 | 2 | 3]}
          </p>
        </Card>
      </div>
      {offerArticles.length > 0 ? articlesCards : null}
      <Card variant="blue-right-card">
        <div className={styles["right-wrapper"]}>
          <div className={styles["data-wrapper"]}>
            <p>Sub-total:</p>
            <p>${getPriceFormat(selectedOffer.total)}</p>
          </div>
          <div className={styles["data-wrapper"]}>
            <p>Envío:</p>
            <p>Incluido</p>
          </div>
          <div className={styles["data-wrapper"]}>
            <p>IVA:</p>
            <p>Incluido</p>
          </div>
          <div className={styles["total-wrapper"]}>
            <p>Total:</p>
            <span style={{ width: "20px" }}></span>
            <p>${getPriceFormat(selectedOffer.total)}</p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OfferDetail;

import ImageCard from "components/common/ImageCard";

interface LoadingCardProps {
  cards: Number;
  variant: "small" | "medium" | "large";
}

const LoadingCard: React.FC<LoadingCardProps> = ({ cards, variant }) => {
  const loadingCards = Array.from(Array(cards).keys()).map((_, index) => {
    return <ImageCard key={index} size={variant} title={" "} loading={true} />;
  });
  return <div>{loadingCards}</div>;
};

export default LoadingCard;

import { postRequest, fetchData, patchRequest, deleteRequest } from "./api";
import {
  CartItem,
  SHOPPING_CART_ITEMS_API,
} from "./shoppingCartItems.api.types";

const publishShoppingCartItem = (cartItem: CartItem) => {
  return postRequest(SHOPPING_CART_ITEMS_API.publishShoppingCartItem, cartItem);
};

const getShoppingCartItems = (userId: number) => {
  return fetchData(SHOPPING_CART_ITEMS_API.getShoppingCartItems(userId));
};

const patchShoppingCartItem = (itemId: number, cartItem: CartItem) => {
  return patchRequest(
    SHOPPING_CART_ITEMS_API.patchShoppingCartItem(itemId),
    cartItem
  );
};

const deleteShoppingCartItem = (itemId: number) => {
  return deleteRequest(SHOPPING_CART_ITEMS_API.deleteShoppingCartItem(itemId));
};

export {
  publishShoppingCartItem,
  getShoppingCartItems,
  patchShoppingCartItem,
  deleteShoppingCartItem,
};

import { useEffect, useState } from "react";
import {
  Article,
  Characteristic,
  ProductDetail,
} from "config/api/inventory.api.types";
import Divider from "components/common/Divider";
import ExpandableContent from "components/common/ExpandableContent";
import { Property } from "../ProductDetailView";
import styles from "./SelectableProperties.module.scss";
import Button from "components/common/Button";

interface SelectablePropertiesProps {
  product: ProductDetail;
  productProperties: Characteristic[];
  onIsPropertyAlreadyIncluded: any;
  onSelectProperty: any;
  onResetProperties: any;
  selectedProperties: Property[];
  productArticles: Article[];
}

const SelectableProperties: React.FC<SelectablePropertiesProps> = ({
  product,
  productProperties,
  onIsPropertyAlreadyIncluded,
  onSelectProperty,
  onResetProperties,
  productArticles,
  selectedProperties,
}) => {
  const [filteredArticles, setFilteredArticles] = useState(productArticles);

  useEffect(() => {
    const isValueSelected = (idValue: number): boolean => {
      let aux = 0;
      let prop;
      while (aux < selectedProperties.length) {
        prop = selectedProperties[aux];
        if (prop?.id === idValue) {
          return true;
        }
        aux++;
      }
      return false;
    };

    productProperties.forEach((prop) => {
      if (
        prop.values.length === 1 &&
        !isValueSelected(prop.values[0].valueId)
      ) {
        onSelectProperty({
          prop: prop.characteristicName,
          value: prop.values[0].value,
          id: prop.values[0].valueId,
        });
      }
    });
  }, [onSelectProperty, productProperties, selectedProperties]);

  useEffect(() => {
    if (selectedProperties.length) {
      const filteredArticles = productArticles.filter((article) => {
        let existProperty = false;
        selectedProperties.forEach((sProperty) => {
          existProperty =
            article.characteristics[sProperty?.prop] === sProperty?.value;
        });
        return existProperty;
      });
      setFilteredArticles(filteredArticles);
    }
  }, [selectedProperties, productArticles]);

  const isPropertyAvailable = (prop: Property) => {
    const foundArticle = filteredArticles.find(
      (article) => article.characteristics[prop.prop] === prop.value
    );
    return foundArticle?.articleId ? true : false;
  };

  const isSinglePropDisponible = (
    productChar: Characteristic,
    property: Property
  ) => {
    const availableOptions = [] as Property[];
    productChar.values.forEach((value) => {
      const prop = {
        prop: productChar.characteristicName,
        value: value.value,
        id: value.valueId,
      } as Property;
      if (isPropertyAvailable(prop)) {
        availableOptions.unshift(prop);
      }
    });
    if (availableOptions.length === 1) {
      if (property.id === availableOptions[0].id) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={styles["product-props-container"]}>
      <p className={styles.title}>{product.groupedProduct.productName}</p>
      <Divider color="grey" />
      <p className={styles.title}>
        1. Selecciona las características que deseas
      </p>
      {productProperties &&
        productProperties.map((prop, index) => {
          return (
            <ExpandableContent
              key={prop.characteristicId}
              title={prop.characteristicName}
              isPrimary={false}
              divider={false}
              isOpen={true}
            >
              <div className={styles["values-container"]}>
                {prop.values.map((value) => {
                  const property = {
                    prop: prop.characteristicName,
                    value: value.value,
                    id: value.valueId,
                  } as Property;
                  const propertyIncluded =
                    onIsPropertyAlreadyIncluded(property);
                  const propertyAvailable = isPropertyAvailable(property);
                  const isUniqueProperty = isSinglePropDisponible(
                    prop,
                    property
                  );
                  const isPropertySelected =
                    propertyIncluded?.value === value.value || isUniqueProperty;
                  return (
                    <Button
                      color={
                        !propertyAvailable && !isPropertySelected
                          ? "disabled"
                          : "secondary"
                      }
                      emphasis={isPropertySelected ? "high" : "medium"}
                      key={value.valueId}
                      curvature="low"
                      forceColor={true}
                      action={() => {
                        if (!propertyAvailable) {
                          onResetProperties();
                          onSelectProperty(
                            {
                              prop: prop.characteristicName,
                              value: value.value,
                              id: value.valueId,
                            },
                            true
                          );
                        } else {
                          onSelectProperty({
                            prop: prop.characteristicName,
                            value: value.value,
                            id: value.valueId,
                          });
                        }
                      }}
                    >
                      <p style={{ fontWeight: "400" }}>{value.value}</p>
                    </Button>
                  );
                })}
              </div>
            </ExpandableContent>
          );
        })}
    </div>
  );
};

export default SelectableProperties;

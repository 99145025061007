import { OptionsField } from "forms/form.types";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./CreditCardField.module.scss";
import fieldStyles from "../Field/Field.module.scss";
import validator from "validator";
import { numericInputHandleKeyDown, numericInputHandleKeyUp } from "../utils/ControlledNumericInputs.utils";

const CreditCardField: React.FC<OptionsField> = ({
  id,
  name,
  value,
  placeholder,
  label,
  required,
  type,
  disabled,
  fieldCaption = "",
  errorMessage = "Ingrese los 16 números de su tarjeta",
  customProps = {}
}) => {
  const [error, setError] = useState("");
  const methods = useFormContext();
  const [currentValue, setCurrentValue] = useState(value);
  const [ctrlPressed, setCtrlPressed] = useState(false);

  const cardNumberMaxLength = 16;

  const validateCreditCard = (cardValue: string) => {
    if (validator.isCreditCard(cardValue)
          && cardValue.length >= 15
          && cardValue.length <= 16
    ) {
      setError("");
      return true;
    }
    setError(errorMessage);
    return false;
  };

  useEffect(() => {
    methods.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const activeError = error !== "";
  const emptyFieldWhenRequired =
    methods.formState.errors[name] &&
    methods.formState.errors[name].type === "required";

  const valueNotChanged = currentValue === value;

  const errorStyle =
    activeError || (emptyFieldWhenRequired && valueNotChanged)
      ? "input-error"
      : "";

  const caption = fieldCaption && (
    <span className={fieldStyles["field-caption"]}>{fieldCaption}</span>
  )
  const requiredMessage = emptyFieldWhenRequired && valueNotChanged && (
    <span className={styles["error-text"]}>Este campo es requerido</span>
  );

  activeError &&
    (methods.formState.errors[name] = { type: "validation", message: error });

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <input
        {...methods.register(name, {
            value: currentValue,
            required,
            validate: (value) => (validateCreditCard(value)),
          })
        }
        id={id}
        className={`${styles.input} ${styles[errorStyle]}`}
        type={type}
        max={`${cardNumberMaxLength}`}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={(e) => numericInputHandleKeyDown(e,
          currentValue,
          cardNumberMaxLength,
          ctrlPressed,
          setCtrlPressed)}
        onKeyUp={(e) => numericInputHandleKeyUp(e,
          setCtrlPressed)}
        onChange={(e) => {
          let creditCardValue = e.target.value;
          setCurrentValue(creditCardValue);
          validateCreditCard(creditCardValue);
        }}
        {...customProps}
      />
      {caption}
      {requiredMessage}
      {error !== "" && <div className={styles["error-text"]}> {error}</div>}
    </div>
  );
};

export default CreditCardField;

import { useEffect, useState } from "react";
import { getArticleDetail, getProductPrice } from "config/api/inventory.api";
import { ArticleDetail } from "config/api/inventory.api.types";
import styles from "./SubstituteItem.module.scss";
import useLocalStorage from "components/hooks/useLocalStorage";
import { Characteristic } from "components/common/common.types";
import { toTitleCase } from "views/Offers/common";
import { getPriceFormat } from "config/utils";

interface SubstituteItemProps {
  article: number;
}

const SubstituteItem: React.FC<SubstituteItemProps> = ({ article }) => {
  const userData = useLocalStorage("userData", {}).storedValue;
  const [item, setItem] = useState({} as ArticleDetail);
  const [itemPrice, setItemPrice] = useState(0);
  const productDetails =
    item.characteristics &&
    item.characteristics.map((characteristic: any) => (
      <p key={characteristic.id} className={styles.property}>
        {characteristic.prop}: {characteristic.value}
      </p>
    ));

  useEffect(() => {
    getArticleDetail(Number(article)).then((article) => {
      const newChars = Object.entries(article.characteristics).map(
        ([k, v]) => ({ prop: k, value: v } as Characteristic)
      );
      setItem({ ...article, characteristics: newChars });
    });
  }, [setItem, article]);

  useEffect(() => {
    const userId = Number(userData["id"]);
    const locationId = Number(userData["locationData"][0]["id"]);
    getProductPrice(userId, locationId, `{"${article}":1}`).then((price) => {
      setItemPrice(price.total);
    });
  }, [setItem, article, userData]);

  if (!item.article_id) {
    return null;
  }

  return (
    <div className={styles.product}>
      <div className={styles.header}>
        <p> Producto que estabas buscando</p>
        <p> Precio anterior</p>
      </div>
      <div className={styles.body}>
        <div className={styles.properties}>
          <img
            src={item.images[0].url}
            alt={item.name}
            className={styles.image}
          />
          <div>
            <p className={styles["name"]}>{toTitleCase(item.name)}</p>
            {productDetails}
          </div>
        </div>
        <p> ${getPriceFormat(itemPrice)}</p>
      </div>
    </div>
  );
};

export default SubstituteItem;

import { useAuth0 } from "@auth0/auth0-react";
import env from "react-dotenv";
import StatusView from "components/StatusView/StatusView";
import { sendEmail } from "config/api/api";

interface UnavailableServiceProps {
  service: string;
}

const UnavailableService: React.FC<UnavailableServiceProps> = ({ service }) => {
  const { logout } = useAuth0();

  const NoServiceContext = (
    <div>
      <p>
        Estamos presentando intermitencia en la plataforma, en{" "}
        <strong> DEACERO</strong> seguimos trabajando para digitalizar nuestros
        servicios.
      </p>
      <p>
        <strong>Vuelve a intentarlo más tarde.</strong> Agradecemos tu
        paciencia.
      </p>
    </div>
  );

  const { from_email, to_email, subject, message } = {
    from_email: "ventaempleados@deacero.com",
    to_email: env.REACT_APP_EMAIL_UNAVAILABLE_SERVICE ?? "morivera@deacero.com",
    subject: "IMPORTANT- Service Down",
    message: `El servicio ${service} está presentando intermitencias, por favor verifica su estado.`,
  };

  return (
    <StatusView
      iconRoute="/not-working.svg"
      title="¡Lo sentimos! Ha ocurrido un error"
      statusContext={NoServiceContext}
      iconAlt="service not working warning"
      actionTitle="Entendido"
      action={() => {
        sendEmail(from_email, to_email, subject, message);
        logout({ returnTo: window.location.origin });
      }}
    />
  );
};

export default UnavailableService;

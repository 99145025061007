import { useMemo } from "react";
import styles from "./Pagination.module.scss";

interface PaginationProps {
  onPageChange: any;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize = 50,
}) => {
  const DOTS = "...";

  const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  if (
    currentPage === 0 ||
    (paginationRange && (paginationRange as any).length < 2)
  ) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage =
    paginationRange &&
    (paginationRange as any)[(paginationRange as any).length - 1];

  return (
    <ul className={styles["pagination-container"]}>
      <li
        className={`${styles["pagination-item"]} ${
          currentPage === 1 && styles["disabled"]
        }`}
        onClick={onPrevious}
      >
        <div className={`${styles["arrow"]} ${styles["left"]}`} />
      </li>
      {paginationRange &&
        (paginationRange as any).map((pageNumber: any, i: number) => {
          if (pageNumber === DOTS) {
            return (
              <li
                className={`${styles["pagination-item"]} ${styles["dots"]}`}
                key={`${i}-${pageNumber}`}
              >
                &#8230;
              </li>
            );
          }

          return (
            <li
              className={`${styles["pagination-item"]} ${
                pageNumber === currentPage && styles["selected"]
              }`}
              onClick={() => onPageChange(pageNumber)}
              key={`${i}-${pageNumber}`}
            >
              {pageNumber}
            </li>
          );
        })}
      <li
        className={`${styles["pagination-item"]} ${
          currentPage === lastPage && styles["disabled"]
        }`}
        onClick={onNext}
      >
        <div className={`${styles["arrow"]} ${styles["right"]}`} />
      </li>
    </ul>
  );
};

export default Pagination;

import { Document, Page, pdfjs } from "react-pdf";

interface ShowPDFProps {
  file: string;
  pageNumber: number;
}

const ShowPDF: React.FC<ShowPDFProps> = ({ file, pageNumber }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

  return (
    <Document
      file={file}
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadError={console.error}
    >
      {Array.apply(null, Array(pageNumber))
        .map((x, i) => i + 1)
        .map((page, i) => (
          <Page
            pageNumber={page}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            key={i}
          />
        ))}
    </Document>
  );
};

export default ShowPDF;

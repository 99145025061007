import React from "react";

const defaultNotificationData = {
  notificationData: null,
  setNotificationData: (item: React.FC | null) => {},
};

export const NotificationDataContext = React.createContext<any>(
  defaultNotificationData
);

export const NotificationDataProvider = NotificationDataContext.Provider;

export default NotificationDataContext;

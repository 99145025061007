import React from "react";
import { User } from "./api/user.api.types";

const defaultUser = {
  userData: {} as User,
  setUserData: (user: User) => {},
};

const UserContext = React.createContext(defaultUser);

export const UserProvider = UserContext.Provider;

export default UserContext;

import axios from "axios";
import { API, ZipCodeLocation, CodePostalInfo, Email } from "./api.types";

const fetchData = (url: string) => {
  async function fetchUrl() {
    const response = await fetch(url);
    const json = await response.json();
    return json;
  }
  return fetchUrl();
};

const getData = async (url: string) => {
  const response = await axios.get(url);
  return response.data;
};

const postRequest = async (url: string, body: any, headers?: any) => {
  const response = await axios.post(url, body, headers);
  return response;
};

const patchRequest = async (url: string, body: any, headers?: any) => {
  const response = await axios.patch(url, body, { headers });
  return response;
};

const deleteRequest = async (url: string) => {
  const response = await axios.delete(url);
  return response;
};

const getLocation = (zipCode: string): Promise<ZipCodeLocation> => {
  return getData(API.location(zipCode)).then((data) => {
    if (data["location"]) {
      const neighborhoodsObject = data["location"]["neighborhood"];
      const neighborhoods = Object.keys(neighborhoodsObject);
      const neighborhoodsArray = neighborhoods.map((neighborhood) => {
        return {
          value: neighborhood,
          label: neighborhoodsObject[neighborhood],
        };
      });
      data["location"]["neighborhood"] = neighborhoodsArray;
    }
    return data;
  });
};

const sendEmail = (
  from_email: string,
  to_email: string,
  subject: string,
  message: string
): Promise<any> => {
  const body: Email = {
    sendgrid: {
      from_email: from_email,
      to_email: to_email,
      subject: subject,
      message: message,
    },
  };

  return postRequest(API.sendEmail(), body);
};

const getLatLngFromPostalCode = (
  postalCode: number,
  country: string
): Promise<CodePostalInfo> => {
  return fetchData(API.mapsAPI(postalCode, country));
};

export {
  fetchData,
  getData,
  postRequest,
  patchRequest,
  deleteRequest,
  getLocation,
  getLatLngFromPostalCode,
  sendEmail,
};

import { useContext, useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "components/common/Button";
import useLocalStorage from "components/hooks/useLocalStorage";
import { CartProduct } from "components/common/common.types";
import ShoppingCartContext from "config/shoppingCartContext";
import { getProductPrice } from "config/api/inventory.api";
import { getShoppingCartItems } from "config/api/shoppingCartItems.api";
import { patchShoppingCartItem } from "config/api/shoppingCartItems.api";
import styles from "./ShoppingCartItem.module.scss";
import { getPriceFormat } from "config/utils";

interface ShoppingCartItemProps {
  item: CartProduct;
  removeItem?: (item: CartProduct) => void;
}

const ShoppingCartItem: React.FC<ShoppingCartItemProps> = ({
  item,
  removeItem,
}) => {
  const [itemPrice, setItemPrice] = useState(0);
  const [quantity, setquantity] = useState(item.quantity);
  const { setShoppingCartItems } = useContext(ShoppingCartContext);
  const userData = useLocalStorage("userData", {}).storedValue;
  const locationId = Number(
    userData["locationData"] ? userData["locationData"][0]["id"] : 0
  );
  const productDetailsKeys = item.characteristics;

  const addPriceToShoppingCartItems = (
    shoppingCartItems: Array<CartProduct>
  ) => {
    const newItemsArray: any = shoppingCartItems.map(async (item) => {
      await getProductPrice(userData.id, locationId, `{"${item.articleId}":1}`)
        .then((priceData: any) => {
          const productPrice = priceData.total ?? 0;
          item.price = productPrice;
        })
        .catch((error) => {
          console.log(error);
        });

      return item;
    });

    Promise.all(newItemsArray).then((productsWithUpdatedPrice) => {
      setShoppingCartItems(productsWithUpdatedPrice);
    });
  };

  const updateShoppingCartContextWithDB = async (userId: number) => {
    await getShoppingCartItems(userId)
      .then((shoppingCartItemsFromDB) => {
        setShoppingCartItems(shoppingCartItemsFromDB);
        addPriceToShoppingCartItems(shoppingCartItemsFromDB);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateItemQuantityOnDB = async (
    productToUpdate: CartProduct,
    quantity: number,
    props: any
  ) => {
    await patchShoppingCartItem(productToUpdate.id, {
      sku: productToUpdate.sku,
      quantity: quantity,
      userId: userData.id,
      articleId: productToUpdate.articleId,
      title: productToUpdate.title,
      srcImage: productToUpdate.srcImage,
      characteristics: props,
    })
      .then(() => {
        updateShoppingCartContextWithDB(userData.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleQuantityChange = (e: any) => {
    let actualQuantity = e;
    if (actualQuantity < 1) {
      actualQuantity = 1;
    }
    setquantity(actualQuantity);
    updateItemQuantityOnDB(item, actualQuantity, item.characteristics);
  };

  useEffect(() => {
    if (quantity < 1) {
      setquantity(1);
    }
  }, [quantity]);

  useEffect(() => {
    if (item.price !== 0 && item.price) {
      const productPrice =
        Math.ceil(Math.round(item.price * 1 * 100 * 1e9) / 1e9) / 100;
      setItemPrice(productPrice);
    }
  }, [quantity, item.price]);

  const productDetails = productDetailsKeys.map((characteristic: any) => (
    <p key={characteristic.id} className={styles.property}>
      {characteristic.prop}: {characteristic.value}
    </p>
  ));

  const deleteButton = removeItem && (
    <div className={styles["buttons-wrapper"]}>
      <Button color="secondary" emphasis="low" action={() => removeItem(item)}>
        Eliminar
        <DeleteIcon className={styles.icon} />
      </Button>
    </div>
  );

  return (
    <div className={styles.product}>
      <div className={styles.header}>
        <p> {item.title}</p>
        <p> ${getPriceFormat(itemPrice)}</p>
      </div>
      <div className={styles.body}>
        <div className={styles.properties}>
          {item.srcImage?.length > 0 && (
            <img
              src={item.srcImage}
              alt={item.title}
              className={styles.image}
            />
          )}
          <div>
            <p className={styles["properties-title"]}>
              Propiedades del Producto
            </p>
            {productDetails}
          </div>
        </div>
        <div className={styles.options}>
          <div className={styles["quantity-wrapper"]}>
            <p>Cantidad</p>
            <div className={styles["unit-container"]}>
              <input
                value={quantity}
                type="number"
                disabled
                onChange={(e) => {
                  const value = e.target.value;
                  handleQuantityChange(Number(value));
                }}
              />
            </div>
          </div>
          {deleteButton}
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartItem;
